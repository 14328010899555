import React from 'react'
import Tooltip from '@mui/material/Tooltip'

//import { NavLink } from 'react-router-dom';
import Style from 'style-it'
import { NavLink } from 'react-router-dom'
import DashboardMenu from '../../Components/Maps/DashboardMenu'
import { checkPrivileges } from '../../Helpers'
import ExtraMenu from './ExtraMenu'
import MenuItem from '../../Components/common/MenuItem'
export default class Navbar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: '/monitoring'
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue })
    this.props.history.push(newValue)
    return false
  }

  componentWillMount () {
    if (this.props.location.pathname !== this.state.value) {
      this.setState({ value: this.props.location.pathname })
    }
  }

  collapseMenu = () => {
    if (document.body.offsetWidth < 959) {
      this.props.collapseMenu()
    }
  }

  render () {
    const analytic = checkPrivileges('dashboard')&&(<DashboardMenu history={this.props.history} />) || null
    const extraMenuSetting = [
      { min: 1440, max: 500000, col: 10 },
      { min: 1000, max: 1399, col: 8 },
      { min: 768, max: 999, col: 4 },
      { min: 0, max: 767, col: 100 }
    ];
    const width = window.innerWidth;
    const col = extraMenuSetting.find(r => r.min <= width && width <= r.max);
    const restMenu = [];
    const activeOnly = Object.values(this.props.items).filter(r => r.checked);

    activeOnly.forEach((item, index) => {
      if(col && index >= col.col) {
        const { icon: ItemIcon } = item;
        restMenu.push(<NavLink className={'navbar-menu-item'} key={index} style={{ display: 'flex', padding: 10, alignItems: 'center', gap: 10 }} to={item.href}>
          <ItemIcon fill='currentColor' width={20} height={20} />
          {item.title}
        </NavLink>)
      }
    });

    return (<div className='navbar-menu'>
        {activeOnly.map((item, index) => {
          if(col && index < col.col) {
            const { icon: ItemIcon } = item;
            const navlink = (
                <Tooltip
                  key={index}
                  title={item.title}
                  placement={undefined}
                  classes={{
                    popper: 'menu-popper',
                    tooltip: 'menu-popper-tooltip'
                  }}
                >
                  <NavLink
                    className='navbar-menu-item'
                    onClick={this.collapseMenu}
                    to={item.href}
                    style={{position: 'relative'}}
                  >
                    <ItemIcon fill='currentColor' width={20} height={20} />
                    {item.count !== undefined ? <span className="counter">{(item.count>99)?'99+':item.count}</span> : null}
                    {
                      checkPrivileges("deliverynoteMoreThanOneVehicleView")&&<>
              
                    {item.state === "deliveryNote" ? (
  <Tooltip
    title={`You have ${this.props.logInUser?.dueDeliveryNoteCount ?? 0} repeated services`}
    placement="right"
  >
    <span
      style={{
        position: "absolute",
        top: -5,
        right: -5,
        backgroundColor: "white",
        color: this.props.themecolors.themeLightColor,
        border: `1px solid white`,
        fontSize: "10px",
        fontWeight: "bold",
        borderRadius: "50%",
        width: "18px",
        height: "18px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
     {(this.props.logInUser?.dueDeliveryNoteCount ?? 0) > 99 ? '99+' : (this.props.logInUser?.dueDeliveryNoteCount ?? 0)}
    </span>
  </Tooltip>
) : null}
        </>
                    }
                    <span className='nav-link-text'>{item.title}</span>
                  </NavLink>
                </Tooltip>
              )

            return <>{item.name === 'dashboard' ? <><div key={item.name}>
              {navlink}
              </div>{analytic}</> : 
              <div key={index}>{navlink}</div>
            }</>
          } else {
            return null
          }
          })}
          {restMenu.length ? <ExtraMenu>
            {restMenu}
          </ExtraMenu> : null}
        
      </div>)
  }
}
