import React, { Component } from 'react'
import { connect } from 'react-redux'
import DeleteIcon from '@mui/icons-material/Delete';
import QrCodeIcon from '@mui/icons-material/QrCode';
import EditIcon from '@mui/icons-material/Edit';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Link } from 'react-router-dom'
import { checkPrivileges } from '../../Helpers'
import { ReactComponent as Vehicle } from './../../assets/nav/vehicle.svg'
import { ReactComponent as IntegratedWasl } from '../../assets/monitoring/wasl.svg'
import { ReactComponent as NotIntegratedWasl } from '../../assets/monitoring/wasl-cross.svg'
import Icon from '@mui/material/Icon'
import InfoIcon from './../../assets/monitoring/info.svg'
import withTranslationWrapper from '../../HOC/withTranslation';
class vehicle extends React.PureComponent {
  render () {
    const list =
      this.props.vehicles
      && this.props.itemPagination
       && this.props.itemPagination.total >= 0
       ? this.props.itemPagination?.items.filter(d => {
            return (d.id + d.label + d.model + d.deviceId + d.vehicleLicensePlate)
              .toLowerCase()
              .includes((this.props.search || '').toLowerCase())
          })
        : []

    let data = ''
    const loading = []
    if (list.length) {
      data = list.map(vehicle => (
        <ListRow
          key={vehicle.id}
          item={vehicle}
          {...this.props}
          removedItem={this.props.removedItem}
          editItem={this.props.editItem}
          showWaslDetails={this.props.showWaslDetails}
          selecteItem={this.props.selecteItem}
          selectedItemId={this.props.selectedItemId}
        />
      ))
    }else{
      return(
        <p style={{ textAlign: 'center', color: '#ccc', fontWeight: 'bold' }}>
          {/* {this.props.translate('notFound')} */}
        </p>
      )
    }
    return (
      <>
        {data && (
          <div className='individual-view'>
            <ul className='list-view with-padding-right'>{data}</ul>
          </div>
        )}
        {loading}
      </>
    )
  }
}

export class ListRows extends Component {
  render () {
    if (this.props.children.length) {
      return (
        <ul className='list-view'>
          {this.props.children.map(data => (
            <ListRow key={data.id} item={data} {...this.props} />
          ))}
        </ul>
      )
    } else {
      return ''
    }
  }
}
export class ListRow extends Component {
  render () {
    const { waslEnabled } = this.props?.ServerSetting?.attributes || {};
    const { id, label,vehicleLicensePlate, deviceId, attributes: { WASLKey } } = this.props.item;
    const device = this.props.allVehicleLocation && this.props.allVehicleLocation[deviceId] || null;
    return (
      <li className={
        'clearfix list-row ' +
        (parseInt(this.props.match.params.id) ===
        parseInt(id)
          ? 'active'
          : '')
      }>
        <Link
          onClick={this.handleClick}
          
          to={'/vehicle/' + id}
        >
          <label
            className='checkbox'
            onClick={() => (id!==parseInt(this.props.match.params.id))?this.props.selecteItem(id):this.handleClick}
          >
            <span
              className='unit-name'
            >
              <span
                style={{
                  marginRight: '5px',
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
              >
                <Vehicle
                  width={20}
                  height={20}
                  style={{ display: 'block', fill: 'currentColor' }}
                />
              </span>
              {vehicleLicensePlate||label}
            </span>
          </label>
          </Link>
          <div className='pull-right'>
            {waslEnabled ? (WASLKey ? <IntegratedWasl width={32} style={{fill: 'currentColor'}} /> : <NotIntegratedWasl width={32} style={{fill: 'currentColor'}} />) : ''}
              {waslEnabled && (
              <span
                className='action-item hovered'
                title={this.props.translate('waslDetails')}
                onClick={() => this.props.showWaslDetails(this.props.item)}
              >
                <svg fill='currentColor' width={16} height={16}>
                    <use xlinkHref={`${InfoIcon}#icon`} />
                </svg>
              </span>
            )}
              <span
                className='action-item hovered'
                title={this.props.translate('Qr')}
                onClick={() => this.props.qrCodeGenerator(this.props.item)}
              >
                <QrCodeIcon style={{fontSize: 16}}/>
              </span>
            {checkPrivileges('vehicleDelete') && (
              <span
                className='action-item hovered'
                title={this.props.translate('delete')}
                onClick={() => this.props.removedItem(this.props.item)}
              >
                <DeleteIcon style={{fontSize: 16}}/>
              </span>
            )}
            {checkPrivileges('vehicleUpdate') && (
              <span
                className='action-item hovered'
                title={this.props.translate('edit')}
                onClick={() => this.props.editItem(this.props.item)}
              >
                <EditIcon style={{fontSize: 16}}/>
              </span>
            )}

            {device ? (
              <span>
                <img
                    width={24}
                    height={24}
                    style={{display: 'block'}}
                    src={
                      '/assets/category/default/'+(device.category ||
                        'default')+'.svg'}
                    
                    alt=''
                  />
              </span>
            ) : <span
            className='action-item'
          >
            <LinkOffIcon style={{fontSize: 16}}/>
          </span>}
          </div>
        
      </li>
    )
  }
}

const mapState = state => {
  return {
    vehicles: state.vehicles
  }
}

const mapStateToProps = connect(mapState)
export const VehicleShortList = mapStateToProps(withTranslationWrapper(vehicle))
