import React from "react";
import { connect } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import Scrollbar from "react-scrollbars-custom";
import { Grid, Tooltip, Typography } from "@mui/material";

// import ReactHighcharts from 'react-highcharts'
import Select from "react-select";
import instance from "../../axios";
import pieChartDefault from "../../Components/common/Charts/pieChartDefault";
import moment from "moment";

import Skeleton from "@mui/material/Skeleton";

import { nFormatter } from "../../Helpers";
import co2svg from "../../assets/co2img.svg";
import Support from "../../Layout/Support";
import Button from "../../Components/common/Button";
import onlineSvg from "./../../assets/dashboard/newDashboards/TotalInvoices.svg";
import maintRequest from "./../../assets/dashboard/newDashboards/MaintRequest.svg";
import gpsNotUpdatedSvg from "./../../assets/dashboard/newDashboards/GPSnotUpdated.svg";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import InfoIcon from "@mui/icons-material/Info";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import '@emran-alhaddad/saudi-riyal-font/index.css';
const FiltersOption = [
  { label: "MTD", value: 1 },
  { label: "Last Month", value: 2 },
  { label: "Last 3 months", value: 3 },
  { label: "YTD", value: 4 },
  { label: "Last Year", value: 5 },
  { label: "ALL", value: 6 },
];

const textStyle = {
  width: "100%",
  color: "#ffffff",
  textAlign: "center",
  fontSize: "24px",
  fontWeight: "800",
  fontStyle: "italic",
  display: "inline-block",
  whiteSpace: "nowrap",
  overflow: "hidden !important",
  //  overflow: "hidden",
  textOverflow: "ellipsis",
};

// let imageStyle = {
// height: "350px",
//   width: "600px",
//   backgroundImage:
//   'url("https://img.freepik.com/free-photo/wide-angle-shot-singletree-growing-clouded-sky-during-sunset-surrounded-by-grass_181624-22807.jpg")',
//   backgroundSize: "contain",
//   backgroundRepeat: "no-repeat",
//   color: "white",
// };

let ownChart = {};
let topVehiclesChart = {};
let vehicleUtilizationChart = {};
let serviceUtilizationChart = {};
let topMaintenancePerDay = {};
let topMaintenancePerKm = {};
let totalVehicles = 0;

class MaintenanceDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      isHovered: false,
      totalCost: "",
      totalOutStanding: "",
      vehicleCategories: "",
      vehicleCategoriesDecarbonisation: [],
      maintRequest: "",
      maintRequestCategory: "",
      partsMaintRequest: "",
      serviceMaintRequest: "",
      packageMaintRequest: "",
      packageAccount: "",
      usedPart: "",
      usedService: "",
      vehicleCategoryCost: "",
      topVehicleModelCost: "",
      tab1: "ALL",
      tab2: "ALL",
      tab3: "ALL",
      tab4: "ALL",
      tab5: "ALL",
      tab6: "ALL",
      tab7: "ALL",
      tabSmall: "ALL",
      loader1: false,
      loader2: false,
      loader3: false,
      loader13: false,
      loader14: false,
      loader17: false,
      loader18: false,
      loader11: false,
      loader15: false,
      xs: window.innerWidth < 600,
      sm: window.innerWidth > 600 && window.innerWidth < 960,
      md: window.innerWidth > 768 && window.innerWidth < 1440,
      carbonFootPrint: "",
      vehicleUtilizer: "",
      topVehiclesbyCost: "costPerDay",
      topVehiclesbyMaint: "maintCost",
      servicesByType: "topService",
      balanceCreditData: {},
      servicesData:{}
    };
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      xs: window.innerWidth < 600,
      sm: window.innerWidth > 600 && window.innerWidth < 960,
      md: window.innerWidth > 768 && window.innerWidth < 1440,
    });
  };

  componentWillMount() {
    let timezone = "";
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    } else {
      timezone = "Asia/Riyadh";
    }
    // console.log("timezone=====", timezone);

    const now = moment().tz(timezone);
    const startOfYear = now.clone().startOf("year");

    const endOfDay = now.clone().endOf("day");

    const lastYearStart = startOfYear.clone().subtract(1, "year");
    const lastYearEnd = endOfDay.clone().subtract(1, "year");

    this.getDashboardData(
      lastYearStart.toISOString(),
      lastYearEnd.toISOString()
    );
    // this.getDashboardData(startOfYear.toISOString(),endOfDay.toISOString() );
    this.getFMD13();
    this.getFMD14();
    this.getFMD17();
    // this.getFMD18()
    this.getFMD11();
    this.getFMD19();
  }
  getDashboardData = (from, to) => {
    // this.getFMD1(to,from)
    // this.getFMD2(to,from)
    // this.getFMD3(to,from)
    // this.getFMD4(to,from)
    // this.getFMD5(to,from)
    // this.getFMD6(to,from)
    // // this.getFMD7()
    // this.getFMD8(to,from)
    // this.getFMD9(to,from)
    // this.getFMD10(to,from)

    this.getFMD1();
    this.getFMD2();
    this.getFMD3();
    this.getFMD4();
    this.getFMD5();
    this.getFMD6();
    // this.getFMD7()
    this.getFMD8();
    this.getFMD9();
    this.getFMD10();
    this.getFMD15();
    this.getFMD16();

    // instance({url: `/api/dashboards/fmd/1`,method: 'GET' }).then(res=>{this.setState({totalCost:res})  }).catch(err=>{console.log("err 1====", err)})
    // instance({url: `/api/dashboards/fmd/2`,method: 'GET' }).then(res=>{this.setState({totalOutStanding:res}) }).catch(err=>{console.log("err 2====", err)})
    // instance({url: `/api/dashboards/fmd/3`,method: 'GET' }).then(res=>{
    //   let dt = []
    //   totalVehicles = 0
    // let colors = ["#da5e5e","#f50057","#7b101b","#00c179","#d52f2f","#ec1c91","#28a788","#3f52b5e6","#594a86","#864a73"];

    //   for (const [key, value] of Object.entries(res)) {
    //     totalVehicles = totalVehicles + value
    //     dt.push({name:key, y:value,value:value,formattedvalue:value,})
    //   }
    //   const data = [{data: dt}];

    //   let makeData = []
    //       dt && dt.map((item,index)=>(
    //         makeData.push({
    //           ...item,
    //           color:colors[index]
    //       })
    //     ))

    //   ownChart = {
    //     config: pieChartDefault().initializeWithOptions({
    //       chart: {
    //         height: '310px'
    //       },
    //       // title: {
    //       //   text: "table.head"
    //       // },
    //       plotOptions: {
    //         series: {
    //             dataLabels: {
    //                 enabled: false,
    //                 format: '{point.name}: {point.y:.1f}',
    //                 style: {
    //                   fontWeight: 'bold',
    //                   color:"currentColor"
    //               },
    //             }
    //         }
    //     },
    //       series: data,
    //       legend: {
    //         enabled: true
    //       },
    //       yAxis: {
    //         gridLineWidth: 0
    //       },
    //       tooltip: {
    //         formatter: function () {
    //           return (
    //             '<strong>' +
    //             this.key +
    //             '</strong>' +
    //             ' <br>' +
    //             '<strong>' +
    //             this.y.toFixed(2) +
    //             '</strong>'
    //           )
    //         }
    //       }
    //     }),
    //     show: true
    //   }

    //   this.setState({vehicleCategories:res}) }).catch(err=>{console.log("err 3 ====", err)})
    // instance({url: `/api/dashboards/fmd/4`,method: 'GET' }).then(res=>{this.setState({maintRequest:res}) }).catch(err=>{console.log("err 4====", err)})
    // instance({url: `/api/dashboards/fmd/5`,method: 'GET' }).then(res=>{
    //   let dt = []
    //   for (const [key, value] of Object.entries(res)) {
    //     dt.push({name:key, value:value })
    //   }
    //   let maxUsedCount = Math.max(...dt.map(item => item.value));
    //   let usedPartPercentages = dt.map(item => {
    //     return {  ...item, countPercentage: (item.value / maxUsedCount) * 100
    //     }
    //   })
    //   const findItem = usedPartPercentages.find(item=>item.name==="items")
    //   const findService = usedPartPercentages.find(item=>item.name==="services")
    //   const findPackages = usedPartPercentages.find(item=>item.name==="packages")
    //   // partsMaintRequest
    //   this.setState({maintRequestCategory:res,partsMaintRequest:findItem, serviceMaintRequest:findService,packageMaintRequest:findPackages })

    // }).catch(err=>{console.log("err 5====", err)})
    // instance({url: `/api/dashboards/fmd/6`,method: 'GET' }).then(res=>{
    //   let maxUsedCount = Math.max(...res.map(item => item.usedCount));
    //   let usedPartPercentages = res.map(item => {
    //     return {  ...item, usedCountPercentage: (item.usedCount / maxUsedCount) * 100
    //     }
    //   })

    //   this.setState({packageAccount:usedPartPercentages}) }).catch(err=>{console.log("err 6====", err)})
    // instance({url: `/api/dashboards/fmd/7?to=${to}&from=${from}`,method: 'GET' }).then(res=>{
    instance({ url: `/api/dashboards/fmd/7`, method: "GET" })
      .then((res) => {
        let maxUsedCount = Math.max(...res.map((item) => item.usedCount));
        let usedPartPercentages = res.map((item) => {
          return {
            ...item,
            usedCountPercentage: (item.usedCount / maxUsedCount) * 100,
          };
        });

        this.setState({ usedPart: usedPartPercentages });
      })
      .catch((err) => {
        console.log("err 7====", err);
      });
    // instance({url: `/api/dashboards/fmd/8`,method: 'GET' }).then(res=>{
    //   let maxUsedCount = Math.max(...res.map(item => item.usedCount));
    //   let usedPartPercentages = res.map(item => {
    //     return {
    //       ...item,
    //       usedCountPercentage: (item.usedCount / maxUsedCount) * 100
    //     }
    //   })

    //   this.setState({usedService:usedPartPercentages}) }).catch(err=>{console.log("err 8====", err)})
    // instance({url: `/api/dashboards/fmd/9`,method: 'GET' }).then(res=>{this.setState({vehicleCategoryCost:res}) }).catch(err=>{console.log("err 9====", err)})
    // instance({url: `/api/dashboards/fmd/10`,method:'GET'}).then(res=>{this.setState({topVehicleModelCost:res}) }).catch(err=>{console.log("err 10====", err)})
  };
  generateRandomColor = () => {
    let colors = [
      "#f38686",
      "#91e064",
      "#e86493",
      "#f6cc4e",
      "#d52f2f",
      "#5d2c2c",
      "#45e6aa",
      "#427ace",
      "#477ac7",
      "#c747b5",
    ];
    let randomColor = colors[Math.floor(Math.random() * colors.length)];
    // console.log("randomColor ====", randomColor);
    return randomColor;

    // let maxVal = 0xFFFFFF; // 16777215
    // let randomNumber = Math.random() * maxVal;
    // randomNumber = Math.floor(randomNumber);
    // randomNumber = randomNumber.toString(16);
    // let randColor = randomNumber.padStart(6, 0);
    // return `#${randColor.toUpperCase()}`
  };

  getFMD19 = () => {
    let url = `/api/dashboards/fmd/11`;
    this.setState({ loader11: true }, () => {
      instance({ url: url, method: "GET" })
        .then((res) => {
          // console.log("res====", res);
          // const newobj = {
          //   "totalCarbonFootPrint": res && res.totalCarbonFootPrint,
          //   "categoryWiseCarbonFootPrint": Object.entries(res?.categoryWiseCarbonFootPrint).map(([name, value]) => ({
          //     name,
          //     value
          //   }))
          // };
          const newobj = {
            totalCarbonFootPrint: res && res.totalCarbonFootPrint,
            categoryWiseCarbonFootPrint: Object.entries(
              res?.categoryWiseCarbonFootPrint
            )
              .filter(([name, value]) => value !== 0)
              .map(([name, value]) => ({ name, value }))
              .sort((a, b) => b.value - a.value),
          };

          this.setState({ carbonFootPrint: newobj || {}, loader11: false });
        })
        .catch((err) => {
          this.setState({ loader11: false });
        });
    });
  };

  getFMD1 = (to, from) => {
    let url =
      to && from
        ? `/api/dashboards/fmd/1?to=${to}&from=${from}`
        : `/api/dashboards/fmd/1`;
    this.setState({ loader1: true }, () => {
      instance({ url: url, method: "GET" })
        .then((res) => {
          this.setState({ totalCost: res, loader1: false });
        })
        .catch((err) => {
          this.setState({ loader1: false });
        });
    });
  };
  getFMD2 = (to, from) => {
    let url =
      to && from
        ? `/api/dashboards/fmd/2?to=${to}&from=${from}`
        : `/api/dashboards/fmd/2`;
    this.setState({ loader2: true }, () => {
      instance({ url: url, method: "GET" })
        .then((res) => {
          this.setState({
            totalOutStanding: res,
            loader2: false,
          });
        })
        .catch((err) => {
          this.setState({
            loader2: false,
          });
        });
    });
  };
  getFMD3 = (to, from) => {
    let url =
      to && from
        ? `/api/dashboards/fmd/3?to=${to}&from=${from}`
        : `/api/dashboards/fmd/3`;

    instance({ url: url, method: "GET" })
      .then((res) => {
        let dt = [];
        let totalVehicles = 0;
        const colors = [
          "#003F5C",
          "#29AF5C",
          "#009E6F",
          "#008C7D",
          "#1E72B8",
          "#6D71C5",
          "#007981",
          "#00667D",
          "#005270",
          "#6FBD43",
        ];

        // Calculate total vehicles and prepare data array
        for (const [key, value] of Object.entries(res)) {
          let modifiedKey =
            key === "car" ? "Sedan" : key === "suv" ? key.toUpperCase() : key;

          totalVehicles += value; // Add to totalVehicles
          dt.push({
            name: modifiedKey,
            y: value,
            value: value,
            formattedvalue: value,
          });
        }
        // Attach color to data
        const makeData = dt.map((item, index) => ({
          ...item,
          color: colors[index % colors.length], // Cycle through colors if necessary
        }));

        const chartData = makeData;

        ownChart = {
          config: pieChartDefault().initializeWithOptions({
            chart: {
              type: "pie",
              height: "180px",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  style: {
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    textAlign: "left",
                    color: "currentColor",
                  },
                  distance: 30,
                },
              },
            },
            series: [
              {
                data: chartData, // Use `chartData` directly
              },
            ],
            legend: {
              enabled: false,
              layout: "vertical",
              align: "right",
              verticalAlign: "middle",
              labelFormatter: function () {
                return this.name.charAt(0).toUpperCase() + this.name.slice(1); // Capitalize legend names
              },
            },
            tooltip: {
              formatter: function () {
                let name =
                  this.key === "car"
                    ? "Sedan"
                    : this.key === "suv"
                    ? this.key.toUpperCase()
                    : this.key.charAt(0).toUpperCase() + this.key.slice(1);

                return `<strong>${name}</strong><br><strong>${Number(
                  this.y
                ).toLocaleString()}</strong>`;
              },
            },
            yAxis: {
              gridLineWidth: 0, // Disable grid lines
            },
          }),
          credits: {
            enabled: false, // Set to false to hide the credits label
          },
          show: true,
        };

        // Update component state with vehicle categories and total vehicles
        this.setState({
          vehicleCategories: res,
          totalVehicles: totalVehicles, // Set totalVehicles in state
        });
      })
      .catch((err) => {
        console.error("Error fetching FMD data:", err); // Log errors
      });
  };

  getFMD4 = (to, from) => {
    let url =
      to && from
        ? `/api/dashboards/fmd/4?to=${to}&from=${from}`
        : `/api/dashboards/fmd/4`;
    this.setState({ loader3: true }, () => {
      instance({ url: url, method: "GET" })
        .then((res) => {
          this.setState({
            maintRequest: res,
            loader3: false,
          });
        })
        .catch((err) => {
          this.setState({
            loader3: false,
          });
        });
    });
  };
  getFMD5 = (to, from) => {
    let url =
      to && from
        ? `/api/dashboards/fmd/5?to=${to}&from=${from}`
        : `/api/dashboards/fmd/5`;
    instance({ url: url, method: "GET" })
      .then((res) => {
        let dt = [];
        for (const [key, value] of Object.entries(res)) {
          dt.push({ name: key, value: value });
        }
        let maxUsedCount = Math.max(...dt.map((item) => item.value));
        let usedPartPercentages = dt.map((item) => {
          return {
            ...item,
            countPercentage: (item.value / maxUsedCount) * 100,
          };
        });
        const findItem = usedPartPercentages.find(
          (item) => item.name === "items"
        );
        const findService = usedPartPercentages.find(
          (item) => item.name === "services"
        );
        const findPackages = usedPartPercentages.find(
          (item) => item.name === "packages"
        );
        // partsMaintRequest
        this.setState({
          maintRequestCategory: res,
          partsMaintRequest: findItem,
          serviceMaintRequest: findService,
          packageMaintRequest: findPackages,
        });
      })
      .catch((err) => {
        console.log("err 5====", err);
      });
  };
  getFMD6 = (to, from) => {
    let url =
      to && from
        ? `/api/dashboards/fmd/6?to=${to}&from=${from}`
        : `/api/dashboards/fmd/6`;
    instance({ url: url, method: "GET" })
      .then((res) => {
        let maxUsedCount = Math.max(...res.map((item) => item.usedCount));
        let usedPartPercentages = res.map((item) => {
          return {
            ...item,
            usedCountPercentage: (item.usedCount / maxUsedCount) * 100,
          };
        });

        this.setState({ packageAccount: usedPartPercentages });
      })
      .catch((err) => {
        console.log("err 6====", err);
      });
  };
  getFMD7 = (to, from) => {
    let url =
      to && from
        ? `/api/dashboards/fmd/7?to=${to}&from=${from}`
        : `/api/dashboards/fmd/7`;
    instance({ url: url, method: "GET" })
      .then((res) => {
        let maxUsedCount = Math.max(...res.map((item) => item.usedCount));
        let usedPartPercentages = res.map((item) => {
          return {
            ...item,
            usedCountPercentage: (item.usedCount / maxUsedCount) * 100,
          };
        });

        this.setState({ usedPart: usedPartPercentages });
      })
      .catch((err) => {
        console.log("err 7====", err);
      });
  };

  getFMD8 = (to, from) => {
    let url =
      to && from
        ? `/api/dashboards/fmd/8?to=${to}&from=${from}`
        : `/api/dashboards/fmd/8`;
    instance({ url: url, method: "GET" })
      .then((res) => {
        let maxUsedCount = Math.max(...res.map((item) => item.usedCount));
        let usedPartPercentages = res.map((item) => {
          return {
            ...item,
            usedCountPercentage: (item.usedCount / maxUsedCount) * 100,
          };
        });
        this.setState({ usedService: usedPartPercentages });
      })
      .catch((err) => {
        console.log("err 8====", err);
      });
  };

  getFMD9 = (to, from) => {
    let url =
      to && from
        ? `/api/dashboards/fmd/9?to=${to}&from=${from}`
        : `/api/dashboards/fmd/9`;
    instance({ url: url, method: "GET" })
      .then((res) => {
        this.setState({ vehicleCategoryCost: res });
      })
      .catch((err) => {});
  };

  getFMD10 = (to, from) => {
    let url =
      to && from
        ? `/api/dashboards/fmd/10?to=${to}&from=${from}`
        : `/api/dashboards/fmd/10`;
    instance({ url: url, method: "GET" })
      .then((res) => {
        this.setState({ topVehicleModelCost: res });
      })
      .catch((err) => {});
  };
  getFMD11 = () => {
    instance(`/api/vehicles/categories`)
      .then((response) => {
        this.setState({
          vehicleCategoriesDecarbonisation: response?.categories,
        });
      })
      .catch((error) => {
        console.error("Invalid Categories API response format:", error);
      });
  };
  getFMD13 = () => {
    let url = `/api/dashboards/fmd/13`;
    this.setState({ loader13: true }, () => {
      instance({ url: url, method: "GET" })
        .then((res) => {
          vehicleUtilizationChart = this.getPieChart2(res);
          this.setState({ vehicleUtilizer: res.total, loader13: false });
        })
        .catch((err) => {
          console.log("===", err);
        });
    });
  };
  getFMD14 = () => {
    let url = `/api/dashboards/fmd/14`;
    this.setState({ loader14: true }, () => {
      instance({ url: url, method: "GET" })
        .then((res) => {
          topMaintenancePerDay = this.getPieChart3(res.costPerDay, "Day");
          topMaintenancePerKm = this.getPieChart3(res.costPerKm, "KM");
          this.setState({ loader14: false });
        })
        .catch((err) => {
          console.log("===", err);
        });
    });
  };

  getFMD15 = () => {
    let url = `/api/dashboards/fmd/15`;
    this.setState({ loader15: true }, () => {
      instance({ url: url, method: "GET" })
        .then((res) => {
          let data = {
            ...res,
            credit: res.totalCredit,
            balanceDue: res.totalbalanceDnPlusInvoice,
            remainingBalance: res.totalCredit - res.totalbalanceDnPlusInvoice,
          };
          this.setState({ balanceCreditData: data, loader15: false });
        })
        .catch((err) => {});
    });
  };

  getFMD16 = (to, from) => {
    let url =
      to && from
        ? `/api/dashboards/fmd/16?to=${to}&from=${from}`
        : `/api/dashboards/fmd/16`;
    instance({ url: url, method: "GET" })
      .then((res) => {
        serviceUtilizationChart = this.getPieChart4(res);
        this.setState({ servicesData: res });
      })
      .catch((err) => {
        console.log("err 16====", err);
      });
  };

  getFMD17 = () => {
    let url = `/api/dashboards/fmd/12`;
    this.setState({ loader17: true }, () => {
      instance({ url: url, method: "GET" })
        .then((res) => {
          // console.log("res=====17", res)
          this.setState({ topVehicle: res, loader17: false });
          topVehiclesChart = this.getPieChart(res);
        })
        .catch((err) => {});
    });
  };


  // getFMD18 = ()=>{
  //   let url = `/api/dashboards/bi/18`
  //   this.setState({ loader18: true }, () => {
  //   instance({url: url,method: 'GET' }).then(res=>{
  //     // console.log("res=====18", res)
  //     const arrWithSalePerKM = res.map(item => ({
  //       previousServiceDate: item.previousServiceDate,
  //       latestServiceDate: item.latestServiceDate,
  //       vehicleId: item.vehicleId,
  //       saleNetAmount: item.saleNetAmount,
  //       salePerKM: item.salePerKM,
  //     }));

  //     // Extracting array with salePerDay
  //     const arrWithSalePerDay = res.map(item => ({
  //       previousServiceDate: item.previousServiceDate,
  //       latestServiceDate: item.latestServiceDate,
  //       vehicleId: item.vehicleId,
  //       saleNetAmount: item.saleNetAmount,
  //       salePerDay: item.salePerDay,
  //     }));
  //     perKmChart= this.getPieChart1(arrWithSalePerKM)
  //     perDayChart= this.getPieChart1(arrWithSalePerDay)

  //     this.setState({serviceCost:res , loader18: false})  }).catch(err=>{      })
  //   })

  // }

  getPieChart1 = (data) => {
    // Define a mapping of original keys to desired labels
    const keyLabelMapping = {
      previousServiceDate: "Previous Service Date",
      salePerKM: "Sale",
      latestServiceDate: "Latest Service Date",
      vehicleId: "Vehicle ID",
      saleNetAmount: "Sale Net Amount",
      salePerDay: "Sale",
    };

    // Filter out unwanted keys from the data
    const filteredData = data.map((item) => {
      const newItem = {};
      Object.keys(item).forEach((key) => {
        if (keyLabelMapping[key]) {
          newItem[keyLabelMapping[key]] = item[key];
        }
      });
      return newItem;
    });

    const chartData = filteredData.map((item) => ({
      name: item["Vehicle ID"], // Assuming "Vehicle ID" is the identifier for each data point
      y: item["Sale Net Amount"],
      sale: item["Sale"], // Assuming "Count" is the count value
    }));
    const currency = this.props.logInUser && this.props.logInUser.attributes
    ? this.props.logInUser.attributes.currencyLabel
    : "SAR";
    return {
      config: pieChartDefault().initializeWithOptions({
        chart: {
          type: "pie",
          height: "310px",
        },
        title: {
          text: "",
        },
        plotOptions: {
          pie: {
            innerSize: "50%",
            dataLabels: {
              enabled: true,
              format: "{point.name}: {point.percentage:.1f}%",
            },
          },
        },
        tooltip: {
          formatter: function () {
   
          
            return (
              "<b>" +
              "Vehicle : " +
              "</b>" +
              this.point.name +
              "<br/>" +
              "<b>" +
              "Total Maint Cost : " +
              "</b>" +
              Number(this.point.y).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              `${currency}`+
              "<br/>" +
              "<b>" +
              "Sale : " +
              "</b>" +
              this.point.sale
            );
          },
        },
        series: [
          {
            name: "Monthly Data",
            innerSize: "50%",
            data: chartData,
          },
        ],
        legend: {
          enabled: false,
        },
        yAxis: {
          gridLineWidth: 0,
        },
        credits: {
          enabled: false,
        },
      }),
      show: true,
    };
  };
  getPieChart3 = (data, typespan) => {
    let colors = [
      "#003F5C",
      "#29AF5C",
      "#009E6F",
      "#008C7D",
      "#1E72B8",
      "#6D71C5",
      "#007981",
      "#00667D",
      "#005270",
      "#6FBD43",
    ];
    const currency = this.props.logInUser && this.props.logInUser.attributes
    ? this.props.logInUser.attributes.currencyLabel
    : "SAR";
    return {
      config: pieChartDefault().initializeWithOptions({
        chart: {
          type: "pie",
          height: "280px",
        },
        plotOptions: {
          pie: {
            innerSize: "30%", // Set the inner size to create the donut effect
            dataLabels: {
              enabled: true,
              format: "{point.name}: {point.percentage:.1f}%",
              distance: 20,
            },
          },
        },
        tooltip: {
          formatter: function () {
            let currencySymbol = currency === "SAR" 
            ? '<span style="font-family: saudi_riyal;">&#xE900;</span>' 
            : currency
            return (
              "<b>" +
              "Vehicle License Plate : " +
              "</b>" +
              this.point.name +
              "<br/>" +
              "<b>" +
              "Cost Per " +
              typespan +
              " : " +
              "</b>" +
              Number(this.point.y).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              currencySymbol
            );
          },
        },

        series: [
          {
            innerSize: "50%",
            data: Object.keys(data).map((key, index) => ({
              name: key,
              y: data[key],
              color: colors[index % colors.length], // Use the colors in a cyclic manner
            })),
          },
        ],
        legend: {
          enabled: false,
        },
        yAxis: {
          gridLineWidth: 0,
        },
        credits: {
          enabled: false,
        },
      }),
      show: true,
    };
  };

  getPieChart2 = (data) => {
    return {
      config: pieChartDefault().initializeWithOptions({
        chart: {
          type: "pie",
          height: "280px",
        },
        plotOptions: {
          pie: {
            innerSize: "50%",
            dataLabels: {
              enabled: false,
              format: "<b>{point.name}</b>: {point.percentage:.1f}%",
              distance: 10,
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b>" +
              " : " +
              Number(this.point.y).toLocaleString() +
              " (" +
              this.point.percentage.toFixed(2) +
              "%)" +
              "<br /><b>" +
              "Total Utilization" +
              "</b>" +
              " : " +
              data.total.toLocaleString()
            );
          },
        },
        series: [
          {
            innerSize: "50%",
            data: [
              {
                name: this.props.translate("Underutilized"),
                y: data?.underUtilized,
                color: "#6FBD43",
              },
              {
                name: this.props.translate("Overutilized"),
                y: data?.overUtilized,
                color: "#FFA600",
              },
            ],
          },
        ],
        legend: {
          enabled: true,
          useHTML: true,
          labelFormatter: function () {
            const percentage = ((this.y / data.total) * 100).toFixed(1); // calculate percentage
            return (
              '<div style="max-width: 100%; overflow: hidden; text-overflow: ellipsis; word-wrap: break-word;">' +
              "<b>" +
              this.name +
              "</b> : " +
              (isNaN(percentage) ? "0.0%" : percentage + "%") + // display percentage
              "</div>"
            );
          },
        },
        yAxis: {
          gridLineWidth: 0,
        },
        credits: {
          enabled: false,
        },
      }),
      show: true,
    };
  };

  getPieChart4 = (data) => {
    return {
      config: pieChartDefault().initializeWithOptions({
        chart: {
          type: "pie",
          height: "270px",
        },
        plotOptions: {
          pie: {
            innerSize: "50%",
            dataLabels: {
              enabled: false,
              format: "<b>{point.name}</b>: {point.percentage:.1f}%",
              distance: 10,
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b>" +
              " : " +
              Number(this.point.y).toLocaleString() 
              // " (" +
              // this.point.percentage.toFixed(2) +
              // "%)" +
              // "<br /><b>" +
              // "Total Utilization" +
              // "</b>" +
              // " : " +
              // data.total.toLocaleString()
            );
          },
        },
        series: [
          {
            innerSize: "50%",
            data: [
              {
                name: this.props.translate("Approval Pending"),
                y:(data?.estimate?.GarageToCustomer || 0) + (data?.self_service?.GarageToCustomer || 0),
                color: "#FFA600",
              },
              {
                name: this.props.translate("Completed"),
                y:(data?.estimate?.completed || 0) + (data?.self_service?.completed || 0),
                color: "#6FBD43",
              },
              {
                name: this.props.translate("Approved"),
                y: (data?.estimate?.required || 0) + (data?.self_service?.required || 0),
                color: "#FEF200",
              },
              {
                name: this.props.translate("Rejected"),
                y: (data?.estimate?.CustomerToGarage || 0) + (data?.self_service?.CustomerToGarage || 0),
                color: "#F93E3E",
              },
              {
                name: this.props.translate("Self Service"),
                y: data?.self_service?.total || 0,
                color: "#1E72B8",
              },
              {
                name: this.props.translate("Garage Recieved"),
                y:data?.estimate?.total || 0,
                color: "#D860F8",
              },
            ],
          },
        ],
        legend: {
          enabled: true,
          useHTML: true,
          labelFormatter: function () {
            const percentage = this.y ; 
            return (
              '<div style="max-width: 100%; overflow: hidden; text-overflow: ellipsis; word-wrap: break-word;">' +
              "<b>" +
              this.name +
              "</b> : " +
              (isNaN(percentage) ? "0" : percentage) + 
              "</div>"
            );
          },
        },
        yAxis: {
          gridLineWidth: 0,
        },
        credits: {
          enabled: false,
        },
      }),
      show: true,
    };
  };

  getPieChart = (data) => {
    let colors = [
      "#003F5C",
      "#29AF5C",
      "#009E6F",
      "#008C7D",
      "#1E72B8",
      "#6D71C5",
      "#007981",
      "#00667D",
      "#005270",
      "#6FBD43",
    ];
    const currency = this.props.logInUser?.attributes?.currencyLabel || "SAR";
    return {
      config: pieChartDefault().initializeWithOptions({
        chart: {
          type: "pie",
          height: "280px",
        },
        title: {
          text: "",
        },
        plotOptions: {
          pie: {
            innerSize: "50%", // Set the inner size to create the donut effect
            dataLabels: {
              enabled: true,
              format: "{point.name}: {point.percentage:.1f}%",
            },
          },
        },
        tooltip: {
          formatter: function () {  
            let currencySymbol = currency === "SAR" 
              ? '<span style="font-family: saudi_riyal;">&#xE900;</span>' 
              : currency
            return (
              "<b>" +
              "Vehicle License Plate : " +
              "</b>" +
              this.point.name +
              "<br/>" +
              "<b>" +
              "Total Maint Cost : " +
              "</b>" +
              Number(this.point.y).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              currencySymbol+
              "<br/>" +
              "<b>" +
              "Maint Count : " +
              "</b>" +
              this.point.count
            );
          },
        },

        series: [
          {
            name: "Monthly Data",
            innerSize: "50%",
            data: data?.map((item, index) => ({
              name: item.plateNumber,
              y: item.SaleNetAmount,
              count: item.Count,
              color: colors[index % colors.length], // Use the colors in a cyclic manner
            })),
          },
        ],
        legend: {
          enabled: false,
        },
        yAxis: {
          gridLineWidth: 0,
        },
        credits: {
          enabled: false,
        },
      }),
      show: true,
    };
  };

  getToFrom = (value) => {
    if (value === 1) {
      let value = new Date();
      let val = moment(value).startOf("month");
      let to = val._i.toISOString();
      let from = val._d.toISOString();
      let res = {
        to: to,
        from: from,
      };
      return res;
    } else if (value === 2) {
      let date = new Date();
      let from = new Date(
        Date.UTC(date.getUTCFullYear(), date.getUTCMonth() - 1, 1)
      ).toISOString();
      let date2 = new Date();
      let to = new Date(
        Date.UTC(date2.getUTCFullYear(), date2.getUTCMonth(), 0)
      ).toISOString();
      let res = {
        to: to,
        from: from,
      };
      return res;
    } else if (value === 3) {
      let today = new Date();
      let lastMonth = new Date();
      lastMonth.setMonth(today.getMonth() - 3);

      let from = new Date(
        lastMonth.getFullYear(),
        lastMonth.getUTCMonth() - 1,
        1
      );
      let to = new Date(today.getFullYear(), today.getMonth(), 0);
      let res = {
        to: to.toISOString(),
        from: from.toISOString(),
      };
      return res;
    } else if (value === 4) {
      let from = new Date(new Date().getFullYear(), 0, 1).toISOString();
      let to = new Date().toISOString();
      let res = {
        to: to,
        from: from,
      };
      return res;
    } else if (value === 6) {
      let res = {
        to: false,
        from: false,
      };
      return res;
    } else {
      let from = new Date(new Date().getFullYear() - 1, 0, 1).toISOString();
      let to = new Date(new Date().getFullYear() - 1, 11, 31).toISOString();
      let res = {
        to: to,
        from: from,
      };
      return res;
    }
  };

  handleChange = (name, e) => {
    this.setState({ [name]: e.label }, async () => {
      const res = await this.getToFrom(e.value);
      if (name === "tab1") {
        this.getFMD1(res.to, res.from);
        this.getFMD2(res.to, res.from);
        this.getFMD4(res.to, res.from);
      } else if (name === "tabSmall") {
        this.getFMD5(res.to, res.from);
      } else if (name === "tab2") {
        this.getFMD3(res.to, res.from);
      } else if (name === "tab3") {
        this.setState({ vehicleCategoryCost: "" }, () => {
          this.getFMD9(res.to, res.from);
        });
      } else if (name === "tab4") {
        this.setState({ topVehicleModelCost: "" }, () => {
          this.getFMD10(res.to, res.from);
        });
      } else if (name === "tab5") {
        this.setState({ usedPart: "" }, () => {
          this.getFMD7(res.to, res.from);
        });
      } else if (name === "tab6") {
        this.setState({ usedService: "" }, () => {
          this.getFMD8(res.to, res.from);
        });
      } else {
        this.setState({ packageAccount: "" }, () => {
          this.getFMD6(res.to, res.from);
        });
      }
    });
  };

  capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    // Replace underscores with spaces
    const stringWithSpaces = str.replace(/_/g, " ");
    // Capitalize the first letter
    return stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);
  };

  handleOpenMessage = () => {
    this.setState({ openModal: true });
  };
  handleCloseMessage = () => {
    this.setState({ openModal: false });
  };

  handleChangeMaintCost = (value) => {
    this.setState({ topVehiclesbyCost: value }, () => {});
  };
  handleChangeMaint = (value) => {
    this.setState({ topVehiclesbyMaint: value }, () => {});
  };
  handleChangeServiebyType = (value) => {
    this.setState({ servicesByType: value }, () => {});
  };

  render() {
    const {
      xs,
      sm,
      md,
      topVehiclesbyCost,
      topVehiclesbyMaint,
      servicesByType,
    } = this.state;
    let fmd9 = [];
    let fmd10 = [];
    for (const [key, value] of Object.entries(this.state.vehicleCategoryCost)) {
      fmd9.push({ name: key, cost: value });
    }
    for (const [key, value] of Object.entries(this.state.topVehicleModelCost)) {
      fmd10.push({ name: key, cost: value });
    }
    fmd9.sort((a, b) => b.cost - a.cost);
    fmd10.sort((a, b) => b.cost - a.cost);
    const { packages, items, services } = this.state.maintRequestCategory;
    let pa = packages ? packages : 0;
    let it = items ? items : 0;
    let se = services ? services : 0;
    // const data = {
    //   data: [
    //       { name: 'Scheduled', value: 65540, color: '#0288D1', formattedvalue: '65,540' },
    //       { name: 'Approved', value: 65540, color: '#28A745', formattedvalue: '65,540' },
    //       { name: 'Rejected', value: 65540, color: '#DC3545', formattedvalue: '65,540' },
    //   ],
    //   total: { name: 'Bookings', value: 65540 * 3, formattedvalue: '125,000' }
    // };

    let colors = ["primary", "success", "secondary", "danger", "warning"];
    const currency = this.props.logInUser?.attributes?.currencyLabel || "SAR";

    
    return (
      <Grid
        container
        spacing={2}
        style={{ backgroundColor: "#F1F6F9", padding: "10px" }}
      >
        {/* <Grid container spacing={2} style={{backgroundColor:"black", padding:"10px" }}> */}
        {/* Row 1  C1*/}
        {/* <Grid item xs={12} sm={6} md={4} lg={4}  > */}
        {/* <Grid container spacing={2}> */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "110px", maxWidth: "120px" }}>
                <h3
                  className="row-label"
                  style={{ textAlign: "left", fontWeight: 800, fontSize: 15 }}
                >
                  {this.props.translate("totalFleetMaintInvestments")}
                </h3>
                {/* <h3 className="row-label" style={{textAlign:"right",fontSize:12, fontWeight:600 }}> */}
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: 12,
                  fontWeight: 600,
                  width: "90px",
                }}
              >
                <Select
                  options={FiltersOption}
                  // value={"this.state.tab1"}]
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      backgroundColor: "transparent",
                      height: "10px",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      fontSize: "12px", // Set the font size
                      maxHeight: "100px", // Set the maximum height of the dropdown menu
                      overflowY: "auto", // Enable vertical scrolling if the content overflows
                    }),
                  }}
                  value={{
                    label: this.state.tab1 ? this.state.tab1 : "Select",
                  }}
                  onChange={(e) => this.handleChange("tab1", e)}
                />
              </div>
              {/* <div style={{width:"211px",color:"#fff", height:"84px", backgroundColor:"green", padding:"17px 14px",borderRadius:"8px" }}>
                    <p style={{fontSize:"14px", fontWeight:"600"}}>Online</p>
                    <p style={{fontSize:"24px", fontWeight:"800"}}>768</p>
                </div> */}
              {/* </h3> */}
            </div>
            <div style={{ marginTop: "14px" }}>
              <div
                style={{
                  width: "100%",
                  minWidth: "204px",
                  color: "#fff",
                  height: "86px",
                  backgroundImage: `url(${onlineSvg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  padding: "12px 0 18px 14px",
                  borderRadius: "8px 8px 18px 18px",
                }}
              >
                {/* <TotalInvoices/> */}
                <p
                  style={{
                    width: "100%",
                    color: "#ffffff",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  {this.props.translate("totalInvoices")}
                </p>
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginTop: "14px",
                  }}
                >
                  <Tooltip
                    title={
                      <Typography style={{ fontSize: "14px" }}>
                        {this.state?.totalCost?.toLocaleString() || 0}
                      </Typography>
                    }
                    placement="bottom-start"
                    enterDelay={300}
                    classes={{ tooltip: "customTooltip" }}
                  >
                    <span style={textStyle}>
                      {" "}
                      {this.state?.totalCost
                        ? nFormatter(this.state?.totalCost, 2)
                        : 0}
                    </span>
                  </Tooltip>
      <span style={{ color: "#ffffff", marginLeft: "10px",fontSize:"1.5rem"}}>
      {currency.toUpperCase()==="SAR"? <span className="icon-saudi_riyal"></span>:currency}
      </span>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  minWidth: "204px",
                  marginTop: "4px",
                  color: "#fff",
                  height: "86px",
                  backgroundImage: `url(${gpsNotUpdatedSvg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  padding: "12px 0 18px 14px",
                  borderRadius: "8px",
                }}
              >
                <p
                  style={{
                    width: "100%",
                    color: "#ffffff",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  {this.props.translate("totalOverdue")}
                </p>
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginTop: "14px",
                  }}
                >
                  <Tooltip
                    title={
                      <Typography style={{ fontSize: "14px" }}>
                        {this.state?.totalOutStanding?.toLocaleString() || 0}
                      </Typography>
                    }
                    placement="bottom-start"
                    enterDelay={300}
                    classes={{ tooltip: "customTooltip" }}
                  >
                    <span style={textStyle}>
                      {this.state?.totalOutStanding
                        ? nFormatter(this.state?.totalOutStanding, 2)
                        : 0}
                    </span>
                  </Tooltip>
      <span style={{ color: "#ffffff", marginLeft: "10px",
        fontSize:"1.5rem"
       }}>
      {currency.toUpperCase()==="SAR"? <span className="icon-saudi_riyal"></span>:currency}
      </span>
                </div>
              </div>
              {/* <div style={{width:"211px",color:"#fff", height:"84px",
                //  backgroundColor:"green",
                   backgroundImage: `url(${onlineSvg})` ,
                  padding:"17px 14px",borderRadius:"8px" }}></div> */}

              <div
                style={{
                  width: "100%",
                  minWidth: "204px",
                  marginTop: "4px",
                  color: "#fff",
                  height: "86px",
                  backgroundImage: `url(${maintRequest})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  padding: "12px 0 18px 14px",
                  borderRadius: "8px",
                }}
              >
                <p
                  style={{
                    width: "100%",
                    color: "#ffffff",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  {this.props.translate("maintenanceRequest")}
                </p>
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginTop: "14px",
                  }}
                >
                  <Tooltip
                    title={
                      <Typography style={{ fontSize: "14px" }}>
                        {this.state?.maintRequest?.toLocaleString() || 0}
                      </Typography>
                    }
                    placement="bottom-start"
                    enterDelay={300}
                    classes={{ tooltip: "customTooltip" }}
                  >
                    <span style={textStyle}>
                      {this.state?.maintRequest
                        ? nFormatter(this.state?.maintRequest, 2)
                        : 0}
                    </span>
                    {/* {parseInt(this.state?.maintRequest|| 0)}</span> */}
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          style={{ padding: "0px 5px", zIndex: 9 }}
        >
          {this.state.loader13 ? (
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                height: "100%",
                padding: "12px 12px 0px 12px",
              }}
            >
              <MaintenanceItemEmpty />
            </div>
          ) : (
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                height: "334px",
                padding: "11px 12px 0px 12px",
              }}
            >
              {/* <p style={{textAlign: "left", fontWeight: 600, fontSize: 18}}>{this.props.translate("vehicleUtilization")}</p> */}
              <h3
                className="row-label"
                style={{ textAlign: "left", fontWeight: 800, fontSize: 15 }}
              >
                {this.props.translate("vehicleUtilization")}
              </h3>

              {/* {vehicleUtilizationChart && vehicleUtilizationChart.config ? (
                          <ReactHighcharts
                            domProps={{ id: 'vehicleUtilizationChart' }}
                            config={vehicleUtilizationChart.config}
                          />
                      ) : null} */}
              <HighchartsReact
                highcharts={Highcharts}
                options={vehicleUtilizationChart.config}
              />
            </div>
          )}
        </Grid>
        {/* </Grid> */}
        {/* </Grid> */}

        {/* Row 1  C2*/}
        {/* <Grid item xs={12} sm={6} md={4} lg={2} style={{padding:"0px 5px"}} >
                 {this.state.loader15 ? (
                    <div style={{backgroundColor:"white", borderRadius:"8px", height:"100%", padding:"12px 12px 0px 12px"}}>
                    <MaintenanceItemEmpty />
                    </div>
                  ) : (
                    <div style={{backgroundColor:"white", borderRadius:"8px", height:"334px", padding:"11px 12px 0px 12px"}} >
                      <h3 className="row-label" style={{textAlign:"left", fontWeight:800,fontSize:15}}>{this.props.translate("Credit Limit")}</h3>
                      <div style={{marginTop:"25px"}}>
                        <div style={{marginTop:"25px"}}>
                          <p style={{ fontWeight:800, fontSize : "12px" }}>Total Credit</p>
                          <p style={{color:"#6FBD43",fontSize : "24px",marginTop:"10px",  fontWeight:800, marginLeft:"4px"}}>{this.state?.balanceCreditData?.credit?.toFixed(2) || 0}<span style={{fontSize:"12px" , color:"black", marginLeft:"5px"}}>SAR</span></p>
                        </div>
                        <div style={{marginTop:"20px"}}>
                          <p style={{ fontWeight:800, fontSize : "12px", }}>Credit Due</p>
                          <p style={{color:"#1E72B8",fontSize : "24px",marginTop:"10px",  fontWeight:800, marginLeft:"4px"}}>{this.state?.balanceCreditData?.balanceDue?.toFixed(2) || 0}<span style={{fontSize:"12px" , color:"black", marginLeft:"5px"}}>SAR</span></p>
                        </div>
                        <div style={{marginTop:"20px"}}>
                          <p style={{ fontWeight:800,fontSize : "12px",  }}>Remaining Credit</p>
                          <p style={{color:"'#FFA600",fontSize : "24px",marginTop:"10px",  fontWeight:800, marginLeft:"4px"}}>{this.state?.balanceCreditData?.remainingBalance?.toFixed(2) || 0}<span style={{fontSize:"12px" , color:"black", marginLeft:"5px"}}>SAR</span></p>
                        </div>
                      </div>
                    
                    </div>)}
             </Grid> */}
        <Grid item xs={12} sm={6} md={6} lg={4} style={{ padding: "0px 5px" }}>
          <div>
            {ownChart.config ? (
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  height: "100%",
                  padding: "10px 12px 0px 12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3
                    className="row-label"
                    style={{ textAlign: "left", fontWeight: 800, fontSize: 15 }}
                  >
                    {this.props.translate("totalFleetSize")}
                  </h3>
                  <Tooltip
                    title={
                      <Typography style={{ fontSize: "14px" }}>
                        {this.state.totalVehicles?.toLocaleString() || ""}
                      </Typography>
                    }
                    placement="bottom-start"
                    enterDelay={300}
                    classes={{ tooltip: "customTooltip" }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: 18,
                        fontWeight: 800,
                        color: "#6FBD43",
                        marginLeft: "20px",
                      }}
                    >
                      {this.state.totalVehicles
                        ? nFormatter(this.state.totalVehicles, 2)
                        : ""}
                    </span>
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {ownChart && ownChart.config ? (
                    <div>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={ownChart.config}
                      />
                      {/* <ReactHighcharts
                            domProps={{ id: 'ownChart' }}
                            config={ownChart.config}
                          /> */}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  height: "100%",
                }}
              >
                <MaintenanceItemEmpty />
              </div>
            )}
          </div>

          <div style={{ marginTop: "10px" }}>
            {this.state.loader15 ? (
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  height: "100%",
                  padding: "12px 12px 0px 12px",
                }}
              >
                <MaintenanceItemEmpty />
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  // height:"334px",
                  padding: "11px 12px 10px 12px",
                }}
              >
                <h3
                  className="row-label"
                  style={{ textAlign: "left", fontWeight: 800, fontSize: 15 }}
                >
                  {this.props.translate("Credit Limit")}
                </h3>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ marginTop: "15px" }}>
                    <p style={{ fontWeight: 800, fontSize: "12px" }}>
                      {this.props.translate("Total Credit Limit")}
                    </p>
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {this.state?.balanceCreditData?.credit?.toFixed(2) ||
                            0}
                        </Typography>
                      }
                      placement="bottom-start"
                      enterDelay={300}
                      classes={{ tooltip: "customTooltip" }}
                    >
                      <p
                        style={{
                          color: "#6FBD43",
                          fontSize: "24px",
                          marginTop: "5px",
                          fontWeight: 800,
                        }}
                      >
                        {nFormatter(this.state?.balanceCreditData?.credit, 2) ||
                          0}
                        {/* {this.state?.balanceCreditData?.credit?.toFixed(2) || 0} */}
                        <span
                          style={{
                            fontSize: "12px",
                            color: "black",
                            marginLeft: "5px",
                          }}
                        >
                                                    {currency.toUpperCase()==="SAR"? <span className="icon-saudi_riyal"></span>:currency}
      </span>
                      </p>
                    </Tooltip>
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <p style={{ fontWeight: 800, fontSize: "12px" }}>
                      {this.props.translate("Due Amount")}
                    </p>
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {this.state?.balanceCreditData?.balanceDue?.toFixed(
                            2
                          ) || 0}
                        </Typography>
                      }
                      placement="bottom-start"
                      enterDelay={300}
                      classes={{ tooltip: "customTooltip" }}
                    >
                      <p
                        style={{
                          color: "#1E72B8",
                          fontSize: "24px",
                          marginTop: "5px",
                          fontWeight: 800,
                        }}
                      >
                        {nFormatter(
                          this.state?.balanceCreditData?.balanceDue,
                          2
                        ) || 0}

                        <span
                          style={{
                            fontSize: "12px",
                            color: "black",
                            marginLeft: "5px",
                          }}
                        >
                                {currency.toUpperCase()==="SAR"? <span className="icon-saudi_riyal"></span>:currency}

                        </span>
                      </p>
                    </Tooltip>
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <p style={{ fontWeight: 800, fontSize: "12px" }}>
                      {this.props.translate("Remaining Credit Limit")}
                    </p>
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {this.state?.balanceCreditData?.remainingBalance?.toFixed(
                            2
                          ) || 0}
                        </Typography>
                      }
                      placement="bottom-start"
                      enterDelay={300}
                      classes={{ tooltip: "customTooltip" }}
                    >
                      <p
                        style={{
                          color: "orange",
                          fontSize: "24px",
                          marginTop: "5px",
                          fontWeight: 800,
                        }}
                      >
                        {this.state?.balanceCreditData?.remainingBalance > 0
                          ? nFormatter(
                              this.state?.balanceCreditData?.remainingBalance,
                              2
                            )
                          : this.state?.balanceCreditData?.remainingBalance?.toFixed(
                              2
                            ) || 0}
                        <span
                          style={{
                            fontSize: "12px",
                            color: "black",
                            marginLeft: "5px",
                          }}
                        >
      {currency.toUpperCase()==="SAR"? <span className="icon-saudi_riyal"></span>:currency}

                        </span>
                      </p>
                    </Tooltip>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Grid>

        {/* Row 1  C3*/}
        <Grid item xs={12} sm={6} md={6} lg={4} style={{ padding: "0px 5px" }}>
          <Grid
            container
            style={{
              backgroundColor: "white",
              borderRadius: "8px",
              height: "100%",
              padding: "10px 12px 0px 12px",
            }}
          >
            <Grid item xs={12} sm={6} md={6} lg={6}>
              {this.state?.carbonFootPrint ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "208px",
                    }}
                  >
                    {/* <p style={{textAlign:"left", fontWeight:600,fontSize:18}}>
                        {this.props.translate("decarbonisation")}
                      </p> */}
                    <h3
                      className="row-label"
                      style={{
                        textAlign: "left",
                        fontWeight: 800,
                        fontSize: 15,
                      }}
                    >
                      {this.props.translate("decarbonisation")}
                    </h3>

                    <div
                      style={{
                        backgroundColor: this.state.isHovered ? "#6FBD43" : "",
                        borderRadius: "3px",
                        cursor: "pointer",
                        marginTop: "5px",
                      }}
                      onClick={this.handleOpenMessage}
                      onMouseOver={() => this.setState({ isHovered: true })}
                      onMouseOut={() => this.setState({ isHovered: false })}
                    >
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          color: this.state.isHovered ? "white" : "Black",
                          // textDecoration: this.state.isHovered ? "none" : "underline",
                          width: "100%",
                        }}
                      >
                        {this.props.translate(
                          "Let our experts help you go green and save money with your fleet"
                        )}{" "}
                        <ContactSupportIcon style={{ fontSize: 14 }} />
                      </p>
                    </div>
                  </div>
                  <div style={{ marginTop: "45px", paddingLeft: "10px" }}>
                    <img src={co2svg} alt="" />
                  </div>

                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 600,
                      fontSize: 18,
                      margin: "25px 0",
                      //  display:"flex",
                      //  justifyContent:"space-between",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 700,
                        display: "block",
                        fontSize: 14,
                        //  marginTop: '20px'
                      }}
                    >
                      {this.props.translate("total")} CO<sub>2</sub>{" "}
                      {this.props.translate("emitted")}
                    </span>{" "}
                    &nbsp;&nbsp;&nbsp;
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {this.state?.carbonFootPrint?.totalCarbonFootPrint?.toLocaleString() ||
                            ""}
                        </Typography>
                      }
                      placement="bottom-start"
                      enterDelay={300}
                      classes={{ tooltip: "customTooltip" }}
                    >
                      <span
                        style={{
                          fontWeight: 800,
                          fontSize: 14,
                          color: "#6FBD43",
                          marginLeft: "20px",
                        }}
                      >
                        {this.state?.carbonFootPrint?.totalCarbonFootPrint?.toLocaleString(
                          undefined,
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        ) || 0}{" "}
                        {this.props.translate("metricTons")}
                      </span>
                    </Tooltip>
                  </p>
                </>
              ) : (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    height: "100%",
                  }}
                >
                  <MaintenanceItemEmpty />
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              {this.state.carbonFootPrint ? (
                <>
                  <h3
                    className="row-label"
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      fontSize: 15,
                      margin: "20px 0 10px 0",
                      width: "100%",
                    }}
                  >
                    {this.props.translate("byVehicleCategory")}
                    {/* {this.props.translate("byVehicleCategory")} */}
                    <span
                      style={{
                        marginLeft: "5px",
                        paddingTop: "10px !important",
                      }}
                    >
                      <Tooltip
                        title="All units are in Metric Tons"
                        placement="bottom-start"
                        enterDelay={300}
                      >
                        <InfoIcon
                          style={{ fontSize: "16px" }}
                          className="customTooltip"
                        />
                      </Tooltip>
                    </span>
                  </h3>

                  <Scrollbar style={{ height: "245px" }}>
                    <ul className="md-bar-graphlist">
                      {this.state.carbonFootPrint.categoryWiseCarbonFootPrint?.map(
                        (item) => (
                          <li
                            style={{
                              backgroundColor: "#EBEBEB",
                              borderRadius: "8px",
                              minWidth: "192px",
                              height: "32px",
                              margin: "0 10px 10px 0",
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontSize: 14,
                                fontWeight: 500,
                                textTransform: "capitalize",
                                padding: "2px 10px",
                              }}
                            >
                              <span
                                style={{
                                  marginRight: "10px",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                {this.props.translate(item.name)}
                              </span>
                              <span
                                style={{
                                  marginRight: "10px",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                {item.value?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                  </Scrollbar>
                </>
              ) : (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    height: "100%",
                  }}
                >
                  <MaintenanceItemEmpty />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* Row 2 */}

        {/* Row 2  C1*/}
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          style={{ minHeight: "320px", marginTop: "10px" }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{ padding: "0px 5px" }}
            >
              {this.state.loader17 ? (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: "10px 12px 0px 12px",
                  }}
                >
                  <MaintenanceItemEmpty />
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: "10px 12px 0px 12px",
                  }}
                >
                  {/* <p style={{textAlign:"left", fontWeight:600,fontSize:18}}>{this.props.translate("top10VehiclesbyMaint")}</p> */}
                  <h3
                    className="row-label"
                    style={{ textAlign: "left", fontWeight: 800, fontSize: 15 }}
                  >
                    {this.props.translate("top10VehiclesbyMaint")}{" "}
                    {this.props.translate("cost")}
                  </h3>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "15px 0px 15px 0px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        this.handleChangeMaintCost("costPerDay");
                      }}
                      style={{
                        width: "113px",
                        height: "36px",
                        marginLeft: "5px",
                        color:
                          topVehiclesbyCost === "costPerDay"
                            ? "white"
                            : "black",
                        backgroundColor:
                          topVehiclesbyCost === "costPerDay"
                            ? "black"
                            : "white",
                        border:
                          topVehiclesbyCost === "costPerDay"
                            ? "black"
                            : "2px solid green",
                        borderRadius: "8px",
                      }}
                    >
                      {this.props.translate("perDay")}
                    </Button>
                    <Button
                      onClick={() => {
                        this.handleChangeMaintCost("costPerKm");
                      }}
                      style={{
                        width: "113px",
                        height: "36px",
                        marginLeft: "5px",
                        color:
                          topVehiclesbyCost === "costPerKm" ? "white" : "black",
                        backgroundColor:
                          topVehiclesbyCost === "costPerKm" ? "black" : "white",
                        border:
                          topVehiclesbyCost === "costPerKm"
                            ? "black"
                            : "2px solid green",
                        borderRadius: "8px",
                      }}
                    >
                      {" "}
                      {this.props.translate("perKM")}{" "}
                    </Button>
                  </div>

                  <div style={{ marginTop: "15px" }}>
                    {/* // <h2>chart</h2>
                                  // <ReactHighcharts
                                  //   domProps={{ id: 'topMaintenancePerDay' }}
                                  //   config={topMaintenancePerDay.config}
                                  // /> */}

                    {topVehiclesbyCost === "costPerDay" ? (
                      <>
                        {topMaintenancePerDay && topMaintenancePerDay ? (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={topMaintenancePerDay.config}
                          />
                        ) : null}
                      </>
                    ) : topVehiclesbyCost === "costPerKm" ? (
                      <>
                        {/* // <h2>chart</h2>
                                  // <ReactHighcharts
                                  //   domProps={{ id: 'topMaintenancePerKm' }}
                                  //   config={topMaintenancePerKm.config}
                                  // />   */}
                        {topMaintenancePerKm && topMaintenancePerKm ? (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={topMaintenancePerKm.config}
                          />
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </div>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{ padding: "0px 5px" }}
            >
              {this.state.loader17 ? (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: "10px 12px 0px 12px",
                  }}
                >
                  <MaintenanceItemEmpty />
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: "10px 12px 0px 12px",
                  }}
                >
                  {/* <p style={{textAlign: "left", fontWeight: 600, fontSize: 18}}>{this.props.translate("top10VehiclesbyMaint")}</p> */}
                  <h3
                    className="row-label"
                    style={{ textAlign: "left", fontWeight: 800, fontSize: 15 }}
                  >
                    {this.props.translate("top10VehiclesbyHighestMaint")}
                  </h3>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "50px 0px 15px 0px",
                    }}
                  >
                    {/* <Button 
                              onClick={()=>{this.handleChangeMaint("maintCost")}}
                             style={{width:"113px", height:"36px", marginLeft:"5px",
                               color:topVehiclesbyMaint === "maintCost" ? "black" : "white",
                               backgroundColor:topVehiclesbyMaint === "maintCost" ? "white" : "black",
                               border:topVehiclesbyMaint === "maintCost" ? "2px solid green" : "black",
                               borderRadius:"8px"
                               }}
                          >
                            Maint. Costs</Button>
                            <Button 
                              onClick={()=>{this.handleChangeMaint("maintVisits")}}
                             style={{width:"113px", height:"36px", marginLeft:"5px",
                               color:topVehiclesbyMaint === "maintVisits" ? "black" : "white",
                               backgroundColor:topVehiclesbyMaint === "maintVisits" ? "white" : "black",
                               border:topVehiclesbyMaint === "maintVisits" ? "2px solid green" : "black",
                               borderRadius:"8px"
                               }}
                          >
                            Maint. Visits</Button> */}
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <Grid item xs={12} md={12}>
                      {/* // <h3>chart</h3>
                                      // <ReactHighcharts
                                      //   domProps={{ id: 'topVehiclesChart' }}
                                      //   config={topVehiclesChart.config}
                                      // /> */}
                      {topVehiclesChart && topVehiclesChart.config ? (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={topVehiclesChart.config}
                        />
                      ) : null}
                    </Grid>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            style={{ minHeight: "320px", marginTop: "10px" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              style={{ minHeight: "285px" }}
            >
              {this.state.partsMaintRequest?.countPercentage ? (
                <div
                  style={{
                    backgroundColor: "white",
                    minHeight: "285px",
                    borderRadius: "8px",
                    padding: "3px 12px 0px 12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >  
                    <h3
                      className="row-label"
                      style={{
                        textAlign: "left",
                        fontWeight: 800,
                        fontSize: 15,
                        marginTop:"8px"
                      }}
                    >
                      {this.props.translate("maintenanceRequestCategories")}
                    </h3>

                    {/* <p  style={{textAlign:"left", fontSize:16,fontWeight:800}}>
                            {this.props.translate("maintenanceRequestCategories")}</p> */}
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: 12,
                        fontWeight: 600,
                        width: "90px",
                      }}
                    >
                      <Select
                        options={FiltersOption}
                        value={{
                          label: this.state.tabSmall
                            ? this.state.tabSmall
                            : "Select",
                        }}
                        onChange={(e) => this.handleChange("tabSmall", e)}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "none",
                            backgroundColor: "transparent",
                            height: "10px",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            fontSize: "12px", // Set the font size
                            maxHeight: "100px", // Set the maximum height of the dropdown menu
                            overflowY: "auto", // Enable vertical scrolling if the content overflows
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "26px",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "left",
                        marginTop: 6,
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      {this.props.translate("totalMaintenanceRequestCount")}
                    </p>
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "14px" }}>
                          {(pa + it + se)?.toLocaleString()}
                        </Typography>
                      }
                      placement="bottom-start"
                      enterDelay={300}
                      classes={{ tooltip: "customTooltip" }}
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "800",
                          color: "#6FBD43",
                          textAlign: "right",
                        }}
                      >
                        {nFormatter(pa + it + se, 2)}
                      </span>
                    </Tooltip>
                  </div>
                  <div style={{ marginTop: "30px" }}>
                    <ul className="md-bar-graphlist">
                      <li>
                        {/* <span  style={{display:"flex",justifyContent:"space-between"}} className="row-label"><em>{this.props.translate("parts")}</em> 
                             <strong style={{marginRight:"10px"}}>
                             {(() => {
                                    try
                                     {
                                      const realValue = items;
                                      const integerAmount = Math.floor(realValue);
                                      const formattedAmount = integerAmount.toLocaleString();
                                      return formattedAmount;
                                    } catch (error) {
                                      console.error('Error:', error);
                                      return "Error";
                                    }
                                  })()}
                             
                             </strong></span> */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "20%" }}>
                            <em>{this.props.translate("parts")}</em>
                            <br></br>
                            <strong style={{ marginRight: "10px" }}>
                              {(() => {
                                try {
                                  const realValue = items ? items : 0;
                                  const integerAmount = Math.floor(realValue);
                                  const formattedAmount =
                                    integerAmount.toLocaleString();
                                  return formattedAmount;
                                } catch (error) {
                                  console.error("Error:", error);
                                  return "Error";
                                }
                              })()}
                            </strong>
                          </div>
                          <div style={{ width: "75%" }}>
                            <LinearProgress
                              style={{ height: 32 }}
                              className="danger"
                              variant="determinate"
                              value={parseInt(
                                this.state.partsMaintRequest?.countPercentage ||
                                  0
                              )}
                            />
                            {/* <LinearProgress style={{height: 32}} className="error" variant="determinate" value={parseInt(this.state.partsMaintRequest?.countPercentage || 0)} /> */}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "20%" }}>
                            <em>{this.props.translate("services")}</em>
                            <br></br>
                            <strong style={{ marginRight: "10px" }}>
                              {(() => {
                                try {
                                  const realValue = services ? services : 0;
                                  const integerAmount = Math.floor(realValue);
                                  const formattedAmount =
                                    integerAmount.toLocaleString();
                                  return formattedAmount;
                                } catch (error) {
                                  console.error("Error:", error);
                                  return "Error";
                                }
                              })()}
                            </strong>
                          </div>
                          <div style={{ width: "75%" }}>
                            <LinearProgress
                              style={{ height: 32 }}
                              className="#1E72B8"
                              variant="determinate"
                              value={parseInt(
                                this.state.serviceMaintRequest
                                  ?.countPercentage || 0
                              )}
                            />
                          </div>
                        </div>
                      </li>

                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "20%" }}>
                            <em>{this.props.translate("packages")}</em>
                            <br></br>
                            <strong style={{ marginRight: "10px" }}>
                              {(() => {
                                try {
                                  const realValue = packages ? packages : 0;
                                  const integerAmount = Math.floor(realValue);
                                  const formattedAmount =
                                    integerAmount.toLocaleString();
                                  return formattedAmount;
                                } catch (error) {
                                  console.error("Error:", error);
                                  return "Error";
                                }
                              })()}
                            </strong>
                          </div>
                          <div style={{ width: "75%" }}>
                            <LinearProgress
                              style={{ height: 32 }}
                              className="success"
                              variant="determinate"
                              value={parseInt(
                                this.state.packageMaintRequest
                                  ?.countPercentage || 0
                              )}
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>{" "}
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: "12px 12px 0px 12px",
                  }}
                >
                  <MaintenanceItemEmpty />
                </div>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ minHeight: "285px" }}
            >
              {this.state.servicesData ? (
            <div
            style={{
              backgroundColor: "white",
              borderRadius: "8px",
              height: "285px",
              padding: "11px 12px 0px 12px",
            }}
          >
            <h3
              className="row-label"
              style={{ textAlign: "left", fontWeight: 800, fontSize: 15 }}
            >
              {this.props.translate("Services Status")}
            </h3>
            <HighchartsReact
              highcharts={Highcharts}
              options={serviceUtilizationChart.config}
            />
          </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: "12px 12px 0px 12px",
                  }}
                >
                  <MaintenanceItemEmpty />
                </div>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={5} lg={5}>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  padding: "12px 12px 0px 12px",
                  minHeight: "285px",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* <p style={{textAlign:"left", fontWeight:600,fontSize:18}}>{this.props.translate("maintenanceCostPerCategory")} <span>SAR</span>
                    </p> */}
                  <h3
                    className="row-label"
                    style={{ textAlign: "left", fontWeight: 800, fontSize: 15 }}
                  >
                    {this.props.translate("maintenanceCost")}{" "}
                    {this.props.translate("byVehicleClassification")}
                    {/* <span style={{fontSize:12, fontWeight:400, marginLeft:10}}>SAR</span> */}
                  </h3>

                  {/* <p>Select</p> */}
                </div>

                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p>
                        {this.props.translate("per")}
                        <span
                          style={{
                            color: "#1E72B8",
                            fontWeight: 800,
                            marginLeft: "4px",
                          }}
                        >
                          {this.props.translate("category")}
                        </span>
                      </p>
                      <div
                        style={{
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: 600,
                          width: "90px",
                        }}
                      >
                        <Select
                          options={FiltersOption}
                          // value={"this.state.tab1"}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              border: "none",
                              backgroundColor: "transparent",
                              height: "10px",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              fontSize: "12px", // Set the font size
                              maxHeight: "100px", // Set the maximum height of the dropdown menu
                              overflowY: "auto", // Enable vertical scrolling if the content overflows
                            }),
                          }}
                          value={{
                            label: this.state.tab3 ? this.state.tab3 : "Select",
                          }}
                          onChange={(e) => this.handleChange("tab3", e)}
                        />
                      </div>
                    </div>
                    {fmd9 && fmd9.length > 0 ? (
                      <Scrollbar style={{ height: "175px", marginTop: "8px" }}>
                        <ul
                          className="md-bar-graphlist"
                          // style={{ marginTop:17 }}
                        >
                          {fmd9 &&
                            fmd9.map((item) => (
                              <li
                                style={{
                                  backgroundColor: "#F1F6F9",
                                  borderRadius: "8px",
                                  margin: "4px 0 0 0",
                                  height: "42px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    padding: "7px 12px",
                                  }}
                                >
                                  <span>
                                    {item.name === "car"
                                      ? "SEDAN"
                                      : item.name?.toUpperCase()}
                                    {/* {this.capitalize(item?.nametoUpperCase()) || ""} */}
                                  </span>
                                  <br></br>
                                  <strong style={{ marginLeft: "10px" }}>
                                    {/* {parseInt(item?.cost||0) }  */}
                                    {/* {item?.cost.toLocaleString() || ""} */}

                                    {(() => {
                                      try {
                                        const realValue = item?.cost;
                                        const integerAmount =
                                          Math.floor(realValue);
                                        const formattedAmount =
                                          integerAmount.toLocaleString();
                                        // const amountWithCurrency = `${formattedAmount} {this.props.translate("sar")}`;
                                        const amountWithCurrency = (
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                         {formattedAmount}{currency.toUpperCase()==="SAR"? <span className="icon-saudi_riyal"></span>:currency}
                                            {/* <span style={{ fontSize: "12px", fontWeight: "400" }}>{this.props.translate("sar")}</span>  */}
                                          </span>
                                        );
                                        return amountWithCurrency;
                                      } catch (error) {
                                        console.error("Error:", error);
                                        return "Error";
                                      }
                                    })()}
                                  </strong>
                                </span>
                              </li>
                            ))}
                        </ul>
                      </Scrollbar>
                    ) : (
                      <div
                        style={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                          padding: "12px 12px 0px 12px",
                        }}
                      >
                        <MaintenanceItemEmpty />
                      </div>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    style={{ padding: "10px 5px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p>
                        {this.props.translate("per")}
                        <span
                          style={{
                            color: "#6FBD43",
                            fontWeight: 800,
                            marginLeft: "4px",
                          }}
                        >
                          {this.props.translate("model")}
                        </span>
                      </p>
                      <div
                        style={{
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: 600,
                          width: "90px",
                        }}
                      >
                        <Select
                          options={FiltersOption}
                          // value={"this.state.tab1"}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              border: "none",
                              backgroundColor: "transparent",
                              height: "10px",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              fontSize: "12px", // Set the font size
                              maxHeight: "100px", // Set the maximum height of the dropdown menu
                              overflowY: "auto", // Enable vertical scrolling if the content overflows
                            }),
                          }}
                          value={{
                            label: this.state.tab4 ? this.state.tab4 : "Select",
                          }}
                          onChange={(e) => this.handleChange("tab4", e)}
                        />
                      </div>
                    </div>
                    {fmd10 && fmd10.length > 0 ? (
                      <Scrollbar style={{ height: "175px", marginTop: "8px" }}>
                        <ul className="md-bar-graphlist">
                          {fmd10 &&
                            fmd10.map((item) => (
                              <li
                                style={{
                                  backgroundColor: "#F1F6F9",
                                  borderRadius: "8px",
                                  margin: "4px 0 0 0",
                                  height: "42px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    padding: "7px 12px",
                                  }}
                                >
                                  <span>
                                    {this.capitalize(item?.name) || ""}
                                  </span>
                                  <br></br>
                                  <strong style={{ marginLeft: "10px" }}>
                                    {/* {parseInt(item?.cost) ||""} SR  */}
                                    {(() => {
                                      try {
                                        const realValue = item?.cost;
                                        const integerAmount =
                                          Math.floor(realValue);
                                        const formattedAmount =
                                          integerAmount.toLocaleString();
                                        // const amountWithCurrency = `${formattedAmount} {this.props.translate("sar")}`;
                                        const amountWithCurrency = (
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                      {formattedAmount}{currency.toUpperCase()==="SAR"? <span className="icon-saudi_riyal"></span>:currency}
                                            {/* <span style={{ fontSize: "12px", fontWeight: "400" }}>{this.props.translate("sar")}</span>  */}
                              </span>
                                        );
                                        return amountWithCurrency;
                                      } catch (error) {
                                        console.error("Error:", error);
                                        return "Error";
                                      }
                                    })()}
                                  </strong>
                                </span>
                              </li>
                            ))}
                        </ul>
                      </Scrollbar>
                    ) : (
                      <div
                        style={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                          padding: "12px 12px 0px 12px",
                        }}
                      >
                        <MaintenanceItemEmpty />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* Row 2  C2*/}
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ minHeight: "320px", marginTop: "1px" }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "1px 12px 0px 12px",
              minHeight: "660px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <p style={{textAlign:"left", fontWeight:600,fontSize:18}}>{this.props.translate("servicesByTypes")}</p> */}
              <h3
                className="row-label"
                style={{ textAlign: "left", fontWeight: 800, fontSize: 15 }}
              >
                {this.props.translate("High-Demand Parts & Services")}
              </h3>

              <div
                style={{
                  textAlign: "left",
                  fontSize: 12,
                  fontWeight: 600,
                  width: "90px",
                }}
              >
                {servicesByType === "topParts" ? (
                  <Select
                    options={FiltersOption}
                    value={{
                      label: this.state.tab5 ? this.state.tab5 : "Select",
                    }}
                    onChange={(e) => this.handleChange("tab5", e)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "none",
                        backgroundColor: "transparent",
                        height: "10px",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        fontSize: "12px", // Set the font size
                        maxHeight: "100px", // Set the maximum height of the dropdown menu
                        overflowY: "auto", // Enable vertical scrolling if the content overflows
                      }),
                    }}
                  />
                ) : servicesByType === "topService" ? (
                  <Select
                    options={FiltersOption}
                    // value={"this.state.tab1"}
                    value={{
                      label: this.state.tab6 ? this.state.tab6 : "Select",
                    }}
                    onChange={(e) => this.handleChange("tab6", e)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "none",
                        backgroundColor: "transparent",
                        height: "10px",
                      }),
                    }}
                  />
                ) : servicesByType === "topPackages" ? (
                  <Select
                    options={FiltersOption}
                    // value={"this.state.tab1"}
                    value={{
                      label: this.state.tab7 ? this.state.tab7 : "Select",
                    }}
                    onChange={(e) => this.handleChange("tab7", e)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "none",
                        backgroundColor: "transparent",
                        height: "10px",
                      }),
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "12px",
              }}
            >
              <Button
                onClick={() => {
                  this.handleChangeServiebyType("topService");
                }}
                style={{
                  width: "113px",
                  height: "36px",
                  marginLeft: "5px",
                  color: servicesByType === "topService" ? "white" : "black",
                  backgroundColor:
                    servicesByType === "topService" ? "black" : "white",
                  border:
                    servicesByType === "topService"
                      ? "black"
                      : "2px solid green",
                  borderRadius: "8px",
                }}
              >
                {this.props.translate("topServices")}
              </Button>
              <Button
                onClick={() => {
                  this.handleChangeServiebyType("topParts");
                }}
                style={{
                  width: "113px",
                  height: "36px",
                  marginLeft: "5px",
                  color: servicesByType === "topParts" ? "white" : "black",
                  backgroundColor:
                    servicesByType === "topParts" ? "black" : "white",
                  border:
                    servicesByType === "topParts" ? "black" : "2px solid green",
                  borderRadius: "8px",
                }}
              >
                {" "}
                {this.props.translate("topParts")}
              </Button>
              <Button
                onClick={() => {
                  this.handleChangeServiebyType("topPackages");
                }}
                style={{
                  width: "113px",
                  height: "36px",
                  marginLeft: "5px",
                  color: servicesByType === "topPackages" ? "white" : "black",
                  backgroundColor:
                    servicesByType === "topPackages" ? "black" : "white",
                  border:
                    servicesByType === "topPackages"
                      ? "black"
                      : "2px solid green",
                  borderRadius: "8px",
                }}
              >
                {this.props.translate("topPackages")}
              </Button>
            </div>
            <div style={{ marginTop: "15px", minHeight: "565px" }}>
              {servicesByType === "topParts" ? (
                <>
                  {" "}
                  {this.state?.usedPart ? (
                    <Scrollbar style={{ height: "545px", padding: "0px 10px" }}>
                      <ul
                        className="md-bar-graphlist"
                        style={{ padding: "0px  10px" }}
                      >
                        {this.state.usedPart &&
                          this.state.usedPart.map((item, index) => {
                            return (
                              <>
                                {/* <Tooltip  
                                    key={item?.itemCode}
                                    placement="bottom-start"
                                    classes={{
                                        popper: "menu-popper",
                                        tooltip: "menu-popper-tooltip",
                                    }} 
                                    title={
                                      <div>
                                          <div>{`${this.props.translate("itemName")}: ${item?.itemName}`}</div>
                                      </div>
                                  }> */}

                                <li style={{ marginTop: "15px" }}>
                                  <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <p
                                      style={{
                                        maxWidth: "250px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: "12px",
                                        fontWeight: "400px",
                                      }}
                                    >
                                      {item?.itemName || ""}
                                    </p>
                                    <strong
                                      style={{
                                        marginRight: "10px",
                                        fontSize: "14px",
                                        fontWeight: "600px",
                                      }}
                                    >
                                      {/* {parseInt(item?.usedCount || 0)} */}
                                      {(() => {
                                        try {
                                          const realValue =
                                            item?.usedCount || 0;
                                          const integerAmount =
                                            Math.floor(realValue);
                                          const formattedAmount =
                                            integerAmount.toLocaleString();
                                          // const amountWithCurrency = `${formattedAmount} {this.props.translate("sar")}`;
                                          return formattedAmount;
                                        } catch (error) {
                                          console.error("Error:", error);
                                          return "Error";
                                        }
                                      })()}
                                    </strong>
                                  </span>

                                  <LinearProgress
                                    style={{ height: 15, borderRadius: "10px" }}
                                    className={colors[index % colors.length]}
                                    variant="determinate"
                                    value={item.usedCountPercentage}
                                  />
                                </li>
                                {/* </Tooltip> */}
                              </>
                            );
                          })}
                      </ul>
                    </Scrollbar>
                  ) : (
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        height: "100%",
                      }}
                    >
                      <MaintenanceItemEmpty />
                    </div>
                  )}
                </>
              ) : servicesByType === "topService" ? (
                <>
                  {this.state.usedService ? (
                    <Scrollbar style={{ height: "545px", padding: "0px 10px" }}>
                      <ul
                        className="md-bar-graphlist"
                        style={{ padding: "0px  10px" }}
                      >
                        {this.state.usedService &&
                          this.state.usedService.map((item, index) => {
                            return (
                              <>
                                {/* <Tooltip  
                                    key={item?.itemCode}
                                    placement="bottom-start"   

                                    classes={{
                                          popper: "menu-popper",
                                          tooltip: "menu-popper-tooltip",
                                        }} 
                                        title={
                                          <div>
                                              <div>{`${this.props.translate("itemName")}: ${item?.itemName}`}</div>
                                          </div>
                                      }> */}
                                <li style={{ marginTop: "15px" }}>
                                  <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                    className="row-label"
                                  >
                                    <p
                                      style={{
                                        maxWidth: "250px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: "12px",
                                        fontWeight: "400px",
                                      }}
                                    >
                                      {item?.itemName || ""}
                                    </p>
                                    <strong
                                      style={{
                                        marginRight: "10px",
                                        fontSize: "14px",
                                        fontWeight: "600px",
                                      }}
                                    >
                                      {(() => {
                                        try {
                                          const realValue =
                                            item?.usedCount || 0;
                                          const integerAmount =
                                            Math.floor(realValue);
                                          const formattedAmount =
                                            integerAmount.toLocaleString();
                                          return formattedAmount;
                                        } catch (error) {
                                          console.error("Error:", error);
                                          return "Error";
                                        }
                                      })()}
                                    </strong>
                                  </span>
                                  <LinearProgress
                                    style={{ height: 15 }}
                                    className={colors[index % colors.length]}
                                    variant="determinate"
                                    value={item.usedCountPercentage}
                                  />
                                </li>
                                {/* </Tooltip> */}
                              </>
                            );
                          })}
                      </ul>
                    </Scrollbar>
                  ) : (
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        height: "100%",
                      }}
                    >
                      <MaintenanceItemEmpty />
                    </div>
                  )}
                </>
              ) : servicesByType === "topPackages" ? (
                <>
                  {" "}
                  {this.state.packageAccount ? (
                    <Scrollbar style={{ height: "545px", padding: "0px 10px" }}>
                      <ul className="md-bar-graphlist">
                        {this.state.packageAccount &&
                          this.state.packageAccount.map((item, index) => {
                            return (
                              <>
                                {/* <Tooltip  
                                    key={item?.itemCode}
                                    placement="bottom-start"   
                                    classes={{
                                          popper: "menu-popper",
                                          tooltip: "menu-popper-tooltip",
                                        }} 
                                        title={
                                          <div>
                                              <div>{`${this.props.translate("itemName")}: ${item?.itemName}`}</div>
                                          </div>
                                      }> */}

                                <li style={{ marginTop: "15px" }}>
                                  <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                    className="row-label"
                                  >
                                    <p
                                      style={{
                                        maxWidth: "250px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: "12px",
                                        fontWeight: "400px",
                                      }}
                                    >
                                      {item?.itemName || ""}
                                    </p>
                                    <strong
                                      style={{
                                        marginRight: "10px",
                                        fontSize: "14px",
                                        fontWeight: "600px",
                                      }}
                                    >
                                      {(() => {
                                        try {
                                          const realValue =
                                            item?.usedCount || 0;
                                          const integerAmount =
                                            Math.floor(realValue);
                                          const formattedAmount =
                                            integerAmount.toLocaleString();
                                          return formattedAmount;
                                        } catch (error) {
                                          console.error("Error:", error);
                                          return "Error";
                                        }
                                      })()}
                                    </strong>
                                  </span>
                                  <LinearProgress
                                    style={{ height: 15 }}
                                    className={colors[index % colors.length]}
                                    variant="determinate"
                                    value={item.usedCountPercentage}
                                  />
                                </li>
                                {/* </Tooltip> */}
                              </>
                            );
                          })}
                      </ul>
                    </Scrollbar>
                  ) : (
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        height: "100%",
                      }}
                    >
                      <MaintenanceItemEmpty />
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </Grid>

        {this.state.openModal === true ? (
          <Support
            {...this.props}
            {...this.state}
            openModal={this.state.openModal}
            handleCloseMessage={this.handleCloseMessage}
          />
        ) : null}
      </Grid>
    );
  }
}
const MaintenanceItemEmpty = React.memo(() => {
  return (
    <div
      style={{ marginTop: "10px" }}
      // className={"mainten-section mainten"}
    >
      <div
      //  className="mainten-section-body"
      >
        <div
        // className="mainten-split-section"
        >
          <div>
            <Skeleton className="skeleton-pulse" width={"100%"} height={30} />
          </div>
          <div>
            <Skeleton className="skeleton-pulse" width={"100%"} height={30} />
          </div>
          <div>
            <Skeleton className="skeleton-pulse" width={"100%"} height={30} />{" "}
          </div>
          <div>
            <Skeleton className="skeleton-pulse" width={"100%"} height={30} />{" "}
          </div>{" "}
          <div>
            <Skeleton className="skeleton-pulse" width={"100%"} height={30} />{" "}
          </div>
        </div>
        {/* <div>
          <span style={{ display: "flex", justifyContent: "space-around" }}>
            <Skeleton className="skeleton-pulse" width={150} height={35} />
            <Skeleton className="skeleton-pulse" width={150} height={35} />
          </span>
        </div>
        <div>
          <span style={{ display: "flex", justifyContent: "space-around" }}>
            <Skeleton className="skeleton-pulse" width={150} height={35} />
            <Skeleton className="skeleton-pulse" width={150} height={35} />
          </span>
        </div> */}
      </div>
    </div>
  );
});
const mapStateToProps = (state, props) => {
  return {
    currentId: props.match && props.match.params && props.match.params.id,
    logInUsers: state.logInUsers,
    themecolors: state.themeColors,
  };
};

export default connect(mapStateToProps)(MaintenanceDashboard);


const RiyalIcon = ({ color = "red", size = 24 }) => (
<svg 
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1080 1080"
    width={size}
    height={size}
    fill={color}
>
    <g>
        <path d="M108.2,722.6c94.1-19.7,184.8-38.7,277.8-58.2c0,49.4,0,95.1,0,142.2c-93.1,19.9-185.3,39.4-277.3,59.7
            c-9.3,2.1-22.5,7.9-25.1,15.2C71.5,915.3,62.5,950,51,989c16.8-2.3,26.3-2.9,35.5-4.9c68.2-14.5,136.3-29.7,204.6-43.8
            c135.4-27.9,112-16.9,185.8-126.7c12.2-18.2,17.7-42.9,20.3-65.2c3.4-29.1,0-59,1.5-88.4c0.4-7.7,6.7-20,12.6-21.6
            c31.1-8.3,62.9-13.9,97.6-21.1c0,76.4,0,149.9,0,226c116.4-24.6,229.7-48.5,342.9-72.9c6-1.3,14.5-5.7,16.1-10.6
            c11.6-35.6,21.8-71.7,34-113C902.9,667.6,813,686.5,720.4,706c0-31.1,1.3-58.5-0.4-85.8c-1.2-20.3,4.3-29.1,25.8-33.1
            c65.7-12.2,130.9-26.8,196.1-41.4c9.6-2.1,23.6-7.1,26.1-14.1c12.4-34.3,21.6-69.8,33.8-111.1c-98.8,20.7-188.6,39.5-282.9,59.2
            c0-136.9,0-269.7,0-411.7c-33.1,27.3-59.2,49.2-85.7,70.7c-19,15.4-25.1,34.4-24.8,59.3c1.5,93.4,0.9,186.9,0.1,280.3
            c-0.1,9.2-4.5,24.8-10,26.3c-31.8,8.9-64.6,14.6-100.8,22.1c0-170.6,0-337.2,0-513.3c-30.7,24.3-54.9,43.5-79.2,62.6
            c-23.3,18.2-31.9,40.9-31.5,71.4c1.7,125.9,0.4,251.8,1.1,377.7c0.1,19.1-5,27.5-25.1,30.9c-45.5,7.8-90.3,19-135.6,28.3
            C125.8,605.1,121.1,610.2,108.2,722.6z"/>
        <path d="M995.2,878c-3.9-0.6-5.9-1.5-7.6-1.1C877.3,900.1,767,923,656.9,947.1
            c-7.9,1.7-18.1,9.7-20.6,17c-11.1,32.8-19.8,66.5-30.6,104.3c14.5-2,22.1-2.5,29.4-4c81.5-17.1,163.1-34.1,244.5-51.6
            c99.7-21.5,102.4-24.6,116.5-127.4C996.4,883.6,995.7,881.6,995.2,878z"/>
    </g>
</svg>
)