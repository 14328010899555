import React, { Component } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import Loader from './Loader'
import Footer from './Footer'
import { connect } from 'react-redux'
import {
  fetchDevices,
  fetchDevicesList,
  fetchMoreDevices,
  searchDevices
} from '../Actions/Devices'
import { logInUserInfo, getRoles } from './../Actions/Users'
import { ServerInfo } from './../Actions/serverSetting'
import CommonSection from '../Helpers/commonSection'
import Style from 'style-it'
import './index.scss'
import Scrollbar from 'react-scrollbars-custom'
import FeatureRow from '../Components/Features'
import {
  setCookie,
  checkedDevelopmentType,
  defaultDevicesLength,
  checkPrivileges,

} from '../Helpers'
import { getLayer } from './../Actions/Maps'
import instance from '../axios'
import Support from './Support/index'
import withTranslationWrapper from '../HOC/withTranslation'
import Dialog from '../Components/common/Dialog'
const formatNumber = (num) => (num >= 1000 ? num.toLocaleString() : num);
class Layout extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      collapse_sidebar: '',
      collapse: false,
      isOpenPaymentDue:false,
      isOpenDubiousDelivery:false,
      paymentDueData:null,
    }

    this.collapseSidebarClass = this.collapseSidebarClass.bind(this)
    this.collapseSidebar = this.collapseSidebar.bind(this)
    this.collapseSidebarWithButton = this.collapseSidebarWithButton.bind(this)
  }

  collapseSidebarClass (value) {
    this.setState({ collapse_sidebar: value || '' })
  }

  collapseSidebar () {
    const w = window.innerWidth
    if (w <= 959) {
      const v = this.state.collapse
      this.setState({ collapse: !v }, () => {
        if (!v) {
          this.collapseSidebarClass('sidebar-collapse')
        } else {
          this.collapseSidebarClass('')
        }
      })
    }
  }

  collapseSidebarWithButton () {
    const v = this.state.collapse
    this.setState({ collapse: !v }, () => {
      if (!v) {
        this.collapseSidebarClass('sidebar-collapse')
      } else {
        this.collapseSidebarClass('')
      }
    })
  }

  componentWillUnmount () {
    this.props.dispatch(fetchMoreDevices(true))
    this.props.dispatch(searchDevices({ device: '' }))
  }
  getDueDeliveryNotesCounts = () => {
    let today = new Date();
    let fourDaysAgo = new Date();
  
    fourDaysAgo.setDate(today.getDate() - 7);
    fourDaysAgo.setHours(0, 0, 0, 0);
    today.setHours(23, 59, 59, 999);
  
    let fromDate = `from=${fourDaysAgo.toISOString()}`;
    let toDate = `to=${today.toISOString()}`;
  
    instance({
      url: `/api/deliverynotes/getdnsmorethanone?${fromDate}&${toDate}`,
      method: "GET",
    })
      .then((response) => {
        const flattenedArray = Object.values(response)?.flat();
        const updatedUserInfo = {
          ...this.props.logInUser,
          dueDeliveryNoteCount:flattenedArray?.length,
        };
        this.props.dispatch(logInUserInfo(updatedUserInfo));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  componentWillMount () {

    this.props.dispatch(fetchMoreDevices(true, defaultDevicesLength))
    if(this.props.logInUser){
      this.handleOpenPaymentDue(this.props.logInUser?.accountId)
    }
    if (!this.props.logInUser.id) {
      const fetchUrl = checkedDevelopmentType()
      // fetch(`/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05${fetchUrl}`,{
      //     headers: { 
      //       Accept: 'application/json; odata=verbose'
      //     }
      //   })
      instance({
        url: `/api/session`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params:{
          app: `3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05`,
          // host: fetchUrl
        }
      })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
          .then(userInfoFront => {
            if(userInfoFront.temporary){
              this.handleLogout()
              return;
            }
            const userPrivileges = false /* JSON.parse(
              localStorage.getItem('frontPrivileges')
            ) */
            // const lang = userInfoFront?.attributes?.lang  || 'en'
            // this.props.setActiveLanguage(lang)

            if (!userPrivileges) {
              // fetch(`/api/privileges?roleId=${userInfoFront.roleId}`, {
              //   method: 'Get'
              // })
              instance({
                url: `/api/privileges`,
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                params:{
                  roleId: userInfoFront.roleId
                }
              })
              // .then(response => {
              //   if (response.ok) {
              //     response.json()
                  .then(privileges => {
                    const privilegeKeys = []

                    privileges.map(item => {
                      privilegeKeys.push(item.key)
                      return false
                    })
                    localStorage.setItem(
                      'frontPrivileges',
                      JSON.stringify({ ...privilegeKeys })
                    )
                 

                    this.setState({ loading: false })
                    this.getDueDeliveryNotesCounts()
                    this.props.dispatch(logInUserInfo(userInfoFront))
                    this.props.dispatch(fetchDevicesList(userInfoFront))
                    this.props.dispatch(fetchDevices(userInfoFront))

                    if (userInfoFront.userType === -1) {
                      // fetch('/api/roles?all=true')
                      instance({
                        url: `/api/roles`,
                        method: 'GET',
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json'
                        },
                        params:{
                          all: true
                        }
                      })
                      // .then(response => {
                      //   if (response.ok) {
                      //     response.json()
                          .then(role => {
                            this.props.dispatch(getRoles(role))
                        //   })
                        // }
                        // else{
                        //   throw response
                        // }
                      }).catch(error => {
                        // errorHandler(error, this.props.dispatch)
                      })
                    } else if (
                      userInfoFront.userType !== 1 &&
                      checkPrivileges('role')
                    ) {
                      // fetch(`/api/roles?userId=${userInfoFront.id}`)
                      instance({
                        url: `/api/roles`,
                        method: 'GET',
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json'
                        },
                        params:{
                          userId: userInfoFront.id
                        }
                      })
                      // .then(
                      //   response => {
                      //     if (response.ok) {
                      //       response.json()
                            .then(role => {
                              this.props.dispatch(getRoles(role))
                          //   })
                          // }
                          // else{
                          //   throw response
                          // }
                        }).catch(error => {
                          // errorHandler(error, this.props.dispatch)
                        })
                    }

                //   })
                // }
                // else{
                //   throw response
                // }
              }).catch(error => {
                // errorHandler(error, this.props.dispatch)
              })
            } 
            if (userInfoFront) {
              // fetch(`/api/accounts?accountId=${userInfoFront.accountId}`)
              instance({
                url: `/api/accounts`,
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                params:{
                  accountId: userInfoFront.accountId
                }
              })
              // .then(
              //   response => {
              //     if (response.ok) {
              //       response.json()
                    .then(server => {
                      const domElements = document.getElementById('favicon')
                      if (
                        server &&
                        server[0].attributes &&
                        server[0].attributes.favIcon &&
                        domElements
                      ) {
                        domElements.setAttribute(
                          'href',
                          server[0].attributes.favIcon
                        )
                      }
                      this.props.dispatch(ServerInfo(server[0]))
                      this.props.dispatch(
                        getLayer({
                          id: userInfoFront.map || server[0].map || 'osm'
                        })
                      )
                  //   })
                  // }
                  // else{
                  //   throw response
                  // }
                }).catch(error => {
                  this.handleLogout()
                })
            }
        //   })
        // } else {
        //   setCookie('JSESSIONID', 0)

        //   this.props.history.push(
        //     '/logout',
        //     this.props.history.location.pathname
        //   )
        //     throw response
        //   }
        }).catch(error => {
          this.handleLogout()
        })
    } else {
      this.setState({ loading: false })
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.logInUser?.dueDeliveryNoteCount !== this.props.logInUser?.dueDeliveryNoteCount) {
      const newCount =this.props.logInUser?.dueDeliveryNoteCount|| 0;
      const isDubiousDeliveryShown = localStorage.getItem('isDubiousDeliveryShow');
      if(isDubiousDeliveryShown&&this.props.logInUser&&newCount > 0&&checkPrivileges("deliverynoteMoreThanOneVehicleView")){
  this.handleOpenDubiousDeliveryCount()
      }
    }
  }
handleOpenPaymentDue=(accountId)=>{
  const isPaymentDueShown = localStorage.getItem('isPaymentDueShown');
  if(isPaymentDueShown&&accountId){

  return instance({
    url: `/api/payments/getpaymentdue`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {
      accountid:accountId,
    },
  })
    .then(response => {
      if(response?.pendingPayment===0){
        localStorage.removeItem("isPaymentDueShown")
      }else{
        if (isPaymentDueShown) {
          this.setState({paymentDueData:response, isOpenPaymentDue: true });
        localStorage.removeItem('isPaymentDueShown');
        }
      }
      
    })
    .catch(error => {
      console.error('Failed to fetch payment due:', error);
    });
  }

}

handleOpenDubiousDeliveryCount=()=>{
  this.setState({
    isOpenDubiousDelivery:true
  },()=>{
    localStorage.removeItem("isDubiousDeliveryShow")
  })
}
duePaymentModalClose=()=>{
    this.setState({isOpenPaymentDue: false})
}

dubiousDeliveryModalClose=()=>{
  this.setState({isOpenDubiousDelivery: false})
  
}

 handleLogout = () => {
    setCookie('JSESSIONID', 0);
    this.props.history.push(
      '/logout',
      this.props.history.location.pathname
    )
  
  };

  render () {
    if (this.props.location.pathname.indexOf('/binders') !== -1) {
      document.body.classList.add('binders-page')
    }
    else {
      document.body.classList.remove('binders-page')
    }
    if (this.props?.notificationSystem?.length > 2) {
      document.body.classList.add('shown-clearall-btn')
    } else {
      document.body.classList.remove('shown-clearall-btn')
    }
    if (this.props.ServerSetting && this.props.ServerSetting.attributes && this.props.ServerSetting.attributes.fontFamily) {
      document.body.classList.remove("ff-1", "ff-2", "ff-3", "ff-4", "ff-5")
      document.body.classList.add(this.props.ServerSetting.attributes.fontFamily)
    } else {
    }

    if (!this.state.loading && this.props.ServerSetting) {
      return (<>
        <Style>
          {`
          .main-view {
            color: ${this.props.themecolors.textColor};
            background: ${this.props.themecolors.backgroundColor};
          }
          .custom-table tbody th,
          .custom-table tbody td {
            border-color: ${this.props.themecolors.themeLightColor};
            color: #333;
          }
          .list-mini-table th, .list-mini-table td {
            border-left: 1px solid ${this.props.themecolors.themeLightColor}77;
            border-bottom: 1px solid ${this.props.themecolors.themeLightColor}77;
            font-size: 12px;
            padding: 1px 6px;
            line-height: 28px
          }
          .list-mini-table th {
            background-color: ${this.props.themecolors.menuActiveBackground};
          }

          .trip-date-row {
            background-color: ${this.props.themecolors.themeDarkColor};
            color: ${this.props.themecolors.themeInverse};
          }

          .fms-sidebar .group-view .list-view-group .list-row {
            border-color: ${this.props.themecolors.menuActiveBackground};
          }
          .react-base-table tbody :not(.row-body) td:nth-child(Even),
          .custom-table-1 tbody td:nth-child(Even),
          .custom-table tbody td:nth-child(Even) {
            background-color: ${this.props.themecolors.themeListingColor}1A;
          }
          .MuiTableRow-root:not(:last-child,.row-body,.MuiTableRow-head) > .MuiTableCell-root:not(.expandable-cell) {
            border-bottom: 2px dashed ${this.props.themecolors.themeLightColor}7A !important;
          }
          .btn-link {
            background-color: ${this.props.themecolors.themeDarkColor};
            color:  ${this.props.themecolors.themeInverse};
          }
          .btn-link.active {
            background-color: ${this.props.themecolors.themeLightColor};
            color:  ${this.props.themecolors.themeLightInverse};
          }
          .custom-table2 tbody th,
          .custom-table2 tbody td {
            border-color: ${this.props.themecolors.themeLightColor};
            color: #333;
          }
          .fms-sidebar .list-view-children .list-view-group > li > .list-row:first-child {
            background: none;           
          }
          .fms-sidebar .list-view-children .list-view-group > li > .list-row:first-child .unit-name {
            font-weight: 700;
          }
          .fms-sidebar .list-view-children .list-view-group > li > .list-row:first-child:before {
            background: ${this.props.themecolors.textColor};
          }
          .binders-page {
            background: ${this.props.themecolors.themeDarkColor};
          }
          .fms-sidebar .list-view {
            color:  ${this.props.themecolors.textColor}
          }
          .feature-row-1 {
            background:  ${this.props.themecolors.themeDarkColor}
          }
          .trip-time, .trip-distance {
            border-right: 1px solid ${this.props.themecolors.themeLightColor}80 
          }
          .tracks-list-row {
            border: 1px solid ${this.props.themecolors.themeLightColor}80   
          }
          .list-view a.list-row {
            color: ${this.props.themecolors.themeListingInverse};
          }
          .selector-list li > label,
          .droppable-element,
          .list-row,.list-row-1 {
            background: ${this.props.themecolors.themeListingColor}2A;
            color: ${this.props.themecolors.themeListingInverse};
          }
          .selector-list li > label.active,
          .selector-list li > label:hover,
          a.list-row.active,
          .list-row.active {
            background: ${this.props.themecolors.menuActiveBackground}2A;
            color: ${this.props.themecolors.themeListingInverse};
          }
          .step-dark .selector-list li > label,
          .step-dark .selector-list li > label.active,
          .step-dark .selector-list li > label:hover,
          .step-dark  a.list-row.active,
          .step-dark .list-row.active {
            background: ${this.props.themecolors.themeLightColor}2A;
            color: ${this.props.themecolors.themeLightInverse};
          }
          .group-list-row:hover,
          .group-list-row {
            background: ${this.props.themecolors.themeLightColor};
            color: ${this.props.themecolors.themeLightInverse};
          }
          .category-item {
            background: ${this.props.themecolors.themeLightColor};
            color: ${this.props.themecolors.themeLightInverse};
          }
          .main-thumb-view .list-view.list-view-children .checkbox .v-checkbox {
            background: ${this.props.themecolors.themeDarkColor};
          }
          .main-thumb-view .group-list-row:hover,
          .main-thumb-view .group-list-row {
            background: none;
            color: ${this.props.themecolors.textColor};
          }
         .list-view a.list-row:hover,
         .list-row:hover,.list-row-1:hover {
          background: ${this.props.themecolors.menuActiveBackground}1A;
          color: ${this.props.themecolors.themeListingInverse};
         }
         .main-thumb-view .fms-sidebar .list-row-condest .list-view-group > li > .list-row:first-child:before {
           background: ${this.props.themecolors.textColor}
         }
         .collapseable {
            background-color: ${this.props.themecolors.themeListingColor}2A;
          }
         .feature-section .item-row:hover {
          color: ${this.props.themecolors.textColor}aa;
         }
         a {
           color: ${this.props.themecolors.textColor};
           cursor: pointer
          }
          .all-attribute-item {
            background: ${this.props.themecolors.themeLightColor}1A;
          }
          .widget-actions,
          .vis-dot.custom-timemachine-item,
          .vis-item.vis-background,
          .vis-item.vis-range {
            background: ${this.props.themecolors.backgroundColor};
            color: ${this.props.themecolors.textColor};
            border-color: ${this.props.themecolors.themeDarkColor};
          }
          .speed-text {
            color: ${this.props.themecolors.themeDarkColor};
          }
        .main-unit-modal-bg {
          background: ${this.props.themecolors.backgroundColor};
          color: ${this.props.themecolors.textColor};
        }
        .page-title-notification,
        .page-title-2,
        .page-title,
        .v-tab-heading {
          background-color: ${this.props.themecolors.themeDarkColor};
          color: ${this.props.themecolors.themeInverse};
          padding: 9px 20px 7px;
        }

        .custom-tab-button {
          min-height: 29px;
          border-radius: 6px 6px 0 0;
          margin: 0 2px;
          background: ${this.props.themecolors.themeLightColor};
          color: ${this.props.themecolors.themeLightInverse};
        }
        .custom-tab-button.custom-tab-button-selected,
        .custom-tab-button-selected {
          background: ${this.props.themecolors.themeDarkColor};
          color: ${this.props.themecolors.themeInverse};
        }
        
        .custom-tab-button-1 {
          background: ${this.props.themecolors.themeLightColor}88;
          border-radius: 0
        }
        
        .row-body {
          background: ${this.props.themecolors.themeDarkColor};
          color: ${this.props.themecolors.themeInverse};
          border-bottom: 2px solid;
        }
        
        .theme-label {
          font-size: 12px;
          color: ${this.props.themecolors.textColor}
        }

        .page-title-inverse {
          border-left: 5px solid ${this.props.themecolors.themeLightColor};
          background: ${this.props.themecolors.menuActiveBackground};
          color: ${this.props.themecolors.menuActiveColor};
          line-height: 37px;
          padding-left: 11px
        }

        .theme-label-shrink {
          margin-top: 0
        }
        .theme-label-focused {
          margin-top: 0;
        }
        .theme-label-error {
          color: ${this.props.themecolors.error}
        }
        .MuiOutlinedInput-notchedOutline,
        .theme-notchedOutline {
          border-color:  ${this.props.themecolors.themeListingColor}4D !important;
          background-color:  ${this.props.themecolors.themeListingColor}1A;
        }
        .theme-input-error .theme-notchedOutline {
          border-color:  ${this.props.themecolors.error} !important;
        }
        .theme-cssFocused .theme-notchedOutline {
          border-color:  ${this.props.themecolors.themeListingColor} !important;
        }
        .theme-textarea {
          font-size: 14px;
          padding: 0
        }
        .theme-input-select {
          border-color: ${this.props.themecolors.themeListingColor};
          color: ${this.props.themecolors.textColor};
          padding: 0;
          display: flex;
          height: auto
        }

        .theme-input-select.Mui-disabled {
          background-color: ${this.props.themecolors.themeListingColor}2A;
        }
        .theme-input {
          border-color: ${this.props.themecolors.themeListingColor};
          color: ${this.props.themecolors.textColor};
          font-size: 12px;
          padding: 5px 14px;
          min-height: 26px;
          line-height: 26px;
        }
        .MuiFormControl-marginDense {
          margin-top: 8px;
          margin-bottom: 4px;
        }
        .theme-cssOutlinedInput {
          color: inherit
        }
        .react-base-table thead td,
        .custom-table-1 .sensor_type_head + tr td, .custom-table-1 .sensor_type_head tr + th
        .custom-table-1 .sensor_type_head td, .custom-table-1 .sensor_type_head th {
          border-top: 0;
        }
        .custom-table-1 td, .custom-table-1 th {
          border-bottom: 2px dashed ${this.props.themecolors.themeListingColor}7A;
          border-top: 2px dashed ${this.props.themecolors.themeListingColor}7A;
          border-right: 0
        }
        .react-base-table tr:last-child td, .react-base-table tr:last-child th,
        .custom-table-1 tr:last-child td, .custom-table-1 tr:last-child th {
          border-bottom-width: 0;
        }
        .custom-table thead th,
        .custom-table-1 .sensor_type_head th {
          background: ${this.props.themecolors.themeListingColor}2A;
          color: ${this.props.themecolors.textColor};
          border: 0
        }
        .MuiSelect-iconOutlined,
        .menu-list-icon {
          color: ${this.props.themecolors.textColor};
        }
        .main-menu-list-wrapper {
          background-color: ${this.props.themecolors.themeLightColor};
        }
        .mainten-section-footer,
        .mainten-highlight-section > div:first-child,
        .mainten-split-section > div:first-child,
        .mainten-section-header,
        .reminder-section-header,
        .reminder-section {
          border-color: ${this.props.themecolors.themeLightColor}7A;
        }
        .reminder-cost {
          color: ${this.props.themecolors.themeLightColor};
          font-weight: bold;
          font-size: 16px
        }
        .action-heading {
          color: ${this.props.themecolors.themeInverse};
        }
        .custom-mileage-list,
        .mainten-section {
          background-color: ${this.props.themecolors.menuActiveBackground}1A;
        }
        .custom-mileage-list span ~ span {
          border-color: ${this.props.themecolors.themeDarkColor}dd;
        }
        .v-filter-field2 {
          border-bottom: 2px dashed ${this.props.themecolors.themeLightColor}7A;
        }

        .mainten-highlight-section {
          background-color: ${this.props.themecolors.themeDarkColor};
          color: ${this.props.themecolors.themeInverse};
        }
        .map-filters-wrapper:not(.no-bg) { color: ${this.props.themecolors.themeInverse}; background-color: ${this.props.themecolors.themeDarkColor}}
        `}</Style>
          <div
            className={
              this.state.collapse_sidebar +
              ' ' +
              this.props.classFromChildren +
              ' ' +
              ' main-view ' +
              (localStorage.getItem('token') &&
              localStorage.getItem('backtoAdmin')
                ? 'shown-bta-bar'
                : '')
            }
          >
            <Style>
              {`
              .boxhandle-icon,
              .msg_head {
                background: ${this.props.themecolors.themeDarkColor};
                color: ${this.props.themecolors.themeInverse};
              }
              .custom-theme-label {
                font-size: 12px;
                display: block;
                margin-bottom: 5px;
              }
              .custom-theme-input:focus {
                outline: none
              }
              .custom-theme-input {
                color: ${this.props.themecolors.textColor};
                background: ${this.props.themecolors.backgroundColor};
                border-color: ${this.props.themecolors.themeLightColor};
                border-radius: 4px;
                font-size: 12px;
                padding: 5px 14px;
                font-family: inherit;
              }
        .material-icons {vertical-align: middle; color: inherit}
        .fms-sidebar .filter-row .filter-nav-link {
          color: ${this.props.themecolors.themeLightInverse};
          background: ${this.props.themecolors.themeLightColor};
        }
        .MuiPickersDay-day.MuiPickersDay-daySelected,
        .fms-sidebar .filter-row .filter-nav-link.active{
          color: ${this.props.themecolors.textColor};
          background: ${this.props.themecolors.backgroundColor};
        }

        .sidebar-bg-layer {
          color: ${this.props.themecolors.textColor};
          background: ${this.props.themecolors.backgroundColor};
        }

        .fms-sidebar .fms-sidebar-monitoring .sidebar-bg-layer,
        .fms-sidebar.sidebar-bg-layer {
          border-right: 2px dashed ${this.props.themecolors.themeListingColor}7A;
        }
        .motion-status-bar {
          border-bottom: 2px dashed ${this.props.themecolors.themeListingColor}7A;
        }
        .motion-status-bar-alt:nth-child(Odd) {
          background-color:  ${this.props.themecolors.menuActiveBackground}1A;
        }
        .motion-status-bar-alt {
          border-bottom: 0;
          padding-inline: 15px;
        }
        .motion-status-bar-alt .motion-status-border {
          width: 12px;
          height: 12px;
        }
        .motion-status-bar-alt .motion-status-name,
        .motion-status-bar-alt .motion-status-count {
          font-weight: normal;
          font-style: normal;
          font-size: 16px;
        }


        .sensors-buttons .theme-button.active {
          border-top-color:  ${this.props.themecolors.menuActiveBackground};
        }

        .action-heading {
          border-top-color: ${this.props.themecolors.menuActiveBackground}
        }

        .feature-row {
          color: ${this.props.themecolors.textColor};
          background: ${this.props.themecolors.backgroundColor};
        }
        .feature-section ul {
          color: ${this.props.themecolors.menuTextColor};
          background: ${this.props.themecolors.menuActiveBackground};
        }
        .leaflet-draw-actions a:hover {
          background: ${this.props.themecolors.backgroundColor};
        }
        .leaflet-draw-actions a {
          border-left: 1px solid rgba(0,0,0,0.1);
          color: ${this.props.themecolors.textColor};
          background: ${this.props.themecolors.themeLightColor};
        }
        .dnd-ebuilder .tag,
        .meter-section .device-input {
          color: ${this.props.themecolors.themeInverse};
          background: ${this.props.themecolors.themeDarkColor}66;
        }
        .meter-section .device-input-alt {
          background: ${this.props.themecolors.themeInverse};
          color: ${this.props.themecolors.themeDarkColor};
        }
      .custom-tabs-root .custom-tabs-scrollable {
        margin-bottom: 0 !important;
      }
      .custom-tabs-root {
        min-height: inherit;
      }
      .custom-tabs {
        gap: 5px
      }

      .custom-indicator {
        background: transparent;
        height: 1px
      }
    .MuiListSubheader-root {
      color: ${this.props.themecolors.textColor}88;
      background: ${this.props.themecolors.backgroundColor}
    }
    .notification-head-row {
      background: ${this.props.themecolors.themeLightColor}88;
    }
    .unread .read-unread-mark {
      background: ${this.props.themecolors.textColor};
    }
    .unit-image {
      background: ${this.props.themecolors.themeLightColor}40;
    }
    .motion-status-bar-inline {
      background: ${this.props.themecolors.menuActiveBackground}1A;
    }
    .unit-image-active {
      background: ${this.props.themecolors.menuActiveBackground}1A !important;
    }
    .theme-input-checkbox {
      padding: 2px;
    }

    .menu-poper {
      opacity: 1;
      z-index: 9;
    }
    .menu-popper-tooltip {
      background: ${this.props.themecolors.textColor};
      color: ${this.props.themecolors.backgroundColor};
      font-size: 12px;
    }
    .MuiTooltip-tooltipPlacementTop {
      margin-top: 6px;
      margin-bottom: 6px;
    }
    .page-title-notification:before,
    .page-title:before,
    .with-border:before {
      background: ${this.props.themecolors.menuActiveBackground};
    }

    .step-connector-active {
      background: ${this.props.themecolors.themeDarkColor};
      color: ${this.props.themecolors.themeInverse};
    }
    .stepper-wrapper .step-dark {
      background: ${this.props.themecolors.themeDarkColor};
      color: ${this.props.themecolors.themeInverse};
    }
    .stepper-wrapper .step-dark .theme-label {
      color: ${this.props.themecolors.themeInverse};
    }
    .stepper-wrapper .step-dark .theme-notchedOutline {
      color: ${this.props.themecolors.themeInverse};
      border-color: ${this.props.themecolors.themeInverse}4D !important;
      background-color: ${this.props.themecolors.themeInverse}1A !important;
    }
    .stepper-wrapper .step-dark .theme-input {
      color: ${this.props.themecolors.themeInverse};
      border-color: ${this.props.themecolors.themeInverse}4D;
    }

    .stepper .step-light {
      color: ${this.props.themecolors.themeDarkColor} !important;
      background-color: ${this.props.themecolors.themeLightColor}4D !important;
    }
    .stepper .step-light .theme-label,
    .stepper .step-light .theme-input {
      color: ${this.props.themecolors.themeDarkColor} !important;
    }
    .notification-model-common-section {
      border-left: 2px dashed ${this.props.themecolors.themeListingColor}4D;
    }
    .extra-menu .active,
    .custom-tab-button-1.custom-tab-button-selected,
    .tab-selected.common-tab {
      background: ${this.props.themecolors.menuActiveBackground};
      color: ${this.props.themecolors.menuTextColor};
    }

    .tab-container-outer {
      color: ${this.props.themecolors.textColor};
      background: ${this.props.themecolors.backgroundColor};
      border-radius: 6px
    }
    .notification-head {
      border-bottom: 1px solid ${this.props.themecolors.themeLightColor}
    }
    .notification-head,
    .notification-bar .infinite-scroll-component {
      background: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor}
    }
    .track-wrapper .map-filters-bar:not(:empty), .with-timeline .map-filters-bar:not(:empty) {
      background: ${this.props.themecolors.backgroundColor};
    }
    
    .react-base-table .MuiTableHead-root {
      box-shadow: none;
      background: ${this.props.themecolors.menuActiveBackground}1A;
    }
    .react-base-table .react-base-table-wrapper {
      border-left: 0
    }

    .ReactTable .rt-noData {
      
      font-size: 1.5em;
      background: transparent;
      font-weight: bold
    }
    .ReactTable .rt-expander:after {
      display: none;
    }
    .ReactTable .rt-expander.-open {
      transform: rotate(90deg);
    }
    .ReactTable .rt-expander svg {
      font-size: inherit;
    }
    .ReactTable .rt-expander {
      color: inherit;
      font-size: 16px;
      transform: rotate(0deg);
      transition: all .3s cubic-bezier(.175,.885,.32,1.275)
    }
    .sidebar,
    .form-title-section,
    .overlayform:before,
    .overlayform {
      background-color: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor};
    }

    .ReactTable-2 .rt-td-1 {
      border-right: 1px solid ${this.props.themecolors.themeLightColor}66;
      border-bottom: 1px solid ${this.props.themecolors.textColor}99
    }
    .c-card-alter {
      color: ${this.props.themecolors.textColor};
      border: 1px solid ${this.props.themecolors.themeLightColor}66;
    }
    .ReactTable-2:first-child .rt-td-1:first-child {
      border-left: 0;
    }
    .ReactTable .-pagination .-btn {
      color: inherit;
    }
    .leaflet-container a.leaflet-popup-close-button {
      color: ${this.props.themecolors.textColor}
    }
    .position-box strong {
      background: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor}
    }
    .callout {
      background: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor} 
    }
    .form-contol-label {
       color: ${this.props.themecolors.textColor}!important
    }
    .report-sidebar-switch:after,
    .callout-body-inner .items .callout-list-item:hover {
      background: ${this.props.themecolors.themeLightColor}
    }
    .ReactTable .rt-th {
      white-space: normal;
      text-align: left;
      word-break: break-word;
    }
    .notification-wizard li .active,.notification-wizard li:hover > span{
      background: ${this.props.themecolors.themeLightColor};
    }
    .form-control,.rdt .form-control {
      background: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor};
      width: 100%;
      height: 28px;
      border: 1px solid ${this.props.themecolors.themeLightColor};
      border-radius: 4px;
    }
    .form-control:focus,.rdt .form-control:focus {
      border: 1px solid ${this.props.themecolors.themeLightColor};
      border-radius: 4px;
      outline: 0;
    }
    .stepper-step.stepper-step-active, .stepper-step.stepper-step-completed {
      color: ${this.props.themecolors.themeLightColor};
    }

    .rdtPicker {
      display: none;
      position: absolute;
      width: 250px;
      padding: 4px;
      margin-top: 1px;
      z-index: 99999 !important;
      background: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor};
      border: 1px solid ${this.props.themecolors.themeLightColor};
    }
    .rdtPicker td.rdtDay:hover,
    .rdtPicker td.rdtHour:hover,
    .rdtPicker td.rdtMinute:hover,
    .rdtPicker td.rdtSecond:hover,
    .rdtPicker .rdtTimeToggle:hover {
      background:${this.props.themecolors.themeLightColor};
      cursor: pointer;
    }
    .rdtPicker td.rdtOld,
    .rdtPicker td.rdtNew {
      color: ${this.props.themecolors.themeLightColor};
    }
    .rdtPicker td.rdtActive.rdtToday:before {
      border-bottom-color: ${this.props.themecolors.themeLightColor};
    }
    .rdtPicker td.rdtToday:before {
      content: '';
      display: inline-block;
      border-left: 7px solid transparent;
      border-bottom: 7px solid ${this.props.themecolors.themeDarkColor};
      border-top-color:${this.props.themecolors.themeLightColor};
      position: absolute;
      bottom: 4px;
      right: 4px;
    }
    .rdtPicker td.rdtActive,
    .rdtPicker td.rdtActive:hover {
      background-color: #428bca;
      color: #fff;
      text-shadow: 0 -1px 0 ${this.props.themecolors.themeLightColor};
    }

    .rdtPicker td.rdtDisabled,
    .rdtPicker td.rdtDisabled:hover {
      background: none;
      color:${this.props.themecolors.themeDarkColor};
      cursor: not-allowed;
    }

    .rdtPicker td span.rdtOld {
      color: ${this.props.themecolors.themeDarkColor};
    }
    .rdtPicker td span.rdtDisabled,
    .rdtPicker td span.rdtDisabled:hover {
      background: none;
      color: ${this.props.themecolors.themeDarkColor};
      cursor: not-allowed;
    }
    .rdtPicker th.rdtDisabled,
    .rdtPicker th.rdtDisabled:hover {
      background: none;
      color:${this.props.themecolors.themeDarkColor};
      cursor: not-allowed;
    }
    .rdtPicker thead tr:first-child th {
      cursor: pointer;
    }
    .rdtPicker thead tr:first-child th:hover {
      background: ${this.props.themecolors.themeLightColor};
    }

    .rdtPicker tfoot {
      border-top: 1px solid ${this.props.themecolors.themeDarkColor};
    }
    .rdtPicker button:hover {
      background-color: ${this.props.themecolors.themeLightColor};
    }
    .rdtPicker th {
      border-bottom: 1px solid ${this.props.themecolors.themeDarkColor};
    }
    .btn-group.btn-group-toggle.offset-sm-2 {
      display: flex;
      margin-left: 24%;
    }
    label.btn.btn-primary {
      width: 5%;
      margin: 0 8px;
    }
    .form-group {
      margin-bottom: 10px;
    }
    .row{
      margin:10px 0;
    }
    .list-menu-item {
      background-color: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor};
      padding-top: 6px;
      padding-bottom: 6px;
      font-size: 12px;
    }
      .list-menu-item:hover {
        background-color: ${this.props.themecolors.themeLightColor};
        color: ${this.props.themecolors.themeLightInverse};
      }
      .Mui-selected.selected_item:hover,
      .Mui-selected.selected_item {
        background-color: ${this.props.themecolors.themeLightColor};
        color: ${this.props.themecolors.themeLightInverse};
      }
      .driver-state-wrap .driver-state {
        background-color: ${this.props.themecolors.themeLightColor};
        color: ${this.props.themecolors.themeLightInverse};
      }
      .stat-block {
        background-color: ${this.props.themecolors.menuActiveBackground};
        color: ${this.props.themecolors.menuTextColor};
      }
      .common-tab .menu_item_text {
        font-size: 0.8125rem
      }
      .custom-tab-button-1,
      .common-tab {
        letter-spacing: inherit;
        min-height: 42px;
        min-width: 70px;
        background-color: ${this.props.themecolors.themeDarkColor};
        color: ${this.props.themecolors.themeInverse};
        opacity: 1;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .main-content-page .MuiPaper-root {
        color: ${this.props.themecolors.textColor};
        background: ${this.props.themecolors.backgroundColor};
      }
      .theme-range-slider {
        color: ${this.props.themecolors.themeLightColor};
      }
      .rta__list {
        color: ${this.props.themecolors.textColor};
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
      }
      .rta__entity {
        background: ${this.props.themecolors.backgroundColor};
      }
      .rta__item:not(:last-child) {
        border-bottom-color: ${this.props.themecolors.themeLightColor};
      }
      .rta__entity--selected {
        color: ${this.props.themecolors.themeDarkColor};
        background: ${this.props.themecolors.textColor};
      }
      .react-time-picker__wrapper {
        border-color: ${this.props.themecolors.themeLightColor};
      }
      .react-time-picker__inputGroup__input {
        color: ${this.props.themecolors.textColor};
        background: ${this.props.themecolors.backgroundColor};
      }
      .leaflet-tooltip {
        opacity: 1 !important;
        background: ${this.props.themecolors.themeDarkColor}e6;
        color: ${this.props.themecolors.themeInverse};
      }
      .speed-row,
      .unit-tooltip-custom-header {
        border-color: ${this.props.themecolors.themeDarkColor};
      }
      .bordered-grid,
      .bordered-grid > .MuiGrid-item {
        border-color: ${this.props.themecolors.themeLightColor}85;
      }
      .header-open .left-nav {
        background-color: ${this.props.themecolors.themeLightColor};
      }
    `}
            </Style>
              <Header {...this.props} />
              {this.props.noSidebar ? null : (
              <Sidebar
                {...this.props}
                sidebarClose={this.collapseSidebar}
                collapseSidebarWithButton={this.collapseSidebarWithButton}
                collapse={this.state.collapse}
                openImportTable={this.props.openImportTable}
              />
            )}
            <div className='main-view'>
              <Scrollbar disableTracksWidthCompensation>
                <div
                  className={
                    this.props.classFromChildren
                      ? this.props.classFromChildren
                      : ''
                  }
                >
                  {this.props.children}
                </div>
              </Scrollbar>
            </div>
            {this.props.logInUser &&
              this.props.logInUser.id &&
              this.props.location &&
              this.props.location.pathname && this.props.location.pathname.indexOf('/monitoring') !== 0 &&
              this.props.location.pathname.indexOf('/dashboard') !== 0 && (
                <CommonSection
                  location={this.props.location}
                  showDeviceModal={!this.props.showDeviceModal}
                />
              )}

            {this.props.deviceId ? (
              <Feature
                themecolors={this.props.themecolors}
                currentPosition={this.props.currentPosition}
                currentDevice={this.props.currentDevice}
                translate={this.props.translate}
                logInUser={this.props.logInUser}
                copyToClipboard={this.props.copyToClipboard}
              />
            ) : null}
          </div>
          {this.state.isOpenPaymentDue ? (
                      <Dialog
                        open
                        isPaymentDue
                        // title={this.props.translate('sendCommand')}
                        title={`${this.props.translate('Alert : Payment Due')}`}
                        themeColors={this.props.themecolors}
                        disableFooter
                        noFullScreen={true}
                        onClose={this.duePaymentModalClose}
                        dialogHeight={250}
                        draggable
                        bodyPadding={0}
                      >
<div style={{ padding: "10px" }}>
  <h2 style={{ fontWeight: 400 }}>
    Dear <strong>{this.state.paymentDueData?.accountName}</strong>,<br/>
    We hope this message finds you well. Our records indicate that there is an outstanding balance of <strong>{formatNumber(this.state.paymentDueData?.pendingPayment)}</strong> on your account.
  </h2>
  <h2 style={{ fontWeight: 400 }}>
  Please ignore this message if you alrady settled your amount.
  </h2>
  <hr/>

  <h2 style={{ marginTop: "20px", textAlign: "right", fontWeight: 400 }}>
  ، <strong>{this.state.paymentDueData?.accountName}</strong> عزيزي <br />
    نأمل أن تجدك هذه الرسالة بخير. تشير سجلاتنا إلى وجود رصيد متبقي بقيمة 
    <strong>{formatNumber(this.state.paymentDueData?.pendingPayment)}</strong> في حسابك.
  </h2>
  <h2 style={{ fontWeight: 400, textAlign: "right" }}>
  يرجى تجاهل هذه الرسالة إذا قمت بتسوية المبلغ الخاص بك بالفعل
  </h2>
</div>




                      </Dialog>
                    ) : null}
                              {this.state.isOpenDubiousDelivery ? (
                      <Dialog
                        open
                        isDubiousDelivery
                        // title={this.props.translate('sendCommand')}
                        title={`${this.props.translate('Alert : Repeated Service')}`}
                        themeColors={this.props.themecolors}
                        disableFooter
                        noFullScreen={true}
                        onClose={this.dubiousDeliveryModalClose}
                        dialogHeight={150}
                        draggable
                        bodyPadding={0}
                      >
<div style={{ padding: "10px" }}>
  <h2 style={{ fontWeight: 400 }}>
    Dear Valued Client,<br />
    You have <strong>{this.props.logInUser?.dueDeliveryNoteCount}</strong> repeated services in the last seven days.
  </h2>

  <h2 style={{ marginTop: "20px", textAlign: "right", fontWeight: 400 }}>
    عزيزي العميل،<br />
    لديك <strong>{this.props.logInUser?.dueDeliveryNoteCount}</strong> خدمات مكررة خلال الأيام السبعة الماضية.
  </h2>
</div>
                      </Dialog>
                       ):null}
          {this.props.afterView}
            <Footer history={this.props.history} />
            <Support {...this.props} {...this.state} />
          </>
        
      )
    } else {
      return <Loader />
    }
  }
}

export function Feature (props) {
  return <FeatureRow
        themecolors={props.themecolors}
        currentPosition={props.currentPosition}
        currentDevice={props.currentDevice}
        translate={props.translate}
        logInUser={props.logInUser}
        copyToClipboard={props.copyToClipboard}
      />
}
const mapStateToProps = state => ({
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting,
  themecolors: state.themeColors,
  notificationSystem: state.notificationSystem
})

export default connect(mapStateToProps)(withTranslationWrapper(Layout))
