import React, { Component } from 'react'
import { connect } from 'react-redux'
;
import Style from 'style-it'
import Loader from '../../Loader'
import FilterIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import Tooltip from '../../../Components/common/Tooltip'
import MissedOpportunityFilter from '../../../Components/common/MissedOpportunityFilter'
class invoices extends Component {
  constructor (props) {
    super(props)
    this.state = { searchRecently: '' }
  }

  search (e) {
    this.props.searchItems(e.target.value)
  }

  render () {
    const { invoices } = this.props
    return (
      <Style>
        {`.material-icons {vertical-align: middle; color: inherit}`}
        <div className='fms-sidebar sidebar-bg-layer'>
       <h3
          style={{ display: 'flex', alignItems: 'center', margin: 10, marginLeft: 10, marginTop: 14, marginBottom: 0, fontSize: 21 }}
          className="alt-title sidebar-icon-gap"
        >
          {this.props.translate('missedOpportunity')}
          </h3>
        {this.props.isShowReminder?null:
            <MissedOpportunityFilter
              {...this.props}
              formSubmit={this.props.submitSideBarSearch}
              downloadInvoice={this.props.downloadInvoice}
              sidebar
              invoice
              loader={this.props.loader}
            />
        }

        
        </div>
      </Style>
    )
  }
}

const mapState = state => ({
  geoFence: state.geoFence,
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)
export default mapStateToProps(invoices)
