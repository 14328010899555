import React, { Component } from "react";
import { connect } from "react-redux";
// import Checkbox from "./Checkbox";
import Checkbox from '../common/Checkbox'

import Style from "style-it";
import SearchField from "../common/SearchField";
import { Trans as Translate} from 'react-i18next';
import Scrollbar from "react-scrollbars-custom";
import instance from "../../axios";
import { ExpandLessSharp } from "@mui/icons-material";
import Loader from "../../Layout/Loader";
import isEqual from "react-fast-compare";
import InfiniteScroll from "react-infinite-scroll-component";
import withTranslationWrapper from "../../HOC/withTranslation";

class VehicleSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: {},
      search: "",
      isChecked: false,
      vehicles: {},
      loader: true,
      allVehicles: [],
      page: 1,
      limit: 20,
      intialFetch: true,
    };
  }
  componentWillMount() {
    let list = {};
    if (this.props.value && this.props.value.length) {
      this.props.value.map((d) => (list[d] = true));
      this.setState({ list: {} });
    }
    this.getVehicles();
  }

  componentWillReceiveProps(nextprops, prevState) {
    let list = {};
    if (nextprops.value && nextprops.value.length) {
      nextprops.value.map((d) => (list[d] = true));
    }
    this.setState({ list });
    if (!isEqual(nextprops.loaderChecking, this.state.loader)) {
      this.setState({ loader: false });
    }
  }

  componentWillUnmount() {
    this.setState({
      list: {},
      search: "",
      isChecked: false,
      // loader: true,
      vehicles: {},
      allVehicles: [],
    });
  }

  sendToParent = async (item) => {
    let check = item ? true : false;
    if (this.props.onChange) {
      let data = await Object.keys(this.state.list);
      this.props.onChange(data, "vehicle", check);
      this.setState({ intialFetch: false });
    }
  };

  onChange = (e, v) => {
    let list = { ...this.state.list };
    if (e.target.checked) {
      list[v.id] = true;
    } else {
      delete list[v.id];
    }
    this.setState(
      {
        list,
        isChecked: this.props.vehicles?.length === Object.keys(list).length,
      },
      () => {
        this.sendToParent();
      }
    );
  };
  searchItem = (text) => {
    this.setState({ search: text.target.value }, () => {
      this.getVehicles();
    });
  };

  toggleItems = (event) => {
    if (event.target.checked) {
      let list = {};
      this.state.allVehicles &&
        this.state.allVehicles.map((d) => (list[d.id] = true));
      this.setState({ list, isChecked: true }, () => {
        this.sendToParent(true);
      });
    } else {
      this.setState({ list: {}, isChecked: false }, () => {
        this.sendToParent();
      });
    }
  };

  getVehicles = () => {
    let vehcilesList = [];
    let url;
    let userId;
    let list = {};
    if (this.props.logInUser && this.props.logInUser.id) {
      // if (this.props.userId) {
      //   if (this.props.userId.length > 0) {
      //     let filter = "";
      //     Object.entries(this.props.userId).map(([key, value]) => {
      //       if (value) {
      //         filter += "&userId=" + value;
      //         this.setState({});
      //       }
      //     });
      //     userId = filter;
      //     // multiple user
      //     url = `api/vehicles/list?&page=${this.state.page}&limit=${this.state.limit}${filter}`;
      //   } else {
      //     userId = this.props.userId;
      //     url = `api/vehicles/get?userId=${userId}&all=true&search=${this.state.search}`;
      //   }
      // } else {
        // userId = this.props.logInUser.id;
        userId = this.props.userId ? this.props.userId :  this.props.logInUser.id;
        url = `api/vehicles/get?userId=${userId}&all=true&limit=20&page=1&search=${this.state.search}`
        // url = `api/vehicles/get?userId=${userId}&all=true&search=${this.state.search}`;
      // }
      instance({
        method: "GET",
        url: url,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (res) => {
          if (res.length !== undefined) {
            vehcilesList = res;
          } else {
            vehcilesList = res.data;
          }
          vehcilesList.map((item) => {
            return {
              id: item.id,
              // key: item.id,
              // name: item.label || item.name,
              label: item.label || item.name,
              uniqueId: item.id,
              // valueType: "string",
            };
          });

          if (this.props.type === "reports") {
            let testforData = this.props.userId.length ? true : false;
            vehcilesList && vehcilesList.map((d) => (list[d.id] = true));
            this.setState(
              {
                allVehicles: vehcilesList,
                vehicles: res,
                list: list,
                isChecked: true,
                loader: false,
                singleAccount: testforData ? false : true,
              },
              () => {
                this.sendToParent(this.state.intialFetch);
              }
            );
          } else {
            this.setState({
              vehicles: res,
              loader: false,
            });
          }
        })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    }
  };

  fetchMoreVehicles = () => {
    let url,
      userId = "";
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        // if (this.props.userId) {
        //   if (this.props.userId.length > 0) {
        //     let filter = "";
        //     Object.entries(this.props.userId).map(([key, value]) => {
        //       if (value) {
        //         filter += "&userId=" + value;
        //       }
        //     });
        //     userId = filter;
        //     // multiple user
        //     url = `api/vehicles/list?&page=${this.state.page}&limit=${this.state.limit}${filter}`;
        //   } else {
        //     userId = this.props.userId;
        //     url = `api/vehicles/get?userId=${userId}&all=true&search=${this.state.search}`;
        //   }
        // } else {
          userId = this.props.logInUser.id;
          // url = `api/vehicles/get?userId=${userId}&all=true&search=${this.state.search}`;
          url = `api/vehicles/get?userId=${userId}&all=true&limit=20&page=1&search=${this.state.search}`
        // }
        instance({
          method: "GET",
          url: url,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (res) => {
            this.setState({
              vehicles: {
                ...res,
                data: this.state.vehicles.data.concat(res.data),
              },
            });
          })
          .catch((err) => {
            console.log("err====", err);
          });
      }
    );
  };

  render() {
    return (
      <>
        {/* {this.state.loader === false ? ( */}
          <Style>
            {`
          .selector-list li > label.active,
          .selector-list li > label:hover {
          
          }
          .selector-list li {
              margin-bottom: 6px
          }
          .selector-list li > label {
              cursor: pointer;
              display: block;
              padding: 2px 6px;
              border-radius: 4px;
              display: flex;
              align-items: center;
          }
          .selector-list {
              padding: 0;
              margin: 0;
              list-style: none
          }
          .selector-label {
              font-size: 12px;
              line-height: 1.5;
              margin-left: 6px;
          }
          .selector-image {
              width: 26px;
              margin-left: 6px;
          }
          .selector-image img {
              height: auto;
              width: 26px;
              height: 26px;
              display: block;
              margin: auto;
          }
          .selector-check {
              height: 26px;
              display: inline-flex;
              align-items: center;
              margin-left:10px;
          }
          .selector-count {
              padding: 5px 0 6px;
              font-size: 10px;
              margin-left:2px
              }`}

            <div>
              <div
                className="section-head clearfix section-head-filter"
                style={{ padding: 0, marginLeft: -6, marginRight: -2 }}
              >
                <ul className="filter-row pull-left">
                  <li>
                    <SearchField
                      label={this.props.translate("searchVehicles")}
                      type="search"
                      placeholder="exp: ID, Name"
                      value={this.state.search}
                      onChange={this.searchItem}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </li>
                  <li style={{ marginTop: 10 }}>
                <Checkbox
                  checked={this.state.isChecked}
                  onChange={this.toggleItems}
                  label={this.props.translate("selectAll")}
                />
              </li>
                </ul>
                {/* <Checkbox
                  checked={this.state.isChecked}
                  onChange={this.toggleItems}
                  label={this.props.translate("allVehicles")}
                /> */}
              </div>
              <div className="selector-count">
                {this.state.isChecked
                  ? this.state.vehicles.total
                  : Object.keys(this.state.list).length}
                {"  "}
                {this.props.translate("vehicleSelected")}
              </div>
              {!this.state.isChecked ? (
                <>
                  <Scrollbar
                    disableTracksWidthCompensation={true}
                    style={{ height: 32 * (this.props.rows || 10) }}
                    scrollerProps={{
                      renderer: (props) => {
                        const { elementRef, ...restProps } = props;
                        return (
                          <div
                            {...restProps}
                            ref={elementRef}
                            id="scrollableDeviceSelector"
                          />
                        );
                      },
                    }}
                  >
                    {this.state.vehicles.total ? (
                      <InfiniteScroll
                        dataLength={this.state.vehicles.data.length}
                        next={() => this.fetchMoreVehicles()}
                        hasMore={this.state.vehicles.hasNext}
                        loader={<h3>Loading...</h3>}
                        scrollableTarget="scrollableDeviceSelector"
                        endMessage={
                          <h3
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            ----End----
                          </h3>
                        }
                        scrollThreshold={0.8}
                        // onScroll={()=>this.fetchMoreVehicles()}
                      >
                        <ul className="selector-list">
                          {this.state.vehicles.data &&
                            this.state.vehicles.data.map((row) => (
                              <li key={row.id}>
                                <label
                                  className={
                                    this.state.list[row.id] ? "active" : null
                                  }
                                >
                                  <span className="selector-check">
                                    <Checkbox
                                      checked={this.state.list[row.id]}
                                      value={row}
                                      onChange={this.onChange}
                                    />
                                  </span>
                                  <span className="selector-label">
                                    {row.vehicleLicensePlate || row.label || row.name}{" "}
                                  </span>
                                </label>
                              </li>
                            ))}
                        </ul>
                      </InfiniteScroll>
                    ) : null}
                  </Scrollbar>
                </>
              ) : null}
            </div>
          </Style>
        {/* ) : (
          <Loader component="filter" />
        )} */}
      </>
    );
  }
}

const mapState = (state) => ({
  vehicles: state.vehicles,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
});
const mapStateToProps = connect(mapState);
export default mapStateToProps(withTranslationWrapper(VehicleSelector));



// import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import Checkbox from '../common/Checkbox'
// import Style from 'style-it'
// import SearchField from '../common/SearchField'
// ;
// import Scrollbar from 'react-scrollbars-custom'
// import instance from '../../axios'
// import InfiniteScroll from "react-infinite-scroll-component";

// class VehicleSelector extends Component {
//   constructor (props) {
//     super(props)
//     this.state = {
//       list: {},
//       search: '',
//       isChecked: false,
//       vehicles:''
//     }
//   }
//   componentWillMount () {
//     let list = {}
//     if (this.props.value && this.props.value.length) {
//       this.props.value.map(d => (list[d] = true))
//       this.setState({ list })
//     }
//     this.getVehicles()
//   }

//   componentWillReceiveProps (nextprops) {
//     let list = {}
//     if (nextprops.value && nextprops.value.length) {
//       nextprops.value.map(d => (list[d] = true))
//     }
//     this.setState({ list })
//   }

//   componentWillUnmount () {
//     this.setState({
//       list: {},
//       search: '',
//       isChecked: false
//     })
//   }

//   sendToParent () {
//     if (this.props.onChange) {
//       this.props.onChange(Object.keys(this.state.list))
//     }
//   }

//   onChange = (e, v) => {
//     let list = { ...this.state.list }
//     if (e.target.checked) {
//       list[v.id] = true
//     } else {
//       delete list[v.id]
//     }
//     this.setState(
//       {
//         list,
//         isChecked: this.props.vehicles?.length === Object.keys(list).length
//       },
//       () => {
//         this.sendToParent()
//       }
//     )
//   }
//   searchItem = text => {
//     this.setState({ search: text.target.value },()=>{this.getVehicles()})
//   }

//   toggleItems = event => {
//     if (event.target.checked) {
//       let list = {}
//       this.state.vehicles.map(d => (list[d.id] = true))
//       this.setState({ list, isChecked: true }, () => {
//         this.sendToParent()
//       })
//     } else {
//       this.setState({ list: {}, isChecked: false }, () => {
//         this.sendToParent()
//       })
//     }
//   }
//     toggleItems = (event) => {
//     if (event.target.checked) {
//       let list = {};
//       this.state.allVehicles &&
//         this.state.allVehicles.map((d) => (list[d.id] = true));
//       this.setState({ list, isChecked: true }, () => {
//         this.sendToParent(true);
//       });
//     } else {
//       this.setState({ list: {}, isChecked: false }, () => {
//         this.sendToParent();
//       });
//     }
//   };

  
//   getVehicles =  () => {
//     console.log("===gett")
//     let vehcilesList = []
//      if(this.props.logInUser&&this.props.logInUser.id){
//        instance({
//         method: 'GET',
//         url: `api/vehicles/get?userId=${this.props.logInUser.id}&all=true&limit&page=1&search=${this.state.search}`,
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json'
//         }
//       })
//         .then(res => {
//           vehcilesList = (res.data).map(item => {
//             return {
//               id:item.id,
//               key:item.id,
//               name:item.label,
//               label:item.label,
//               uniqueId:item.id,
//               valueType:'string'
//             }
//           })
//           // })
//           this.setState({vehicles:res})

//       })
//       .catch(error => {
//         // errorHandler(error, this.props.dispatch)
//       })
//      }
//   }


//   fetchMoreVehicles = () => {
//     console.log("===fetc")

//     let url,
//       userId = "";
//     this.setState(
//       {
//         page: this.state.page + 1,
//       },
//       () => {
//         // if (this.props.userId) {
//         //   if (this.props.userId.length > 0) {
//         //     let filter = "";
//         //     Object.entries(this.props.userId).map(([key, value]) => {
//         //       if (value) {
//         //         filter += "&userId=" + value;
//         //       }
//         //     });
//         //     userId = filter;
//         //     // multiple user
//         //     url = `api/vehicles/list?&page=${this.state.page}&limit=${this.state.limit}${filter}`;
//         //   } else {
//         //     userId = this.props.userId;
//         //     url = `api/vehicles/get?userId=${userId}&all=true&search=${this.state.search}`;
//         //   }
//         // } else {
//           userId = this.props.logInUser.id;
//           url = `api/vehicles/get?userId=${userId}&all=true&limit&search=${this.state.search}`;
//         // }
//         instance({
//           method: "GET",
//           url: url,
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//           },
//         })
//           .then(async (res) => {
//             this.setState({
//               vehicles: {
//                 ...res,
//                 data: this.state.vehicles.data.concat(res.data),
//               },
//             });
//           })
//           .catch((err) => {
//             console.log("err====", err);
//           });
//       }
//     );
//   };
//   render () {
//     let list = this.props.vehicles.filter(
//       item => item.label && item.label.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
//     )
//     return (
//       <Style>
//         {`
//         .selector-list li {
//             margin-bottom: 6px
//         }
//         .selector-list li > label {
//             cursor: pointer;
//             display: block;
//             padding: 2px 6px;
//             border-radius: 4px;
//             display: flex;
//             align-items: center;
//         }
//         .selector-list {
//             padding: 0;
//             margin: 0;
//             list-style: none
//         }
//         .selector-label {
//             font-size: 12px;
//             line-height: 1.5;
//             margin-left: 6px;
//         }
//         .selector-image {
//             width: 26px;
//             margin-left: 6px;
//         }
//         .selector-image img {
//             height: auto;
//             width: 26px;
//             height: 26px;
//             display: block;
//             margin: auto;
//         }
//         .selector-check {
//             height: 26px;
//             display: inline-flex;
//             align-items: center;
//         }
//         .selector-count {
//             padding: 0 0 6px;
//             font-size: 10px
//         }`}
//         <div>
//           <div
//             className='section-head section-head-filter'
//             style={{ padding: 0, marginLeft: -6 }}
//           >
//             <ul className='filter-row'>
//               <li>
//                 <SearchField
//                   label={this.props.translate('searchVehicles')}
//                   type='search'
//                   placeholder='exp: ID, Name'
//                   value={this.state.search}
//                   onChange={this.searchItem}
//                   variant='outlined'
//                   margin='dense'
//                   fullWidth
//                 />
//               </li>
//               <li style={{ paddingRight: 0, paddingTop: 3 }}>
//                 <Checkbox
//                   checked={this.state.isChecked}
//                   onChange={this.toggleItems}
//                   label={this.props.translate('selectAll')}
//                 />
//               </li>
//             </ul>
//           </div>
//           <div className='selector-count'>
//             {' '}
//             {Object.keys(this.state.list).length}{' '}
//             {this.props.translate('VehicleSelected')}
//           </div>
//           {!this.state.isChecked ? (
//           <Scrollbar
//             disableTracksWidthCompensation={true}
//             style={{
//               height:
//                 (this.props.minHeight ? parseInt(this.props.minHeight) : 35) *
//                 (this.props.rows || 5)
//             }}
//             scrollerProps={{
//               renderer: props => {
//                 const { elementRef, ...restProps } = props
//                 return (
//                   <div {...restProps} ref={elementRef} id='scrollableDiv' />
//                 )
//               }
//             }}
//           >
//             {console.log("===vehicles",this.state.vehicles)}
//             {this.state.vehicles.total ? (
//                    <InfiniteScroll
//                         dataLength={this.state.vehicles?.data?.length}
//                         next={() => this.fetchMoreVehicles()}
//                         hasMore={this.state.vehicles.hasNext}
//                         loader={<h3>Loading...</h3>}
//                         scrollableTarget="scrollableDeviceSelector"
//                         endMessage={
//                           <h3
//                             style={{
//                               display: "flex",
//                               justifyContent: "center",
//                             }}
//                           >
//                             ----End----
//                           </h3>
//                         }
//                         scrollThreshold={0.8}
//                         // onScroll={()=>this.fetchMoreVehicles()}
//                       >
//             <ul className='selector-list'>
//             {this.state.vehicles.data &&
//                             this.state.vehicles.data.map((row) => (
//                               <li key={row.id}>
//                                 <label
//                                   className={
//                                     this.state.list[row.id] ? "active" : null
//                                   }
//                                 >
//                                   <span className="selector-check">
//                                     <Checkbox
//                                       checked={this.state.list[row.id]}
//                                       value={row}
//                                       onChange={this.onChange}
//                                     />
//                                   </span>
//                                   <span className="selector-label">
//                                     {row.label || row.name}{" "}
//                                   </span>
//                                 </label>
//                               </li>
//                             ))}
//             </ul>
//             </InfiniteScroll>
//                        ) : null}
//           </Scrollbar>
//                ) : null}
//         </div>
//       </Style>
//     )
//   }
// }

// const mapState = state => ({
//   vehicles: state.vehicles,
//   themecolors: state.themeColors,
//   logInUser:state.logInUsers

// })
// const mapStateToProps = connect(mapState)
// export default mapStateToProps((VehicleSelector))
