// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./images/icon-play.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./images/icon-stop.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./images/icon-restart.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./images/icon-slow.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./images/icon-quick.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./images/icon-close.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-control-playback{position:relative;background-color:#7cbdf5;padding:10px;}
.leaflet-control-playback .optionsContainer{position:relative;}
.leaflet-control-playback .optionsContainer > div {
    display: inline-block;
}
.leaflet-control-playback .buttonContainer {}
.leaflet-control-playback .buttonContainer  a {
    display: inline-block;
    width: 32px;
    height: 32px;
    text-decoration: none;
}
.leaflet-control-playback .buttonContainer .btn-stop {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
}
.leaflet-control-playback .buttonContainer .btn-start {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat center;
}
.leaflet-control-playback .buttonContainer .btn-restart {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) no-repeat center;
}
.leaflet-control-playback .buttonContainer .btn-slow {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) no-repeat center;
}
.leaflet-control-playback .buttonContainer .btn-quick {
    background:  url(${___CSS_LOADER_URL_REPLACEMENT_4___}) no-repeat center;
}
.leaflet-control-playback .buttonContainer .btn-close {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_5___})   no-repeat center;
}
.leaflet-control-playback .infoContainer {}
.leaflet-control-playback .sliderContainer {}`, "",{"version":3,"sources":["webpack://./src/leaflet-plugin-trackplayback/control.trackplayback/control.playback.css"],"names":[],"mappings":"AAAA,0BAA0B,iBAAiB,CAAC,wBAAwB,CAAC,YAAY,CAAC;AAClF,4CAA4C,iBAAiB,CAAC;AAC9D;IACI,qBAAqB;AACzB;AACA,4CAA4C;AAC5C;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,qBAAqB;AACzB;AACA;IACI,oEAAwD;AAC5D;AACA;IACI,oEAAwD;AAC5D;AACA;IACI,oEAA2D;AAC/D;AACA;IACI,oEAAwD;AAC5D;AACA;IACI,qEAA0D;AAC9D;AACA;IACI,sEAA2D;AAC/D;AACA,0CAA0C;AAC1C,4CAA4C","sourcesContent":[".leaflet-control-playback{position:relative;background-color:#7cbdf5;padding:10px;}\n.leaflet-control-playback .optionsContainer{position:relative;}\n.leaflet-control-playback .optionsContainer > div {\n    display: inline-block;\n}\n.leaflet-control-playback .buttonContainer {}\n.leaflet-control-playback .buttonContainer  a {\n    display: inline-block;\n    width: 32px;\n    height: 32px;\n    text-decoration: none;\n}\n.leaflet-control-playback .buttonContainer .btn-stop {\n    background: url(./images/icon-play.png) no-repeat center;\n}\n.leaflet-control-playback .buttonContainer .btn-start {\n    background: url(./images/icon-stop.png) no-repeat center;\n}\n.leaflet-control-playback .buttonContainer .btn-restart {\n    background: url(./images/icon-restart.png) no-repeat center;\n}\n.leaflet-control-playback .buttonContainer .btn-slow {\n    background: url(./images/icon-slow.png) no-repeat center;\n}\n.leaflet-control-playback .buttonContainer .btn-quick {\n    background:  url(./images/icon-quick.png) no-repeat center;\n}\n.leaflet-control-playback .buttonContainer .btn-close {\n    background: url(./images/icon-close.png)   no-repeat center;\n}\n.leaflet-control-playback .infoContainer {}\n.leaflet-control-playback .sliderContainer {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
