import React, { Component, Fragment } from "react";
import SimpleModalWrapped from "../common/Modal";
import TextField from "../common/TextField";
import MenuItem from "../common/MenuItem";
//import mapLayerType from '../../lang/en.json'
// import InputAdornment from '@mui/material/InputAdornment'
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Place";
// import CrossIcons from '@mui/icons-material/Remove'
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "../common/Tooltip";
//import Attributes from '../Attributes/attributesModal'
// import DateFnsUtils from '@date-io/date-fns'
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Grid from "@mui/material/Grid";

//import Collapse from '@mui/material/Collapse';
import {
  coordinateFormatTypes,
  ownerUserType,
  dealerUserType,
  adminUserType,
  serviceProviderUserType,
  adminAccountUserType,
  measurementStandard,
  currencies,
} from "./userDataTypes";
import "./usersModal.scss";
import { checkPrivileges } from "../../Helpers";
// import Divider from '@mui/material/Divider';
import { connect } from "react-redux";
import SingleSelect from "../common/SingleSelect";
import { timeZonesTypes } from "../Attributes/timezone";
import instance from "../../axios";
import Checkbox from "../common/Checkbox";
import PublishIcon from "@mui/icons-material/Publish";
import CancelIcon from "@mui/icons-material/Cancel";
import withTranslationWrapper from "../../HOC/withTranslation";
import CustomDatePicker from "../common/CustomDatePicker";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormHelperText } from "@mui/material";

//let themecolors;
const mapStateToProps = (state, ownProps) => {
  //themecolors = state.themeColors;
  return {
    logInUsers: state.logInUsers,
    themecolors: state.themeColors,
    roleTypes:
      ownProps.viewScreen === "add"
        ? state.roles.filter((item) => item.id > 7)
        : state.roles,
  };
};
const ValidationIcon = ({ isValid, message }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {isValid ? (
      <CheckCircleIcon style={{ color: 'green', fontSize: 12 }} />
    ) : (
      <CancelIcon style={{ color: 'red', fontSize: 12 }} />
    )}
    <span style={{ marginLeft: '5px',color:"gray" }}>{message}</span>
  </div>
);

const PasswordRequirements = ({
  hasLengthValid,
  hasNumberValid,
  hasUpperCaseValid,
  hasLowerCaseValid,
  hasSpecialCharValid,
  translate,
}) => (
  <FormHelperText style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
    <ValidationIcon
      isValid={hasLengthValid}
      message={translate('Length must be at least 8 characters')}
    />
    <ValidationIcon
      isValid={hasNumberValid}
      message={translate('Must contain at least one Number')}
    />
    <ValidationIcon
      isValid={hasUpperCaseValid}
      message={translate('Must contain at least one Uppercase letter')}
    />
    <ValidationIcon
      isValid={hasLowerCaseValid}
      message={translate('Must contain at least one Lowercase letter')}
    />
    <ValidationIcon
      isValid={hasSpecialCharValid}
      message={translate('Must contain at least one Special character (!@#$%^&*(),.?:{}|<></>)')}
    />
  </FormHelperText>
);
class addUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      at_modal_visable: false,
      open: props.viewScreen === "add" ? true : false,
      resultMessage: "",
      showButton: this.props.editOption ? true : false,
    };
    this.addAttributes = this.addAttributes.bind(this);
    this.selectMe = this.selectMe.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.searchFromZoneList = this.searchFromZoneList.bind(this);
  }

  toggleCollapse() {
    let a = this.state.open;
    this.setState({
      open: !a,
    });
  }

  toggleRoles = (e) => {
    this.setState({
      showButton: !this.state.showButton,
    });
    return this.props.handleChange("roleId")(e);
  };

  addAttributes() {
    this.setState({
      at_modal_visable: !this.state.at_modal_visable,
    });
  }

  selectMe(element) {
    var doc = document,
      text = element.target,
      range,
      selection;
    if (doc.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  async searchFromZoneList(inputValue) {
    if (!inputValue) {
      return [];
    }

    let result = timeZonesTypes.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    return result.map((item) => {
      return {
        id: item.key,
        key: item.key,
        label: item.label,
        value: item.key,
      };
    });
  }
  searchAccountsList = async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    let params = {
      accountId: this.props.logInUser.accountId,
      search: inputValue,
      limit: 100,
    };
    if (this.props.logInUser.userType === -1) {
      params = {
        ...params,
        all: true,
      };
    }
    // let fetchApi = `/api/accounts/all?accountId=${this.props.logInUser.accountId}&search=${inputValue}&limit=100`
    // if (this.props.logInUser.userType === -1) {
    //   fetchApi = `/api/accounts/all?accountId=${this.props.logInUser.accountId}&all=true&search=${inputValue}&limit=100`
    // }
    // let response = await fetch(fetchApi, {
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    let response = instance({
      url: `/api/accounts/all`,
      method: "GET",
      params: {
        ...params,
      },
    });
    // let json = await response.json()
    let result =
      //   (await json.data) &&
      response.data.map((item) => ({
        ...item,
        label: item.label || item.name,
        value: item.id,
      }));
    if (result.length === 0) {
      this.setState({
        resultMessage: "No option found",
      });
    } else {
      this.setState({
        resultMessage: "",
      });
    }

    if (response.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: "Search for more.",
      });
    }
    return result;
  };
  searchUsersList = async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    let fetchapi = `//api/users/list?accountId=${this.props.form.accountId}&search=${inputValue}&limit=100`;
    // if (this.props.logInUser.userType === -1) {
    //   fetchapi = `/api/users/list?userId=${this.props.logInUser.accountId}&all=true&search=${inputValue}&limit=100`
    // }
    // let response = await fetch(fetchapi, {
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    let response = instance({
      url: `/api/users/list`,
      method: "GET",
      params: {
        accountId: this.props.form.accountId,
        search: inputValue,
        limit: 100,
      },
    });
    // let json = await response.json()
    let result =
      //   (await json.data) &&
      response.data.map((item) => ({
        ...item,
        label: item.label || item.name,
        value: item.id,
      }));
    if (result.length === 0) {
      this.setState({
        resultMessage: "No option found",
      });
    } else {
      this.setState({
        resultMessage: "",
      });
    }

    if (response.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: "Search for more.",
      });
    }
    return result;
  };

  render() {
    let defaultZones = [];
    timeZonesTypes.map((item, i) => {
      // if (i <= 9) {
      defaultZones.push({
        id: item.key,
        key: item.key,
        label: item.label,
        value: item.key,
      });
      // }
      return null;
    });
    let userTypeArray = [];
    if (this.props.userType) {
      let { userType } = this.props;
      if (userType === -1) {
        userTypeArray = ownerUserType;
      } else if (userType === 2) {
        userTypeArray = adminUserType;
      } else if (userType === 3) {
        userTypeArray = adminAccountUserType;
      } else if (userType === 4) {
        userTypeArray = serviceProviderUserType;
      } else if (userType === 5) {
        userTypeArray = dealerUserType;
      } else {
        userTypeArray = [];
      }
    }
    const empty = null;
    return (
      <div>
        {this.props.activeOperation === "add" ||
        this.props.activeOperation === "edit" ? (
          <SimpleModalWrapped
            visable={true}
            title={"sharedRequired"}
            isButtonVisable={true}
            notShowAttributesBtn
            formSubmit={this.props.formSubmit}
            addAttributes={this.addAttributes}
            modalControle={this.props.modalControle}
            buttonText={this.props.buttonText}
            btnCloseText="sharedBack"
            isVisableBtn={this.props.isVisableUserBtn}
            hasPremissions
            canUpdate={this.props.canUpdate}
            attributeChangesMessage={this.props.attributeChangesMessage}
            btnCancelNotShow={this.props.btnCancelNotShow}
            content={
              <Fragment>
                <Grid container spacing={1}>
                  <Grid item sm={6} md={3} xs={12}>
                    <TextField
                      id="name"
                      label={this.props.translate("sharedName")}
                      type="text"
                      required
                      placeholder=""
                      value={this.props.form.name || ""}
                      onChange={this.props.handleChange("name")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} md={3} xs={12}>
                    <TextField
                      id="nameArabic"
                      label={this.props.translate("nameArabic")}
                      type="text"
                      placeholder=""
                      value={this.props.form?.nameArabic || ""}
                      onChange={this.props.handleChange("nameArabic")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  {this.props.viewScreen !== "edit" && (
                  <Grid item sm={6} md={3} xs={12}>
                        <TextField
                          id="userType"
                          select
                          required
                          label={this.props.translate("userType")}
                          value={this.props.form.userType || ""}
                          onChange={this.props.handleChange("userType")}
                          // helperText={this.props.roleTypes.length  ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
                          margin="dense"
                          fullWidth
                        >
                          <MenuItem value="">
                            <em>{this.props.translate("none")}</em>
                          </MenuItem>
                          {userTypeArray.length &&
                            userTypeArray.map((option) => (
                              <MenuItem key={option.key} value={option.key}>
                                {this.props.translate(option.name)}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
         ) }


           
             
                  {/* <Grid item xl={3} sm={6} md={4} xs={12}>
                  <TextField
                    id='type'
                    label={this.props.translate('type')}
                    type='text'
                    placeholder=''
                    value={this.props.form?.type || ''}
                    onChange={this.props.handleChange('type')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                />
                </Grid> */}
                  {!this.state.showButton && this.props.roleTypes.length ? (
                    <Grid
                      item
                      md={3}
                      sm={6}
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span
                        onClick={(e) => this.toggleRoles(e)}
                        style={{ cursor: "pointer" }}
                      >
                        <b>{this.props.translate("selectCustomRole")}</b>
                      </span>
                    </Grid>
                  ) : (
                    ""
                  )}

                  {checkPrivileges("role") && this.state.showButton && (
                    <Grid item sm={6} md={3} xs={12}>
                      <TextField
                        id="role"
                        select
                        label={this.props.translate("role")}
                        value={this.props.form.roleId || ""}
                        onChange={this.props.handleChange("roleId")}
                        margin="dense"
                        fullWidth
                      >
                        {/* {!this.props.editOption && 
                              (<MenuItem key={'defaultRole'} value={'defaultRole'} >
                                <em>{this.props.translate('default')}</em>
                              </MenuItem>
                              )}
                            {!this.props.editOption && <Divider style={{background:'black'}}/> } */}

                        {this.props.roleTypes.length ? (
                          this.props.roleTypes.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {this.props.translate(option.name)}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="">
                            <em>{this.props.translate("none")}</em>
                          </MenuItem>
                        )}
                      </TextField>
                      {!this.props.editOption ? (
                        <span
                          onClick={(e) => this.toggleRoles(e)}
                          style={{ cursor: "pointer" }}
                        >
                          <b>{this.props.translate("useDefaultRole")}</b>
                        </span>
                      ) : (
                        ""
                      )}
                    </Grid>
                  )}
                         <Grid item sm={6} md={3} xs={12}>
                    <TextField
                      id="email"
                      error={this.props.duplicateEntry}
                      label={this.props.translate("userEmail")}
                      type="text"
                      required
                      placeholder=""
                      value={this.props.form.email || ""}
                      onChange={this.props.handleChange("email")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                       {this.props.viewScreen !== "edit" && (
                    <>
                      <Grid item sm={6} md={3} xs={12}>
                        <TextField
                          id="password"
                          label={this.props.translate("userPassword")}
                          type="password"
                          required
                          placeholder=""
                          value={this.props.form.password || ""}
                          onChange={this.props.handleChange("password")}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
  
<PasswordRequirements
          hasLengthValid={this.props.hasLengthValid}
          hasNumberValid={this.props.hasNumberValid}
          hasUpperCaseValid={this.props.hasUpperCaseValid}
          hasLowerCaseValid={this.props.hasLowerCaseValid}
          hasSpecialCharValid={this.props.hasSpecialCharValid}
          translate={this.props.translate}
        />
                      </Grid>

                      <Grid item sm={6} md={3} xs={12}>
                        <TextField
                          id="confirmPassword"
                          label={this.props.translate("confirmPassword")}
                          type="password"
                          error={
                            this.props.form.password ===
                            this.props.form.confirmPassword
                              ? false
                              : true
                          }
                          required
                          placeholder=""
                          value={this.props.form.confirmPassword}
                          onChange={this.props.handleChange("confirmPassword")}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                               <FormHelperText style={{ color: this.props.themecolors.textColor }}>
          <ValidationIcon
            isValid={this.props.isConfirmPassChk}
            message={this.props.translate('Both Passwords must be Equal')}
          />
        </FormHelperText>
                      </Grid>
          
                    </>
                  )}
                  {/* {(checkPrivileges('userCreate') || checkPrivileges('userUpdate')) && (
                  <Grid item sm={6} md={3} xs={12}>
                    <TextField
                      id='role'
                      select
                      label={this.props.translate('roleName')}
                      value={this.props.form.roleId || ''}
                      onChange={this.props.handleChange('roleId')}
                      // disabled={this.props.viewScreen === 'edit'}
                      margin='dense'
                      fullWidth
                    >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem> 
                      {this.props.roleTypes &&
                        this.props.roleTypes.map(option => (
                          <MenuItem key={option.id} value={option.id}>
                            {this.props.translate(option.name)}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                )} */}
                </Grid>
                <h3
                  className="with-border pull-left v-tab-heading"
                  style={{ margin: "10px 0px" }}
                >
                  {this.props.translate("sharedPreferences")}
                </h3>
                <Grid container spacing={1}>
                  <Grid item sm={6} md={3} xs={12}>
                    <TextField
                      id="phone"
                      label={this.props.translate("sharedPhone")}
                      type="text"
                      placeholder=""
                      value={this.props.form.phone || ""}
                      onChange={this.props.handleChange("phone")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  {this.props.viewScreen === "edit" && (
                    <Grid item sm={6} md={3} xs={12}>
                      <TextField
                        id="map"
                        select
                        label={this.props.translate("mapTitle")}
                        value={this.props.form.map || ""}
                        onChange={this.props.handleChange("map")}
                        margin="dense"
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>{this.props.translate("none")}</em>
                        </MenuItem>
                        {this.props.form &&
                          this.props.form.attributes.maps &&
                          this.props.form.attributes.maps.map((option) => (
                            <MenuItem key={option} value={option}>
                              {this.props.translate(option)}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  )}

                  <Grid item sm={6} md={3} xs={12}>
                    <TextField
                      id="coordinateFormat"
                      select
                      label={this.props.translate("settingsCoordinateFormat")}
                      value={this.props.form.coordinateFormat || ""}
                      onChange={this.props.handleChange("coordinateFormat")}
                      margin="dense"
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>{this.props.translate("none")}</em>
                      </MenuItem>
                      {coordinateFormatTypes.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                          {this.props.translate(option.name)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {/* <Grid item sm={6} md={3} xs={12}>
                  <TextField
                    id='poiLayer'
                    label={this.props.translate('mapPoiLayer')}
                    type='text'
                    placeholder=''
                    value={this.props.form.poiLayer || ''}
                    onChange={this.props.handleChange('poiLayer')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}
                </Grid>
                <Grid container spacing={1}>
                  <Grid item sm={6} md={3} xs={12}>
                    <TextField
                      id="latitude"
                      type="number"
                      label={this.props.translate("positionLatitude")}
                      placeholder=""
                      value={this.props.form.latitude || ""}
                      onChange={this.props.handleChange("latitude")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} md={3} xs={12}>
                    <TextField
                      id="longitude"
                      type="number"
                      label={this.props.translate("positionLongitude")}
                      value={this.props.form.longitude || ""}
                      onChange={this.props.handleChange("longitude")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} md={3} xs={12}>
                    <TextField
                      id="zoom"
                      label={this.props.translate("serverZoom")}
                      type="number"
                      required
                      placeholder=""
                      error={
                        this.props.form.zoom > 19 || this.props.form.zoom < 3
                          ? true
                          : false
                      }
                      helperText="Enter Value 3 to 18"
                      value={this.props.form.zoom || ""}
                      onChange={this.props.handleChange("zoom")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} md={3} xs={12}>
                    <Tooltip title={this.props.translate("addLocation")}>
                      <IconButton
                        color="inherit"
                        onClick={this.props.handleClickShowPostion}
                      >
                        {<EditIcon />}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                {this.props.form.userType && this.props.form.userType !== 1 && (
                  <>
                    {" "}
                    <h3
                      className="with-border pull-left v-tab-heading"
                      style={{ margin: "10px 0px" }}
                    >
                      {this.props.translate("sharedPermissions")}
                    </h3>
                    <Grid container spacing={1}>
                      <Grid item sm={6} md={3} xs={12}>
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          format="dd/MM/yyyy"
                          error={false}
                          margin='dense'
                          label={this.props.translate('userExpirationTime')}
                          variant='outlined'
                          fullWidth
                          minDate={new Date()}
                          value={
                            this.props.form.expirationTime
                              ? new Date(
                                  `${this.props.form.expirationTime}`
                                ).toString()
                              : null
                          }
                          onChange={this.props.handleChange('expirationTime')}
                          InputProps={{
                            classes: {
                              root: 'theme-cssOutlinedInput',
                              input: 'theme-input',
                              focused: 'theme-cssFocused',
                              notchedOutline: 'theme-notchedOutline'
                            },
                            endAdornment: (
                              <InputAdornment position='end'>
                                <Tooltip
                                  title={this.props.translate('noLimit')}
                                >
                                  <IconButton
                                    onClick={e =>
                                      this.props.setExpirationTime(e)
                                    }
                                  >
                                    {<CrossIcons />}
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            )
                          }}
                          InputLabelProps={{
                            classes: {
                              root: 'theme-label',
                              focused: 'theme-label-focused',
                              shrink: 'theme-label-shrink'
                            }
                          }}
                        />
                      </LocalizationProvider> */}
                        <CustomDatePicker
                          format="dd/MM/yyyy"
                          margin="dense"
                          label={this.props.translate("userExpirationTime")}
                          variant="outlined"
                          fullWidth
                          minDate={new Date()}
                          value={
                            this.props.form?.expirationTime
                              ? `${this.props.form?.expirationTime}`
                              : null
                          }
                          onChange={this.props.handleChange("expirationTime")}
                        />
                        {this.props.form.expirationTime && (
                          <Tooltip title={this.props.translate("noLimit")}>
                            <a
                              href={empty}
                              onClick={(e) => this.props.setExpirationTime(e)}
                            >
                              no limit
                            </a>
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid item sm={6} md={3} xs={12}>
                        <TextField
                          id="deviceLimit"
                          label={this.props.translate("userDeviceLimit")}
                          type="number"
                          placeholder=""
                          value={this.props.form.deviceLimit}
                          onChange={this.props.handleChange("deviceLimit")}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item sm={6} md={3} xs={12}>
                        <TextField
                          id="userLimit"
                          label={this.props.translate("userUserLimit")}
                          type="number"
                          placeholder=""
                          value={this.props.form.userLimit}
                          onChange={this.props.handleChange("userLimit")}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item sm={6} md={3} xs={12}>
                        <TextField
                          id="vehicleLimit"
                          label={this.props.translate("vehicleLimit")}
                          type="number"
                          placeholder=""
                          value={this.props.form.vehicleLimit}
                          onChange={this.props.handleChange("vehicleLimit")}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          error={
                            this.props.userType !== -1
                              ? this.props.form.vehicleLimit >
                                this.props.vehicleRemainingLimits
                                ? true
                                : false
                              : false
                          }
                          helperText={
                            this.props.form.vehicleLimit >
                            this.props.vehicleRemainingLimits
                              ? `Remaining Vehicle Limits  ${this.props.vehicleRemainingLimits}`
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <h3
                  className="with-border pull-left v-tab-heading"
                  style={{ margin: "10px 0px" }}
                >
                  {this.props.translate("sharedAttributes")}
                </h3>
                <Grid container spacing={1}>
                  <Grid item sm={6} md={3} xs={12}>
                    <SingleSelect
                      array={[]}
                      async
                      selectName="timezone"
                      isClearable={false}
                      defaultOptions={defaultZones}
                      loadOptions={this.searchFromZoneList}
                      label={this.props.translate("sharedTimezone")}
                      value={
                        this.props.form.attributes &&
                        this.props.form.attributes["timezone"]
                          ? timeZonesTypes.find(
                              (t) =>
                                t.key === this.props.form.attributes["timezone"]
                            )
                          : ""
                      }
                      handleChange={this.props.timezone_handleChange}
                      canAssign={true}
                    />
                  </Grid>
                  <Grid item sm={6} md={3} xs={12}>
                    <TextField
                      id="measurementStandard"
                      select
                      label={this.props.translate("measurementStandard")}
                      value={
                        (this.props.form.attributes &&
                          this.props.form.attributes.measurementStandard) ||
                        ""
                      }
                      onChange={(e) =>
                        this.props.changeAttribute2("measurementStandard", e)
                      }
                      margin="dense"
                      fullWidth
                    >
                      {measurementStandard.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                          {this.props.translate(option.value)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item sm={6} md={3} xs={12}>
                    <TextField
                      id="currencyLabel"
                      select
                      label={this.props.translate("currencyLabel")}
                      value={
                        (this.props.form.attributes &&
                          this.props.form.attributes.currencyLabel) ||
                        this.props.translate("aed")
                      }
                      onChange={(e) =>
                        this.props.changeAttribute2("currencyLabel", e)
                      }
                      margin="dense"
                      fullWidth
                    >
                      {currencies &&
                        currencies.map((option) => (
                          <MenuItem key={option.key} value={option.value}>
                            {this.props.translate(option.value)}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    md={3}
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Checkbox
                      checked={
                        this.props.form.attributes &&
                        this.props.form.attributes.viewModel
                      }
                      onChange={(e) =>
                        this.props.changeAttribute2("viewModel", e)
                      }
                      canAssign
                      canRemove
                      label={this.props.translate("viewModel")}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    md={3}
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Checkbox
                      checked={
                        this.props.form.attributes &&
                        this.props.form.attributes.viewIMEI
                      }
                      onChange={(e) =>
                        this.props.changeAttribute2("viewIMEI", e)
                      }
                      canAssign
                      canRemove
                      label={this.props.translate("viewIMEI")}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: "8px" }}>
                    {this.props.editOption ? (
                      <>
                        <Tooltip title="Upload Image">
                          <Button
                            variant="contained"
                            margin="dense"
                            component="label"
                            onChange={(e) => this.props.uploadFiles(e)}
                          >
                            <input
                              type="file"
                              hidden
                              multiple
                              accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            />
                            {this.props.translate("uploadImagesDoc")}{" "}
                            {/* ({this.props.translate('max')} 5) */}
                            <PublishIcon
                              style={{ color: "#000", marginLeft: 15 }}
                            />
                          </Button>
                        </Tooltip>
                        <div style={{ display: "block" }}>
                          <div
                            style={{
                              display: "inline-flex",
                              flexWrap: "wrap",
                              marginTop: "15px",
                            }}
                          >
                            {this.props.userFilesData &&
                            this.props.userFilesData.length > 0
                              ? this.props.userFilesData.map((data, key) => (
                                  <div
                                    style={{
                                      position: "relative",
                                      margin: "0 15px 20px 0",
                                    }}
                                    key={key}
                                  >
                                    <Tooltip
                                      placement="right-end"
                                      title={data.fileName}
                                    >
                                      <a
                                        style={{
                                          padding: "10px",
                                          background: "#fff",
                                          borderRadius: "5px",
                                          boxShadow:
                                            "0 0 10px rgba(0, 0, 0, 0.1)",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          width: "135px",
                                          display: "block",
                                          textAlign: "center",
                                        }}
                                        download={data.fileName}
                                        href={data.url}
                                        target={"_blank"}
                                      >
                                        {data.fileName}
                                      </a>
                                    </Tooltip>
                                    <CancelIcon
                                      style={{
                                        position: "absolute",
                                        top: "-10px",
                                        right: "-10px",
                                        cursor: "pointer",
                                        zIndex: "99",
                                        background: "#fff",
                                        borderRadius: "50%",
                                      }}
                                      onClick={async () => {
                                        await this.props.removeUserFile(
                                          data.id
                                        );
                                        this.props.enableUpdateButton();
                                      }}
                                    />
                                  </div>
                                ))
                              : null}
                            {this.props.uploadDoc &&
                            this.props.uploadDoc.length > 0
                              ? this.props.uploadDoc.map((data, key) => (
                                  <div
                                    style={{
                                      position: "relative",
                                      margin: "0 15px 20px 0",
                                    }}
                                    key={key}
                                  >
                                    <Tooltip placement="right-end" title={data}>
                                      <a
                                        style={{
                                          padding: "10px",
                                          background: "#fff",
                                          borderRadius: "5px",
                                          boxShadow:
                                            "0 0 10px rgba(0, 0, 0, 0.1)",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          width: "135px",
                                          display: "block",
                                          textAlign: "center",
                                        }}
                                      >
                                        {data}
                                      </a>
                                    </Tooltip>
                                    <CancelIcon
                                      style={{
                                        position: "absolute",
                                        top: "-10px",
                                        right: "-10px",
                                        cursor: "pointer",
                                        zIndex: "99",
                                        background: "#fff",
                                        borderRadius: "50%",
                                      }}
                                      onClick={() => {
                                        this.props.removeFiles(key);
                                      }}
                                    />
                                  </div>
                                ))
                              : null}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </Grid>
                  <Grid item sm={6} md={3} xs={12}>
                    <label
                      style={{
                        fontSize: 10,
                        color: this.props.themecolors.textColor,
                      }}
                    >
                      {this.props.translate("userToken")}
                    </label>
                    <Tooltip title={this.props.translate("getToken")}>
                      <Button
                        className="pull-right"
                        onClick={this.props.generateToken}
                      >
                        {
                          <RefreshIcon
                            style={{
                              color: this.props.themecolors.textColor,
                            }}
                          />
                        }
                      </Button>
                    </Tooltip>
                    <p onClick={(e) => this.selectMe(e)}>
                      <code style={{ wordBreak: "break-all" }}>
                        {this.props.viewScreen === "edit"
                          ? this.props.form.token &&
                            this.props.ServerSetting &&
                            this.props.ServerSetting.attributes &&
                            (this.props.ServerSetting.attributes.hostUrl +
                              "/loginasuser?&" +
                              btoa(this.props.form.token) ||
                              "")
                          : this.props.form.token || ""}
                      </code>{" "}
                      &nbsp;
                    </p>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12}  md={4}style={/{ marginTop: 20 }}> */}
              </Fragment>
            }
          />
        ) : (
          <SimpleModalWrapped
            visable={true}
            title={"sharedRequired"}
            isButtonVisable={true}
            notShowAttributesBtn
            formSubmit={this.props.formSubmit}
            addAttributes={this.props.addAttributes}
            modalControle={this.props.modalControle}
            buttonText={this.props.buttonText}
            btnCloseText="sharedBack"
            isVisableBtn={this.props.isVisableUserBtn}
            hasPremissions
            canUpdate={this.props.canUpdate}
            attributeChangesMessage={this.props.attributeChangesMessage}
            btnCancelNotShow={this.props.btnCancelNotShow}
            content={
              <Fragment>
                <Grid container spacing={1}>
                <Grid item sm={6} md={3} xs={12}>
                        <TextField
                          id="password"
                          label={this.props.translate("userPassword")}
                          type="password"
                          required
                          placeholder=""
                          value={this.props.form.password || ""}
                          onChange={this.props.handleChange("password")}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
  
<PasswordRequirements
          hasLengthValid={this.props.hasLengthValid}
          hasNumberValid={this.props.hasNumberValid}
          hasUpperCaseValid={this.props.hasUpperCaseValid}
          hasLowerCaseValid={this.props.hasLowerCaseValid}
          hasSpecialCharValid={this.props.hasSpecialCharValid}
          translate={this.props.translate}
        />
                      </Grid>

                      <Grid item sm={6} md={3} xs={12}>
                        <TextField
                          id="confirmPassword"
                          label={this.props.translate("confirmPassword")}
                          type="password"
                          error={
                            this.props.form.password ===
                            this.props.form.confirmPassword
                              ? false
                              : true
                          }
                          required
                          placeholder=""
                          value={this.props.form.confirmPassword}
                          onChange={this.props.handleChange("confirmPassword")}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                               <FormHelperText style={{ color: this.props.themecolors.textColor }}>
          <ValidationIcon
            isValid={this.props.isConfirmPassChk}
            message={this.props.translate('Both Passwords must be Equal')}
          />
        </FormHelperText>
                      </Grid>
                </Grid>
              </Fragment>
            }
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(withTranslationWrapper(addUserModal));
