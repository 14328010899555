import React from 'react'
import Button from '../../Components/common/Button'
import AsyncSelect from '../../Components/common/asyncSelect'
import TextField from '../../Components/common/TextField'
import instance from '../../axios'
import { Grid, InputAdornment, FormControlLabel, Collapse } from '@mui/material'
import SingleSelect from '../../Components/common/SingleSelect'
import CustomDatePicker from '../../Components/common/CustomDatePicker'
import Checkbox from '../../Components/common/Checkbox'
import Tooltip from '../../Components/common/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Switch from '../../Components/common/Switch'
import { SettingForm } from './SettingForm'
import VehicleSelector from '../../Components/Devices/VehicleSelector'

export const CreateReminderMaintenance = (props) => {
    const { form, translate, handleChange } = props
    return (
        <div style={{ padding: 4 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 style={{ margin: 0 }} className="page-title">{props.translate('maintenanceReminder')}</h2>
                <div><Button
                    size='small'
                    onClick={props.back}
                >
                    {props.translate('sharedBack')}
                </Button></div>
            </div>
            <Grid container spacing={1}>
         
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                        id='name'
                        margin='dense'
                        label={props.translate('sharedName')}
                        variant='outlined'
                        fullWidth
                        value={props.form.name||""}
                        onChange={e => handleChange('name', e.target.value)}
                    />
                </Grid>
           
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <SingleSelect
                        array={props.servicesAttributes ? props.servicesAttributes : []}
                        id='serviceTypeId'
                        selectName='serviceTypeId'
                        label={translate('sharedType')}
                        value={props.form.attributes && props.form.attributes.serviceTypeId ?
                            {
                                key: form.attributes.serviceTypeId.key,
                                id: form.attributes.serviceTypeId.id,
                                value: form.attributes.serviceTypeId.id,
                                label: form.attributes.serviceTypeId.name ? form.attributes.serviceTypeId.name : form.attributes.serviceTypeId.name
                            } : ''
                        }

                        handleChange={props.handleChangeAttributes}
                        canRemove={true}
                        canAssign={true}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <SingleSelect
                        id='serviceExpenseTypeId'
                        array={(props.subTypes) || []}
                        selectName='serviceExpenseTypeId'
                        label={translate('subType')}
                        value={form.attributes && form.attributes.serviceExpenseTypeId ?
                            {
                                key: form.attributes.serviceExpenseTypeId.key,
                                id: form.attributes.serviceExpenseTypeId.id,
                                value: form.attributes.serviceExpenseTypeId.id,
                                label: form.attributes.serviceExpenseTypeId.name ? form.attributes.serviceExpenseTypeId.name : form.attributes.serviceExpenseTypeId.label
                            } : ''
                        }
                        handleChange={props.handleChangeAttributes}
                        canRemove={true}
                        canAssign={true}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                        id='cost'
                        margin='dense'
                        label={props.translate('cost')}
                        variant='outlined'
                        fullWidth
                        type='number'
                        value={props.form.cost||""}
                        onChange={e => handleChange('cost', e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position='start'
                                    disableTypography
                                    style={{ color: "gray" }}
                                >
                                    {props.logInUser?.attributes && props.logInUser?.attributes?.currencyLabel || 'AED'}
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                     <VehicleSelector
                                api='vehicle'
                                fill
                                hideIcons
                                isClearable
                                placeholder={props.translate('searchVehicle')}
                                onChange={props.handleStoreVehicleIds}
                                value={props.vehicleIds||[]}
                              /> 
                </Grid>
                {(props.vehicleTracker || props.selectedUnit||props.vehicleIds?.length>0) && <>
                    <Grid item xs={12} sm={6} md={4} lg={3} style={{ alignItems: 'center', display: 'flex' }}>
                        <div style={{ display: 'flex', color: 'orange', justifyContent: 'space-between', flex: 1 }}>
                            <Checkbox
                                checked={form && form.attributes && form && form.attributes && form.attributes.repeat}
                                onChange={e => props.handleChangeAttributes('repeat', e.target.checked)}
                                color='primary'
                                label={translate('repeat')}
                            />
                            <Tooltip
                                classes={{
                                    popper: 'menu-popper',
                                    tooltip: 'menu-popper-tooltip'
                                }}
                                title={translate('maintenanceRepeatTooltip')}>
                                <HelpOutlineIcon style={{ fontSize: 15, marginTop: 3, marginRight: 15 }} />
                            </Tooltip>
                        </div>
                    </Grid>
                    <Grid item className={form.attributes && form.attributes.byMileage ? 'opened ' : ''} xs={12}>
                        <Grid container>
                            <Grid sm={4} xs={12} >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <strong className='alt-title'>{translate('deviceMileage')}</strong>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={form && form.attributes && form.attributes.byMileage === true}
                                                onChange={e => props.handleChangeAttributes('byMileage', true, e.target.checked)}
                                                name='totalDistance'
                                            />
                                        }
                                        label={
                                            form && form.attributes && form.attributes.byMileage === true
                                                ? translate('enable')
                                                : translate('disable')
                                        }
                                        labelPlacement='start'
                                        classes={{ label: 'color-inherit' }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={form && form.attributes && form.attributes.byMileage === true} >
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TextField
                                        id='start'
                                        type='number'
                                        margin='dense'
                                        label={translate(form && form.attributes && form.attributes.repeat ? 'maintenanceStart' : 'targetValue')}
                                        variant='outlined'
                                        fullWidth
                                        value={form && form.attributes && form.attributes.mileage || ''}
                                        onChange={e => props.handleChangeAttributes('mileage', e.target.value)}
                                        InputProps={{
                                            // readOnly: form && form.attributes && form.attributes.repeat ? true : false,
                                            endAdornment: (
                                                <InputAdornment
                                                    position='start'
                                                    disableTypography
                                                >
                                                    {'(km)'}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                {form.attributes && form.attributes.repeat ? (<Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TextField
                                        id='periodTotalDistance'
                                        type='number'
                                        margin='dense'
                                        label={translate('maintenancePeriod') + ' (km)'}
                                        variant='outlined'
                                        fullWidth
                                        value={form.attributes.mileageInterval || ''}
                                        onChange={e => props.handleChangeAttributes('mileageInterval', e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position='start'
                                                    disableTypography
                                                >
                                                    {'(km)'}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>) : null}
                                <Grid item style={{ marginLeft: 5 }}>
                                    <div style={{ paddingTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                                        <Checkbox
                                            checked={form && form.attributes && form.attributes.reminderByMileage}
                                            onChange={e => props.handleChangeAttributes('reminderByMileage', e.target.checked)}
                                            color='primary'
                                            label={translate('reminder')}
                                        />
                                        <Tooltip
                                            classes={{
                                                popper: 'menu-popper',
                                                tooltip: 'menu-popper-tooltip'
                                            }}
                                            title={translate('checkRemindMeBefore')}>
                                            <HelpOutlineIcon style={{ fontSize: 15, marginTop: 3, marginLeft: 15, marginRight: 10 }} />
                                        </Tooltip>
                                    </div>
                                </Grid>
                                {form && form.attributes && form.attributes.reminderByMileage ? (<Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TextField
                                        id='maxLimit'
                                        label={translate('remindMeBefore') + ' '}
                                        required
                                        placeholder=''
                                        type='number'
                                        value={form && form.attributes && form.attributes.reminderBeforeMileage || ''}
                                        onChange={e => props.handleChangeAttributes('reminderBeforeMileage', e.target.value)}
                                        error={
                                            // (form.attributes.mileageInterval) < (form.attributes.reminderBeforeMileage)
                                            //  ? true
                                            //   : false
                                            (form.attributes.mileageInterval && form.attributes.reminderBeforeMileage && form.attributes.repeat) ? ((form.attributes.mileageInterval - form.attributes.reminderBeforeMileage) >= 0 ?
                                                false : true) : false
                                        }
                                        helperText={form && form.attributes && form.attributes.repeat ? <p style={{ fontSize: 9, marginTop: 0, marginBottom: 0 }}>{translate('enterPeriodValueKm')}</p> : ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position='start'
                                                    disableTypography
                                                >
                                                    {'(km)'}
                                                </InputAdornment>
                                            )
                                        }}
                                        variant='outlined'
                                        margin='dense'
                                        fullWidth
                                    />
                                </Grid>) : null}
                            </Grid>
                        </Collapse>
                    </Grid>
                    {/* <Grid item xs={12} className={form.attributes && form.attributes.byHours ? 'opened ' : ''}>
                        <Grid container>
                            <Grid sm={4} xs={12} >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <strong className='alt-title'>{translate('reportEngineHours')}</strong>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={form && form.attributes && form.attributes.byHours === true}
                                                onChange={e => props.handleChangeAttributes('byHours', true, e.target.checked)}
                                                name='hours'
                                            />
                                        }
                                        label={
                                            form && form.attributes && form.attributes.byHours === true
                                                ? translate('enable')
                                                : translate('disable')
                                        }
                                        labelPlacement='start'
                                        classes={{ label: 'color-inherit' }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={form && form.attributes && form.attributes.byHours === true} >
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TextField
                                        id='start'
                                        type='number'
                                        margin='dense'
                                        label={translate(form && form.attributes && form.attributes.repeat ? 'maintenanceStart' : 'targetValue')}
                                        variant='outlined'
                                        fullWidth
                                        value={form && form.attributes && form.attributes.hours || ''}
                                        onChange={e => props.handleChangeAttributes('hours', e.target.value)}
                                        InputProps={{
                                            readOnly: form && form.attributes && form.attributes.repeat ? true : false,
                                            endAdornment: (
                                                <InputAdornment
                                                    position='start'
                                                    disableTypography
                                                >
                                                    {'(hours)'}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                {form && form.attributes && form.attributes.repeat ? (<Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TextField
                                        id='hoursInterval'
                                        type='number'
                                        margin='dense'
                                        label={translate('maintenancePeriod') + ' (hour)'}
                                        variant='outlined'
                                        fullWidth
                                        value={form && form.attributes && form.attributes.hoursInterval || ''}
                                        onChange={e => props.handleChangeAttributes('hoursInterval', e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position='start'
                                                    disableTypography
                                                >
                                                    {'(hours)'}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>) : null}
                                <Grid item style={{ marginLeft: 5 }}>
                                    <div style={{ paddingTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                                        <Checkbox
                                            checked={form && form.attributes && form.attributes.reminderByHours}
                                            onChange={e => props.handleChangeAttributes('reminderByHours', e.target.checked)}
                                            color='primary'
                                            label={translate('reminder')}
                                        />
                                        <Tooltip
                                            classes={{
                                                popper: 'menu-popper',
                                                tooltip: 'menu-popper-tooltip'
                                            }}
                                            title={translate('checkRemindMeBefore')}>
                                            <HelpOutlineIcon style={{ fontSize: 15, marginTop: 3, marginLeft: 15, marginRight: 10 }} />
                                        </Tooltip>
                                    </div>
                                </Grid>
                                {form && form.attributes && form.attributes.reminderByHours ? (<Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TextField
                                        id='maxLimit'
                                        label={translate('remindMeBefore') + ' '}
                                        required
                                        placeholder=''
                                        type='number'
                                        value={form.attributes && form.attributes.reminderBeforeHours || ''}
                                        onChange={e => props.handleChangeAttributes('reminderBeforeHours', e.target.value)}
                                        error={
                                            (form.attributes.hoursInterval && form.attributes.reminderBeforeHours && form.attributes.repeat) ? ((form.attributes.hoursInterval - form.attributes.reminderBeforeHours) >= 0 ?
                                                false : true) : false
                                        }
                                        helperText={form && form.attributes && form.attributes.repeat ? <p style={{ fontSize: 9, marginTop: 0, marginBottom: 0 }}>{translate('enterPeriodValueHour')}</p> : ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position='start'
                                                    disableTypography
                                                >
                                                    {'(hours)'}
                                                </InputAdornment>
                                            )
                                        }}
                                        variant='outlined'
                                        margin='dense'
                                        fullWidth
                                    />
                                </Grid>) : null}
                            </Grid>
                        </Collapse>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid sm={4} xs={12} >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <strong className='alt-title'>{translate('date')}</strong>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={form && form.attributes && form.attributes.byDays === true}
                                                onChange={e => props.handleChangeAttributes('byDays', true, e.target.checked)}
                                                name='date'
                                            />
                                        }
                                        label={
                                            form && form.attributes && form.attributes.byDays === true
                                                ? translate('enable')
                                                : translate('disable')
                                        }
                                        labelPlacement='start'
                                        classes={{ label: 'color-inherit' }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={form && form.attributes && form.attributes.byDays === true} >
                            <Grid container spacing={1}>

                                <Grid item xs={12} sm={6} md={4} lg={3}>

                                    <CustomDatePicker
                                        format="dd/MM/yyyy"
                                        margin="dense"
                                        label={translate(form && form.attributes && form.attributes.repeat ? 'startDate' : 'targetValue')}
                                        variant="outlined"
                                        fullWidth
                                        // minDate={new Date()}
                                        value={
                                            form?.attributes?.date
                                                ?
                                                `${form.attributes.date}`
                                                : null
                                        }
                                        onChange={e => props.handleChangeAttributes('date', e)}
                                    />
                                </Grid>



                                {form && form.attributes && form.attributes.repeat ? <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TextField
                                        id='daysInterval'
                                        type='number'
                                        margin='dense'
                                        label={translate('maintenancePeriod') + ' (days)'}
                                        variant='outlined'
                                        fullWidth
                                        value={form && form.attributes && form.attributes.daysInterval || ''}
                                        onChange={e => props.handleChangeAttributes('daysInterval', e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position='start'
                                                    disableTypography
                                                >
                                                    {'(days)'}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid> : null}
                                <Grid item style={{ marginLeft: 5 }}>
                                    <div style={{ paddingTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                                        <Checkbox
                                            checked={form?.attributes?.reminderByDays || false}
                                            onChange={e => props.handleChangeAttributes('reminderByDays', e.target.checked)}
                                            color='primary'
                                            label={translate('reminder')}
                                        />
                                        <Tooltip
                                            classes={{
                                                popper: 'menu-popper',
                                                tooltip: 'menu-popper-tooltip'
                                            }}
                                            title={translate('checkRemindMeBefore')}>
                                            <HelpOutlineIcon style={{ fontSize: 15, marginTop: 3, marginLeft: 15, marginRight: 10 }} />
                                        </Tooltip>
                                    </div>
                                </Grid>
                                {form && form.attributes && form.attributes.reminderByDays ?
                                    (<Grid item xs={12} sm={6} md={4} lg={3}>
                                        <TextField
                                            id='maxLimit'
                                            label={translate('remindMeBefore') + ' '}
                                            required
                                            placeholder=''
                                            type='number'
                                            value={form && form.attributes && form.attributes && form.attributes.reminderBeforeDays || ''}
                                            onChange={e => props.handleChangeAttributes('reminderBeforeDays', e.target.value)}
                                            error={
                                                (form && form.attributes && form.attributes.daysInterval && form.attributes.reminderBeforeDays && form.attributes.repeat) ? ((form && form.attributes && form.attributes.daysInterval - form.attributes.reminderBeforeDays) >= 0 ?
                                                    false : true) : false
                                            }
                                            helperText={form && form.attributes && form.attributes.repeat ? <p style={{ fontSize: 9, marginTop: 0, marginBottom: 0 }}>{translate('enterPeriodValueDay')}</p> : ''}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment
                                                        position='start'
                                                        disableTypography
                                                    >
                                                        {'(days)'}
                                                    </InputAdornment>
                                                )
                                            }}
                                            variant='outlined'
                                            margin='dense'
                                            fullWidth
                                        />
                                    </Grid>)
                                    : null}
                            </Grid>
                        </Collapse>
                    </Grid>
                    {/* average daily mileage */}
                    {/* <Grid item className={form.attributes && form.attributes.avergeDailyMileage ? 'opened ' : ''} xs={12}>
                        <div style={{ display: 'flex' }}><h4 style={{ margin: 0, paddingTop: 8 }}>{translate('averageMileage')}</h4>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={form && form.attributes && form.attributes.avergeDailyMileage === true}
                                        onChange={e => props.handleChangeAttributes('avergeDailyMileage', e.target.checked)}
                                        name='avergeDailyMileage'
                                    />
                                }
                                label={
                                    form && form.attributes && form.attributes.avergeDailyMileage === true
                                        ? translate('enable')
                                        : translate('disable')
                                }
                                labelPlacement='start'
                                classes={{ label: 'color-inherit' }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={form && form.attributes && form.attributes.avergeDailyMileage === true} >
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TextField
                                        id='start'
                                        type='number'
                                        margin='dense'
                                        label={translate('averageDailyMileage')}
                                        variant='outlined'
                                        fullWidth
                                        value={form && form.attributes && form.attributes.avergeMileage || ''}
                                        onChange={e => props.handleChangeAttributes('avergeMileage', e.target.value)}
                                        InputProps={{
                                            readOnly: form && form.attributes && form.attributes.repeat ? true : false,
                                            endAdornment: (
                                                <InputAdornment
                                                    position='start'
                                                    disableTypography
                                                >
                                                    {'(km)'}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>

                                {form.attributes && form.attributes.repeat ?
                                    (<Grid item xs={12} sm={6} md={4} lg={3}>
                                        <TextField
                                            id='start'
                                            type='period'
                                            margin='dense'
                                            label={translate('period')}
                                            variant='outlined'
                                            fullWidth
                                            value={form && form.attributes && form.attributes.period || ''}
                                            onChange={e => props.handleChangeAttributes('period', e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment
                                                        position='start'
                                                        disableTypography
                                                    >
                                                        {'(km)'}
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>) : null}
                                <Grid item style={{ marginLeft: 5 }}>
                                    <div style={{ paddingTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                                        <Checkbox
                                            checked={form && form.attributes && form.attributes.reminderByAverage}
                                            onChange={e => props.handleChangeAttributes('reminderByAverage', e.target.checked)}
                                            color='primary'
                                            label={translate('reminder')}
                                        />
                                        <Tooltip
                                            classes={{
                                                popper: 'menu-popper',
                                                tooltip: 'menu-popper-tooltip'
                                            }}
                                            title={translate('checkRemindMeBefore')}>
                                            <HelpOutlineIcon style={{ fontSize: 15, marginTop: 3, marginLeft: 15, marginRight: 10 }} />
                                        </Tooltip>
                                    </div>
                                </Grid>
                                {form && form.attributes && form.attributes.reminderByAverage ? (<>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <TextField
                                            id='remindMeBefore'
                                            label={translate('remindMeBefore') + ' '}
                                            // required
                                            placeholder=''
                                            type='number'
                                            value={form?.attributes?.reminderBeforeAverage || ''}
                                            onChange={e => props.handleChangeAttributes('reminderBeforeAverage', e.target.value)}
                                            // error={
                                            //   // (form.attributes.mileageInterval) < (form.attributes.reminderBeforeMileage)
                                            //   //  ? true
                                            //   //   : false
                                            //   (form.attributes.averageMileageInterval&&form.attributes.reminderBeforeAverageMileage&&form.attributes.repeat) ? ((form.attributes.averageMileageInterval - form.attributes.reminderBeforeAverageMileage) >= 0 ?
                                            //   false : true) : false
                                            //   }
                                            // helperText={form&&form.attributes&&form.attributes.repeat?<p style={{fontSize: 9, marginTop: 0,marginBottom: 0}}>{translate('enterPeriodValueKm')}</p>:''}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment
                                                        position='start'
                                                        disableTypography
                                                    >
                                                        {'(days)'}
                                                    </InputAdornment>
                                                )
                                            }}
                                            variant='outlined'
                                            margin='dense'
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <CustomDatePicker
                                            format="dd/MM/yyyy"
                                            margin="dense"
                                            label={translate('averageStartDate')}
                                            variant="outlined"
                                            fullWidth
                                            // minDate={new Date()}
                                            value={
                                                form.attributes.averageStartDate
                                                    ?
                                                    `${form.attributes.averageStartDate}`
                                                    : null
                                            }
                                            onChange={e => props.handleChangeAttributes('averageStartDate', e)}
                                        />
                                    </Grid>
                                </>) : null}
                            </Grid>
                        </Collapse>
                    </Grid> */}

                </>}
                <Grid item xs={12}>
                    <SettingForm form={form.attributes} mode={props.mode} saveToParent={props.saveToParent} dispatch={props.dispatch} translate={translate} isReminder />
                </Grid>
                <div style={{ marginLeft: "auto" }}>
                    <Button
                        size='small'
                        onClick={props.submitForm}
                        disabled={props.isVisible}
                    >
                        {props.isEditForm?"Update":translate('sharedCreate')}
                    </Button>
                </div>
            </Grid>

        </div>
    )
}
