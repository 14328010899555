// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.res-table td {
  padding: 4px;
}
.res-table .res-td-action {
  white-space: nowrap;
}
@media (max-width: 959px) {
  .res-table {
    display: block !important;
  }
  .res-table tr, .res-table td, .res-table tbody {
    display: block;
    height: auto;
    box-sizing: border-box;
  }
  .res-table tr:before, .res-table tr:after {
    content: "";
    display: block;
    clear: both;
  }
  .res-table .res-th {
    background: #f1f1f1;
    padding: 12px;
    margin-top: 10px;
    font-weight: bold;
    border-radius: 6px 6px 0 0;
  }
  .res-table .res-td {
    float: left;
    width: 50%;
  }
  .res-table .res-td-action {
    float: left;
    width: 100%;
    text-align: center;
  }
  .res-table .res-td-empty {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Units/Components/ecoTable.scss"],"names":[],"mappings":"AACI;EACI,YAAA;AAAR;AAEI;EACI,mBAAA;AAAR;AAEI;EAPJ;IAQQ,yBAAA;EACN;EAAM;IACI,cAAA;IACA,YAAA;IACA,sBAAA;EAEV;EACU;IACI,WAAA;IACA,cAAA;IACA,WAAA;EACd;EAEM;IACI,mBAAA;IACA,aAAA;IACA,gBAAA;IACA,iBAAA;IACA,0BAAA;EAAV;EAEM;IACI,WAAA;IACA,UAAA;EAAV;EAEM;IACI,WAAA;IACA,WAAA;IACA,kBAAA;EAAV;EAEM;IACI,aAAA;EAAV;AACF","sourcesContent":[".res-table {\n    td {\n        padding: 4px;\n    }\n    .res-td-action {\n        white-space: nowrap;\n    }\n    @media (max-width: 959px) {\n        display: block !important; \n        tr, td, tbody {\n            display: block;\n            height: auto;\n            box-sizing: border-box;\n        }\n        tr {\n            &:before, &:after {\n                content: '';\n                display: block;\n                clear: both;\n            }\n        }\n        .res-th {\n            background: #f1f1f1;\n            padding: 12px;\n            margin-top: 10px;\n            font-weight: bold;\n            border-radius: 6px 6px 0 0;\n        }\n        .res-td {\n            float: left;\n            width: 50%;\n        }\n        .res-td-action {\n            float: left;\n            width: 100%;\n            text-align: center;\n        }\n        .res-td-empty {\n            display: none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
