// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vis-timeline {
  visibility: visible !important;
  background: #fff;
}

.vis-label {
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}
.vis-label:before {
  content: "\\e835";
  display: inline-block;
  vertical-align: middle;
  font-family: "Material Icons";
  font-size: 24px;
}
.vis-label.selected-group {
  background: rgba(255, 236, 167, 0.5215686275);
}
.vis-label.selected-group:before {
  content: "\\e834";
}

.vis-label .sliderContainer {
  display: none;
}

.trackplaybackcontrol {
  padding: 0 16px;
  background: #fff;
  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.09);
  z-index: 999;
  position: relative;
}
.trackplaybackcontrol .track-control-right {
  margin-left: auto;
}
.trackplaybackcontrol .speed-controls {
  display: flex;
  align-items: center;
  border-right: 1px solid #bfbfbf;
  padding-right: 10px;
}
.trackplaybackcontrol .playback-controls {
  padding-left: 10px;
}

.leaflet-control-playback {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Timemachine/Style.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,gBAAA;AACJ;;AAEA;EAEI,eAAA;EAEA,iBAAA;EACA,kBAAA;AADJ;AAEI;EACI,gBAAA;EACA,qBAAA;EACA,sBAAA;EACA,6BAAA;EACA,eAAA;AAAR;AAGI;EACI,6CAAA;AADR;AAEQ;EACI,gBAAA;AAAZ;;AAIA;EAGI,aAAA;AAHJ;;AAMA;EACI,eAAA;EACA,gBAAA;EACA,0CAAA;EACA,YAAA;EACA,kBAAA;AAHJ;AAII;EACE,iBAAA;AAFN;AAII;EACI,aAAA;EACA,mBAAA;EACA,+BAAA;EACA,mBAAA;AAFR;AAII;EACI,kBAAA;AAFR;;AAMA;EACI,aAAA;AAHJ","sourcesContent":[".vis-timeline {\n    visibility: visible !important;\n    background: #fff;\n}\n\n.vis-label {\n\n    cursor: pointer;\n\n    padding-left: 5px;\n    padding-right: 5px;\n    &:before{\n        content: \"\\e835\";\n        display: inline-block;\n        vertical-align: middle;\n        font-family: 'Material Icons';\n        font-size: 24px;\n   \n    }\n    &.selected-group {\n        background: #ffeca785;\n        &:before {\n            content: \"\\e834\";\n        }\n    } \n}\n.vis-label\n\n.sliderContainer {\n    display: none;\n}\n\n.trackplaybackcontrol {\n    padding: 0 16px;\n    background: #fff;\n    box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.09);\n    z-index: 999;\n    position: relative;\n    .track-control-right {\n      margin-left: auto;\n    }\n    .speed-controls {\n        display: flex;\n        align-items: center;\n        border-right: 1px solid #bfbfbf;\n        padding-right: 10px;\n    }\n    .playback-controls {\n        padding-left: 10px;\n    }\n}\n\n.leaflet-control-playback {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
