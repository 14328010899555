// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    filter: invert(0.8) sepia(10%) saturate(10%) hue-rotate(240deg);
}`, "",{"version":3,"sources":["webpack://./src/Components/Notifications/Wizard/Screen2/dateTime.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,+DAA+D;AACnE","sourcesContent":["::-webkit-calendar-picker-indicator {\n    cursor: pointer;\n    border-radius: 4px;\n    filter: invert(0.8) sepia(10%) saturate(10%) hue-rotate(240deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
