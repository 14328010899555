import React, { Component } from "react";
import { connect } from "react-redux";
import { EditItem } from "./EditItem";
import { WaslShortList } from "./WaslShortList";
// import { Trans as Translate} from 'react-i18next';
import Loader from "../../Layout/Loader";
import isEqual from "react-fast-compare";
import {
  // removedvehicle,
  updatevehicle,
  addvehicle,
} from "../../Actions/Vehicles";
import AddVehicle from "./addVehicle";

import axios from "axios";
import { checkPrivileges, errorHandler } from "../../Helpers";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import instance from "../../axios";
import { notifyError, notifySuccess } from "../../Utils/CustomNotifcations";

const vehicleDataFormat = (data, itemId, user) => ({
  id: itemId ? itemId : 0,
  attributes: { ...data.attributes,
   capacity: data.attributes.capacity || 0,
   },
  deviceId: data.deviceId || 0,
  // totalAvailablekm: data.totalAvailablekm || 0,
  totalAvailablekm: data.totalAvailablekm
    ? parseFloat(data.totalAvailablekm)
    : 200,
  label: data.label,
  modelId: data?.modelId?.id || 0,
  fuelEfficiency: data.fuelEfficiency ? parseFloat(data.fuelEfficiency) : 0,
  odometer: data.odometer ? parseFloat(data.odometer) : 0,
  entitlement: data.entitlement ? parseFloat(data.entitlement) : 0,
  garage: data.garage,
  // vehicleType: data.vehicleType,
  vin: data.vin,
  vehicleLicensePlate: data.vehicleLicensePlate,
  leasePeriod: data && data.leasePeriod ? parseInt(data.leasePeriod) : 0,
  vehicleLisencePlateArabic: data.vehicleLisencePlateArabic,
  expirationTime: data.expirationTime,
  monthlyRate: data && data.monthlyRate ? parseFloat(data.monthlyRate) : 0,
  purchasePrice:
    data && data.purchasePrice ? parseFloat(data.purchasePrice) : 0,
  purchaseDate: data?.purchaseDate || "",
  deliveryDate: data?.deliveryDate || "",
  leaseEndDate: data?.leaseEndDate || "",
  leaseStartDate: data?.leaseStartDate || "",
  areaId: data && data.areaId ? data.areaId.id : 0,
  category: data && data.category ? data.category : "",
  onLease: data && data.onLease ? data.onLease : false,
  // parentId:data && data.parentId ? data.parentId.id : user&&user.id?user.id : 0
  parentId:
    data && data.parentId ? data.parentId.id : user && user.id ? user.id : 0,
  carbonFootPrint: data && data.carbonFootPrint ? data.carbonFootPrint : 0,
});

const trasmissionList = [{ name: "Manual" }, { name: "Automatic" }];
const fuel_typeList = [
  { name: "Gasoline", key: "Gasoline" },
  { name: "Diesel", key: "Diesel" },
  { name: "Ethanol", key: "Ethanol" },
  { name: "Hybrid", key: "Hybrid" },
  { name: "Electric", key: "Electric" },
  { name: "Biodiesel", key: "Biodiesel" },
  { name: "Compressed Natural Gas (CNG)", key: "Compressed Natural Gas (CNG)" },
  {
    name: "Liquefied Petroleum Gas (LPG)",
    key: "Liquefied Petroleum Gas (LPG)",
  },
  { name: "Hydrogen", key: "Hydrogen" },
  {
    name: "Mild Hybrid Electric Vehicle (HEV)",
    key: "Mild Hybrid Electric Vehicle (HEV)",
  },
  {
    name: "Moderate Hybrid Electric Vehicle (HEV)",
    key: "Moderate Hybrid Electric Vehicle (HEV)",
  },
  {
    name: "Plug-in Hybrid Electric Vehicle (PHEV)",
    key: "Plug-in Hybrid Electric Vehicle (PHEV)",
  },
];
const formDefault = {
  label: "",
  garage: "",
  model: "",
  // vehicleType: "",
  parentId: "",
  expirationTime: "",
  vehicleLicensePlate: "",
  vehicleLisencePlateArabic: "",
  leasePeriod: "",
  onLease: false,
  fuelEfficiency: "",
  category: "",
  attributes: { typeName: ["Maintenance"] }, //vehicleLicensePlate: ''
  carbonFootPrint: 0,
  odometer: 0,
  entitlement: 0,
  totalAvailablekm: 200,
};
let fuel_type = "";
class vehicleModal extends Component {
  constructor() {
    super();
    this.state = {
      trasmissionList: trasmissionList,
      fuel_typeList: fuel_typeList,
      selectedVehicle: "",
      addOption: false,
      editOption: false,
      isVisableTrackerModal: false,
      isVisableUserBtn: false,
      vehicleId: "",
      vehicleTracker: "",
      form: { ...formDefault },
      vehicleStatus: "",
      allMakes: [],
      selectedMakeTypes: [],
      minPercentage: 1,
      maxPercentage: 100,
      categoriesList: [],
      vehicleLicensePlateError: false,
      yearError:false,
      isVin:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.ac_submit = this.ac_submit.bind(this);
    this.onCloseSubModal = this.onCloseSubModal.bind(this);
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
  }
  componentWillMount() {
    this.dataFormatting();
    this.fetchCategories();

    this.fetchMake();
  }

  fetchCategories = () => {
    //(/api/devices/categories
    instance(`/api/vehicles/categories`)
      .then((response) => {
        this.setState({ categoriesList: response?.categories });
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };
  dataFormatting = async () => {
    let findAreaId =
      this.props.areaList &&
      this.props.areaList.find(
        (item) =>
          item.key ===
          (this.props.selecteditem && this.props.selecteditem.areaId)
      );
    let dt = JSON.parse(JSON.stringify(this.props.selecteditem));
    const device = this.props.devices.find((d) => d.id === dt.deviceId);
    if (this.props.showItemDetail && this.props.trackersApiResponce) {
      let formData = this.setFormData(dt);

      let attributes = dt.attributes;
      let trackers = this.props.trackerData;
      formData = { ...formData, attributes, trackers };
      this.setState({
        selectedVehicle: formData,
        vehicleId: dt.id,
        // vehicleTracker: this.props.trackerData
        vehicleTracker: this.props.trackerData
          ? this.props.trackerData
          : device
          ? { value: device.id, label: device.name, uniqueId: device.uniqueId }
          : "",
      });
    } else {
      if (
        this.props.activeOperation === "edit" &&
        this.props.trackersApiResponce
      ) {
        if (
          !this.state.editOption ||
          !isEqual(this.state.selectedVehicle, dt)
        ) {
          this.setState({
            form: {
              ...dt,
              parentId: this.props.selectedUser,
              areaId: findAreaId,
            },
            vehicleId: dt.id,
            editOption: true,
            addOption: false,
            selectedVehicle: dt,
            // vehicleTracker: this.props.trackerData
            vehicleTracker: this.props.trackerData
              ? this.props.trackerData
              : device
              ? {
                  value: device.id,
                  label: device.name,
                  uniqueId: device.uniqueId,
                }
              : "",
          });
        }
      }
      if (this.props.activeOperation === "add" && !this.state.addOption) {
        this.setState({
          vehicleTracker: "",
          vehicleId: "",
          selectedVehicle: "",
          addOption: true,
          editOption: false,
          waslLinked: true,
          form: { ...formDefault, attributes: {} },
        });
      }
    }
  };

  setFormData = (data) => {
    let formData = {
      label: data.label,
      garage: data.garage,
      model: data.model,
      // vehicleType: data.vehicleType,
      expirationTime: data.expirationTime,
      vehicleLicensePlate: data.vehicleLicensePlate || "",
      vehicleLisencePlateArabic: data.vehicleLisencePlateArabic || "",
      leasePeriod: data.leasePeriod || "",
      vin: data.attributes.vin || "",
      chassisNumber: data.attributes.chassisNumber || "",
      permittedSpeed: data.attributes.permittedSpeed || "",
    };
    return formData;
  };
  ac_submit() {
    let { form } = this.state;
    const obj = vehicleDataFormat(form, 0, this.props.logInUsers);
    this.setState({
      isVisableUserBtn: false,
    });

    // fetch('/api/vehicles', {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/vehicles`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((data) => {
        if (data.status === "success") {
          this.props.dispatch(addvehicle(data.data));
          this.props.onCloseModal();
          this.setState({
            addOption: false,
            selectedVehicle: "",
            form: formDefault,
          });

          this.props.dispatch(notifySuccess("vehicleIsAdded"));
        } else {
          if (
            data &&
            data.message &&
            data.message.includes("vehicles_deviceid")
          ) {
            this.props.dispatch(notifyError("alreadyAttachedWithVehicle"));
          } else {
            this.props.dispatch(
              notifyError("vehicleLicensePlateAllreadyInUsed")
            );
          }
        }
      })
      //   } else {
      //     throw response
      //   }
      // })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
    // .catch(e => {
    //   this.props.dispatch(
    //     Notifications.error({
    //       message: this.props.translate('vehicleFormateIssue'),
    //       autoDismiss: 10
    //     })
    //   )
    // })
  }
  uc_submit = (disableWaslCall) => {
    let {
      form,
      selectedVehicle: { id },
    } = this.state;
    const { waslEnabled } = this.props?.serversettings?.attributes || {};
    if (waslEnabled) {
      this.setState({
        waslStatus: (
          <span className="loading-status-text">
            {" "}
            <LoadingIndicator /> Processing
          </span>
        ),
      });
    }
    this.setState({
      vehicleStatus: (
        <span className="loading-status-text">
          <LoadingIndicator /> Processing
        </span>
      ),
    });
    const obj = vehicleDataFormat(form, id, this.props.logInUsers);

    if (!obj.attributes.WASLKey) {
      delete obj.attributes.WASLKey;
    }
    if (!obj.vin) {
      delete obj.vin;
    }
    this.setState({
      isVisableUserBtn: false,
    });
    const data = {
      url: id ? `/api/vehicles/${id}` : `/api/vehicles/`,
      method: id ? `PUT` : `POST`,
      successMsg: id ? `vehicleIsUpdated` : `vehicleIsAdded`,
    };

    instance({
      url: data.url,
      method: data.method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((d) => {
        console.log("D=====", d);
        // if (d.status === 200 && d.data.status === 'success') {
        this.setState({ form: { ...formDefault, attributes: {} } });
        this.props.dispatch(id ? updatevehicle(d) : addvehicle(d));
        if (!disableWaslCall && waslEnabled && this.state.waslLinked) {
          this.submitWasl(obj, d);
        } else {
          this.handleAfterResponse();
        }
        this.props.dispatch(notifySuccess(data.successMsg));
        this.props.fetchData(this.props?.logInUsers?.id, 1, 50, true);
        this.onCloseSubModal();
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
        console.log("error======", error);
      });
  };

  handleAfterResponse = () => {
    this.setState({
      vehicleStatus: (
        <span className="loading-status-text">
          <DoneAllIcon className="status-icon" /> Done
        </span>
      ),
    });
  };

  onChangedDevice = (item) => {
    if (item) {
      const device = this.props.devices.find((d) => d.id === item.id);

      this.setState(
        {
          vehicleTracker: {
            value: device.id,
            label: device.name,
            uniqueId: device.uniqueId,
          },
          form: {
            ...this.state.form,
            deviceId: item.value,
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          vehicleTracker: "",
          form: {
            ...this.state.form,
            deviceId: "",
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };

  enableAssignTrackerModal = () => {
    this.setState({
      isVisableTrackerModal: true,
    });
  };
  onCloseModal = () => {
    this.setState({
      isVisableTrackerModal: false,
    });
  };
  onCloseSubModal() {
    this.setState({
      addOption: false,
      editOption: false,
    });
    this.props.onCloseModal();
  }

  handleChangeLicenseExpDate = (name) => (event) => {
    // if (name === 'expirationTime') {
    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: event ? event.toISOString() : null,
        },
      },
      () => this.checkRequiredFields()
    );
    // }
    // else if(name === 'purchaseDate' || name === 'deliveryDate' || name === 'leaseEndDate'){
    //   this.setState(
    //     {
    //       form: {
    //         ...this.state.form,
    //         attributes:{
    //           ...this.state.form.attributes,
    //           [name]: event.toISOString()
    //         }
    //       }
    //     },
    //     () => this.checkRequiredFields()
    //   )
    // }
  };
  setExpirationTime = (e) => {
    e.stopPropagation();
    this.setState(
      {
        form: {
          ...this.state.form,
          expirationTime: null,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  handleChange = async (name, event) => {
    const { target } = event;
    if (target && target.type === "checkbox") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.checked,
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (name === "fuel_type") {
      // fuel_type = event.name;
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: { ...this.state.form.attributes, [name]: event.id },
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (name === "category") {
      let value = target.value;
      let val = await this.props.getFuelEfficiency(value);
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value,
            fuelEfficiency: val || 0,
          },
        },
        () => {
          this.checkRequiredFields();
        }
        // () =>{ this.checkRequiredFields(); this.getFuelEfficiency(value) }
      );
    } else if (name === "areaId") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event,
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (name === "typeName") {
      this.setState({
        form: {
          ...this.state.form,
          attributes: {
            ...this.state.form.attributes,
            typeName: event,
          },
        },
      });
    } else if (name === "parentId") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event,
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (name === "entitlement") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.value,
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      let value = target.value.toString();
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value,
          },
          vinError:
  name === "vin" &&
  (value.trim().length !== 17 || !/^[A-Za-z0-9]+$/.test(value))
    ? "VIN must be exactly 17 characters long and contain only alphanumeric characters."
    : "",
    isVin: name === "vin" && value.trim().length === 17 && /^[A-Za-z0-9]+$/.test(value),
        },
        () => this.checkRequiredFields()
      );
    }
  };
  handleChangeForAttributesValues = async (name, event, link) => {
    let { attributes } = this.state.form;
    if (name === "year") {
      const year = event.target.value;
        this.setState({
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: year,
            },
          },
        },()=>{
   const currentYear = new Date().getFullYear().toString(); // Convert current year to string
        const parsedYear = parseInt(year, 10); // Convert input year to number
        const parsedMinYear = 1950; // Minimum valid year
        if (parsedYear >= parsedMinYear && parsedYear <= parseInt(currentYear, 10)) {
          this.setState({
            yearError: false,
          },()=>{
            this.checkRequiredFields()
          });
        } else {
          this.setState({
            yearError: true,
          },()=>{
            this.checkRequiredFields()
          });
        }
          this.checkRequiredFields()
        });
    }
    if (name === "type") {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: event.target.checked,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (name === "make") {
      const z = await this.fetchVehicleMake(event.label);
      this.setState(
        {
          selectedMakeTypes: z,
          form: {
            ...this.state.form,
            [name]: event,
            modelId: {},
            attributes: { ...this.state.form.attributes, make: event.label },
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (name === "modelId") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event,
            attributes: {
              ...this.state.form.attributes,
              model: event.modelName,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    }
    //  else if (
    //   name === "insurancePercentage" ||
    //   name === "bankPercentage" ||
    //   name === "resalePercentage" ||
    //   name === "replacement" ||
    //   name === "zakatPercentage"
    // ) {
    //   if (event.target.value > 100) {
    //     event.target.value = 100;
    //   }
    //   if (event.target.value < 0) {
    //     event.target.value = 0;
    //   }
    //   attributes[name] = event.target.value;
    //   this.setState({
    //     form: {
    //       ...this.state.form,
    //       attributes: { ...attributes },
    //     },
    //   });
    // }
    else {
      const target = name === "trasmissionType" ? event : event && event.target;
      let value =
        name === "trasmissionType" ? event : target && target.value.toString();
      if (value) {
        if (
          name === "insurancePercentage" ||
          name === "bankPercentage" ||
          name === "Maintenance" ||
          name === "zakatPercentage" ||
          name === "resalePercentage" ||
          name === "replacement" ||
          name === "otherCost" ||
          name === "fines" ||
          name === "brandingCost" ||
          name === "telematicsCost" ||
          name === "depreciationCost" ||
          name === "driverCost" ||
          name === "standByVehicle"
        ) {
          if (
            name === "insurancePercentage" ||
            name === "bankPercentage" ||
            name === "resalePercentage" ||
            name === "replacement" ||
            name === "zakatPercentage"
          ) {
            if (value > 100) {
              value = 100;
            }
            if (value < 0) {
              value = 0;
            }
          }
          attributes[name] = parseFloat(value);
        } else attributes[name] = value;
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: { ...attributes },
            },
          },
          () => {
            if (this.state.editOption) {
              this.setState({
                isVisableUserBtn: true,
              });
            }
          }
        );
      } else {
        delete attributes[name];
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: { ...attributes },
            },
          },
          () => {
            if (this.state.editOption) {
              this.setState({
                isVisableUserBtn: true,
                waslLinked: false,
              });
            }
          }
        );
      }
    }
  };
  async fetchVehicleMake(selectedMake) {
    return await instance({
      method: "GET",
      url: `/api/models/get?page=1&limit=1000&make=${selectedMake}&search= `,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        let defaultOptions =
          response &&
          response.data &&
          response.data.map((item) => {
            return { ...item, label: item.modelName };
          });
        return defaultOptions;
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }

  checkRequiredFields() {
    let {
      label,
      vehicleLicensePlate,
      deliveryDate,
      leaseEndDate,
      leaseStartDate,
      onLease,
      make,
      modelId,
      vin,
      category,
      purchaseDate,
      totalAvailablekm,
    } = this.state.form;
    let { fuel_type,year } = this.state.form.attributes;
    let value = true;
    if (this.state.editOption) {
      value = !isEqual(this.state.form, this.props.selecteditem);
    }
    let validLicensePlateFormat = /^\d{1,4}[A-Za-z]{3}$/;
    let validLicensePlateFormatArabic =
      /^[\u0621-\u064A]\s[\u0621-\u064A]\s[\u0621-\u064A]\s[\d٠-٩]{4}$/;
    if (
      validLicensePlateFormat.test(vehicleLicensePlate) ||
      validLicensePlateFormatArabic.test(vehicleLicensePlate)
    ) {
      this.setState({
        vehicleLicensePlateError: false,
      });
    } else {
      this.setState({
        vehicleLicensePlateError: true,
      });
    }
    if (onLease) {
      if (
        label &&
        label.trim().length &&
        vehicleLicensePlate &&
        vehicleLicensePlate.trim().length &&
        (validLicensePlateFormat.test(vehicleLicensePlate) ||
          validLicensePlateFormatArabic.test(vehicleLicensePlate)) &&
        deliveryDate &&
        purchaseDate &&
        leaseEndDate &&
        leaseEndDate > leaseStartDate &&
        value &&
        // make &&
        // modelId &&
        vin &&this.state.isVin&&
        year&&!this.state.yearError&&
        category &&
        fuel_type &&
        totalAvailablekm &&
        totalAvailablekm > 0

      ) {
        this.setState({
          isVisableUserBtn: true,
        });
      } else {
        this.setState({
          isVisableUserBtn: false,
        });
      }
    } else {
      if (
        label &&
        label.trim().length &&
        vehicleLicensePlate &&
        vehicleLicensePlate.trim().length &&
        (validLicensePlateFormat.test(vehicleLicensePlate) ||
          validLicensePlateFormatArabic.test(vehicleLicensePlate)) &&
        // fuel_type !== '' &&
        // fuel_type !== undefined &&
        // deliveryDate &&
        // leaseEndDate&&
        // leaseEndDate > leaseStartDate &&
        value &&
        // make &&
        // modelId &&
        vin &&this.state.isVin&&
        year&&!this.state.yearError&&
        category &&
        fuel_type &&
        totalAvailablekm &&
        totalAvailablekm > 0
      ) {
        this.setState({
          isVisableUserBtn: true,
        });
      } else {
        this.setState({
          isVisableUserBtn: false,
        });
      }
    }
  }

  //   checkRequiredFields() {
  //     let { label, vehicleLicensePlate , deliveryDate, leaseEndDate, leaseStartDate, onLease} = this.state.form;
  //     let value = true;
  //     if (this.state.editOption) {
  //       value = !isEqual(this.state.form, this.props.selecteditem);
  //     }
  //     let validLicensePlateFormat = /^\d{4}[A-Za-z]{3}$/;
  //     let validLicensePlateFormatArabic = /^[\u0621-\u064A]\s[\u0621-\u064A]\s[\u0621-\u064A]\s[\d٠-٩]{4}$/;
  //     if(validLicensePlateFormat.test(vehicleLicensePlate) || validLicensePlateFormatArabic.test(vehicleLicensePlate)) {
  //       this.setState({
  //         vehicleLicensePlateError: false
  //       })
  //     } else {
  //       this.setState({
  //         vehicleLicensePlateError: true
  //       })
  //     }
  //     if(onLease){
  //       if (
  //         label &&
  //         label.trim().length &&
  //         vehicleLicensePlate &&
  //         vehicleLicensePlate.trim().length &&
  //         validLicensePlateFormat.test(vehicleLicensePlate) || validLicensePlateFormatArabic.test(vehicleLicensePlate) &&
  //         deliveryDate &&
  //         leaseEndDate&&
  //         leaseEndDate > leaseStartDate &&
  //         value
  //       ) {
  //         this.setState({
  //           isVisableUserBtn: true,
  //         });
  //       }
  //       else {
  //         this.setState({
  //           isVisableUserBtn: false,
  //         });
  //       }
  //     }
  // else{
  //   if (
  //     label &&
  //     label.trim().length &&
  //     vehicleLicensePlate &&
  //     vehicleLicensePlate.trim().length &&
  //     validLicensePlateFormat.test(vehicleLicensePlate) || validLicensePlateFormatArabic.test(vehicleLicensePlate) &&
  //     // fuel_type !== '' &&
  //     // fuel_type !== undefined &&
  //     // deliveryDate &&
  //     // leaseEndDate&&
  //     // leaseEndDate > leaseStartDate &&
  //     value
  //   ) {
  //     this.setState({
  //       isVisableUserBtn: true,
  //     });
  //   }
  //   else {
  //     this.setState({
  //       isVisableUserBtn: false,
  //     });
  //   }
  // }

  //   }

  getFuelEfficiency = async (type) => {
    let result = await axios.get(`/api/vehicles/fuelefficiency?type=${type}`);
    const [key] = Object.keys(result.data);
    const value = result.data[key];
    this.setState({
      form: {
        ...this.state.form,
        fuelEfficiency: value || 0,
      },
    });
    return value;
  };

  submitWasl = (vehicle) => {
    const { waslCompanyRefKey } = this.props?.serversettings?.attributes || {};
    if (waslCompanyRefKey) {
      const {
        number,
        leftLetter,
        rightLetter,
        plateType,
        middleLetter,
        sequenceNumber,
      } = vehicle.attributes;
      const { uniqueId } = this.state.vehicleTracker;
      if (
        number &&
        leftLetter &&
        rightLetter &&
        plateType &&
        middleLetter &&
        sequenceNumber &&
        uniqueId
      ) {
        instance
          .post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, {
            hook: "registerVehicle",
            account_id: this.props.serversettings.id,
            data: {
              uniqueId,
              sequenceNumber,
              activity: "DEFAULT",
              attributes: {
                rightLetter,
                number,
                leftLetter,
                middleLetter,
                plateType,
              },
            },
            hook_prefix: waslCompanyRefKey,
          })
          .then((response) => {
            // if(response.status === 200 && response.data && response.data.success) {
            this.setState({
              waslStatus: (
                <span className="loading-status-text">
                  <DoneAllIcon className="status-icon" /> Done
                </span>
              ),
            });
            if (response.result && response.result.referenceKey) {
              this.handleChangeForAttributesValues("WASLKey", {
                target: { value: response.result.referenceKey },
              });
              this.uc_submit(true);
            }
            // }
          })
          .catch((error) => {
            let res = error?.response?.data || {};
            let check = res && res.data && res.data.result;
            if (check && check.referenceKey) {
              this.handleChangeForAttributesValues("WASLKey", {
                target: { value: check.referenceKey },
              });
              this.uc_submit(true);
            }

            this.setState({
              waslStatus: (
                <span className="loading-status-text">
                  <ErrorOutlineIcon className="status-icon" /> invalid form
                </span>
              ),
            });
            this.handleAfterResponse();
          });
      } else {
        this.setState({
          waslStatus: (
            <span className="loading-status-text">
              <ErrorOutlineIcon className="status-icon" /> incomplete form
            </span>
          ),
        });
        this.handleAfterResponse();
      }
    } else {
      this.handleAfterResponse();
    }
  };

  fetchMake = () => {
    instance({
      method: "GET",
      url: `/api/models/make`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        let defaultOptions =
          response &&
          response.categories &&
          response.categories.map((item) => {
            return { id: item, label: item };
          });
        this.setState({ allMakes: defaultOptions });
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  render() {
    let { selecteditem } = this.props;

    if (
      selecteditem.id !== this.state.vehicleId &&
      this.props.trackersApiResponce
    ) {
      this.dataFormatting();
    }
    return (
      <div style={{ height: "100%", position: "relative", zIndex: 9999 }}>
        {this.state.addOption && (
          <>
            {/* Add Vehicle */}
            <AddVehicle
              translate={this.props.translate}
              {...this.state}
              {...this.props}
              users={this.props.users}
              dispatch={this.props.dispatch}
              logInUsers={this.props.logInUsers}
              onClose={this.onCloseSubModal}
              formSubmit={this.uc_submit}
              changeAttribute={this.changeAttribute}
              handleChange={this.handleChange}
              handleChangeForAttributesValues={
                this.handleChangeForAttributesValues
              }
              handleChangeLicenseExpDate={this.handleChangeLicenseExpDate}
              setExpirationTime={this.setExpirationTime}
              onChangedDevice={this.onChangedDevice}
              form={this.state.form}
              buttonText={"sharedCreate"}
              isHaveAccess={"vehicleCreate"}
              areaList={checkPrivileges("area") ? this.props.areaList : []}
            />
          </>
        )}
        {this.state.editOption && (
          <AddVehicle
            {...this.props}
            {...this.state}
            users={this.props.users}
            translate={this.props.translate}
            dispatch={this.props.dispatch}
            serversettings={this.props.serversettings}
            logInUsers={this.props.logInUsers}
            onClose={this.onCloseSubModal}
            formSubmit={this.uc_submit}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            handleChangeForAttributesValues={
              this.handleChangeForAttributesValues
            }
            handleChangeLicenseExpDate={this.handleChangeLicenseExpDate}
            setExpirationTime={this.setExpirationTime}
            onChangedDevice={this.onChangedDevice}
            buttonText={"update"}
            isHaveAccess={"vehicleUpdate"}
            areaList={checkPrivileges("area") ? this.props.areaList : []}
          />
        )}
        {this.state.selectedVehicle &&
        this.props.showItemDetail &&
        this.props.trackersApiResponce ? (
          <EditItem
            logInUsers={this.props.logInUsers}
            currentLocation={this.props.currentLocation}
            selectedItem={this.state.selectedVehicle}
            handleClose={this.onCloseSubModal}
            devicesIcons={this.props.devicesIcons}
          />
        ) : !this.state.addOption && !this.state.editOption ? (
          <Loader />
        ) : null}
        {this.props.showWaslShortList && this.props.vehicleHookData ? (
          <WaslShortList
            logInUsers={this.props.logInUsers}
            currentLocation={this.props.currentLocation}
            handleClose={this.onCloseSubModal}
            devicesIcons={this.props.devicesIcons}
            vehicleHookData={this.props.vehicleHookData}
            sequenceNumber={this.props.sequenceNumber}
          />
        ) : null}
      </div>
    );
  }
}

const mapState = (state) => ({
  logInUsers: state.logInUsers,
  serversettings: state.ServerSetting,
  themecolors: state.themeColors,
  users: state.users,
});
const mapStateToProps = connect(mapState);
export const VehicleModal = mapStateToProps(vehicleModal);

const LoadingIndicator = () => {
  return (
    <span className="status-icon">
      <span className="loading-dot" style={{ animationDelay: "0ms" }} />
      <span className="loading-dot" style={{ animationDelay: "160ms" }} />
      <span className="loading-dot" style={{ animationDelay: "320ms" }} />
    </span>
  );
};
