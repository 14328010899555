import React, { Component } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import Route from './Route'
import Monitoring from '../Pages/Monitoring'
import Login from '../Pages/Login'
import { LogOut } from '../Pages/LogOut'
import Loginasuser from '../Pages/LoginAsUser'
import Tracks from '../Pages/Tracks'
import Timemachine from '../Pages/Timemachine'
import Units from '../Pages/Units'
// import Groups from '../Pages/Groups'
import Geofences from '../Pages/Geofences'
import Reports from '../Pages/Reports'
import ReportsHistory from '../Pages/ReportsHistory'
import { ResetPassword } from '../Pages/ResetPassword'
import Users from '../Pages/Users'
import Actionlogs from '../Pages/ActionLogs'
import InvoiceDetail from '../Pages/Invoices/Invoice'
import MaintenanceInvoiceDetail  from '../Pages/Invoices/maintenanceInvoice'
import Invoice from '../Pages/Invoices/index'
import Costing from '../Pages/Costing/index'
import Messages from '../Pages/Messages'
import Drivers from '../Pages/Drivers'
import Trailer from '../Pages/Trailer'
import Trash from '../Pages/Trash'
import Dashboard from '../Pages/MainDashboard'
import MarkerMap from '../Pages/MarkerMap'
import Commands from '../Pages/Commands'
import Maintenance from '../Pages/Maintenance'
import Notification from '../Pages/Notifications'
import Events from '../Pages/Events'
import AccountManagements from '../Pages/AccountManagements'
import NotFound from '../Pages/NotFound'
import '../assets/main.scss'
import Vehicle from '../Pages/Vehicle'
import Garage from '../Pages/Garage'
import Area from '../Pages/AreaModule'
import Services from '../Pages/Services'
import Model from '../Pages/Model'
import Jobs from '../Pages/Jobs'
import Parameters from '../Pages/Parameters'
import BuildTheme from '../Pages/BuildTheme'
import DashboardBuilder from '../Pages/DashboardBuilder'
import PremiumDashboard from '../Pages/PremiumDashboard'
// import MaintenanceDashboard from '../Pages/MaintenanceDashboard'
import DeliveryNotes from '../Pages/DeliveryNotes/index'
import DeliverNotesDetail from '../Pages/DeliveryNotes/DeliveryNote'
import MissedOpportunity from '../Pages/MissedOpportunities/index'
import UserLocationShare from '../Pages/UserLocationShare';
import MonitoringShareLocation from '../Pages/MonitoringShareLocation';


import { checkPrivileges, errorHandler } from "../Helpers";

export default class Routes extends Component {
  
  checkBackToAdmin = () => {
    return localStorage.getItem('backtoAdmin');
  }
  render () {
    const backtoAdmin = this.checkBackToAdmin();
    return (
      <Switch>
          {
          backtoAdmin&&backtoAdmin!==""&&
          <Route
          path='/monitoringnew'
            {...this.props}
            render={routeProps => (
              <MonitoringShareLocation {...this.props} {...routeProps} {...this.state} />
            )}
          />
        }
        <Route
          exact
          path='/'
          render={
            routeProps => (
              // <Redirect to='/maintenance_dashboard' />
                <Redirect to='/dashboard' />
             ) /*<Home {...routeProps} {...this.state} />*/
          }
        />
      
      <Route
          exact
          path='/delivery-detail'
          render={routeProps => (
            <DeliverNotesDetail {...this.props} {...routeProps} {...this.state} />
          )}
        />

        <Route
          exact
          path='/deliveryNote'
          render={routeProps => (
            <DeliveryNotes {...this.props} {...routeProps} {...this.state} />
          )}
        />

        
        <Route
          exact
          path='/dashboard'
          render={routeProps => <Dashboard {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/login'
          render={routeProps => (
            <Login {...this.props} {...routeProps} {...this.state} />
          )}
        />
        <Route
          exact
          path='/logout'
          render={routeProps => <LogOut {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/loginasuser'
          render={routeProps => <Loginasuser {...routeProps}  {...this.props} {...this.state} />}
        />
        <Route
          exact
          path='/monitoring'
          render={routeProps => <Monitoring {...routeProps} {...this.state} />}
        />

<Route
          exact
          {...this.props}
          path='/locationshare'
          render={routeProps => <UserLocationShare {...this.props} {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/tracks'
          render={routeProps => <Tracks {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/tracks/:id'
          render={routeProps => <Tracks {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/Dlogs'
          render={routeProps => <Messages {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/timemachine'
          render={routeProps => <Timemachine {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/units/:id'
          render={routeProps => <Units {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/units/add/unit'
          render={routeProps => (
            <Units {...routeProps} {...this.state} addUnitScreen={true} />
          )}
        />
        <Route
          exact
          path='/units'
          render={routeProps => <Units {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/users/:id'
          render={routeProps => <Users {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/commands'
          render={routeProps => <Commands {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/maintenance'
          render={routeProps => <Maintenance {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/maintenanceCreate'
          render={routeProps => <Maintenance {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/maintenanceUpdate/:id'
          render={routeProps => (
            <Maintenance {...routeProps} {...this.state} />
          )}
        />
        <Route
          exact
          path='/parameters'
          render={routeProps => <Parameters {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/users'
          render={routeProps => <Users {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/addUser'
          render={routeProps => (
            <Users {...routeProps} {...this.state} addUserScreen={true} />
          )}
        />
         <Route
          exact
          path='/missed-opportunities'
          render={routeProps => (
            <MissedOpportunity {...this.props} {...routeProps} {...this.state} />
          )}
        />
        {/* <Route
          exact
          path='/resources'
          render={routeProps => (
            <Groups {...routeProps} {...this.state} />
          )}
        />
        <Route
          exact
          path='/resources/:id'
          render={routeProps => (
            <Groups {...routeProps} {...this.state} />
          )}
        /> */}
        <Route
          exact
          path='/themeBuilder'
          render={routeProps => (
            <BuildTheme {...routeProps} {...this.state} />
          )}
        />
        
        <Route
          exact
          path='/premium_dashboard/:id'
          render={routeProps => (
            <PremiumDashboard {...routeProps} {...this.state} />
          )}
        />
        {/* <Route
          exact
          path='/maintenance_dashboard'
          render={routeProps => (
            <MaintenanceDashboard {...routeProps} {...this.state} />
          )}
        /> */}
        
        <Route
          exact
          path='/dashboardBuilder'
          render={routeProps => (
            <DashboardBuilder {...routeProps} {...this.state} />
          )}
        />
        <Route
          exact
          path='/dashboardBuilder/:id'
          render={routeProps => (
            <DashboardBuilder {...routeProps} {...this.state} />
          )}
        />
       
        <Route
          exact
          path='/geofences'
          render={routeProps =><Geofences {...routeProps} {...this.state} />
        }
        />
        <Route
          exact
          path='/reports'
          render={routeProps => <Reports {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/reportsHistory'
          render={routeProps => <ReportsHistory {...routeProps} {...this.state} />}
        />
        
        <Route
          exact
          path='/users'
          render={routeProps => <Users {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/drivers'
          render={routeProps => <Drivers {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/drivers/:id'
          render={routeProps => <Drivers {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/binders'
          render={routeProps => <Trailer {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/binders/:id'
          render={routeProps => <Trailer {...routeProps}  {...this.state} />}
        />
        
        
        <Route
          exact
          path='/notifications'
          render={routeProps => (
            <Notification {...routeProps}  {...this.props} {...this.state} />
          )}
        />
        <Route
          exact
          path='/notificationCreate'
          render={routeProps => (
            <Notification {...routeProps} {...this.state} />
          )}
        />
        <Route
          exact
          path='/notificationUpdate/:id'
          render={routeProps => (
            <Notification {...routeProps} {...this.state} />
          )}
        />
        <Route
          exact
          path='/events'
          render={routeProps => <Events {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/events/:id'
          render={routeProps => <Events {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/maps/:lat/:lng'
          render={routeProps => <MarkerMap {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/vehicles'
          render={routeProps => <Vehicle {...routeProps} {...this.state} renderMode='default'/>}
        />
        <Route
          exact
          path='/model'
          render={routeProps => <Model {...routeProps} {...this.state} renderMode='default'/>}
        />
        <Route
          exact
          path='/garage'
          render={routeProps => <Garage {...routeProps} {...this.state} renderMode='default'/>}
        />
        <Route
          exact
          path='/area'
          render={routeProps => <Area {...routeProps} {...this.state} renderMode='default'/>}
        />
         <Route
          exact
          path='/services'
          render={routeProps => <Services {...routeProps} {...this.state} renderMode='default'/>}
        />
        {/* <Route
          exact
          path='/services'
          render={routeProps => <Services {...routeProps} {...this.state} renderMode='default'/>}
        /> */}
        <Route
          exact
          path='/schedules'
          render={routeProps => <Jobs {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/schedules/:id'
          render={routeProps => <Jobs {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/vehicle/:id'
          render={routeProps => <Vehicle {...routeProps} {...this.state} renderMode='view'/>}
        />
        <Route
          exact
          path='/vehicles/:id'
          render={routeProps => <Vehicle {...routeProps} {...this.state} renderMode='edit'/>}
        />
        <Route
          exact
          path='/trash'
          render={routeProps => <Trash {...this.props}   {...routeProps} {...this.state} />}
        />
        <Route
          exact
          path='/accountManagements'
          render={routeProps => (
            <AccountManagements {...this.props}  {...routeProps} {...this.state} />
          )}
        />
        <Route
          exact
          path='/reset'
          render={routeProps => (
            <ResetPassword {...this.props} {...routeProps} {...this.state} />
          )}
        />
        <Route
          exact
          path='/actionlogs'
          render={routeProps => (
            <Actionlogs {...this.props} {...routeProps} {...this.state} />
          )}
        />
        <Route
          exact
          path='/invoiceDetail'
          render={routeProps => (
            <InvoiceDetail {...this.props} {...routeProps} {...this.state} />
          )}
        />
       <Route
              exact
              path="/maintenance-invoice-detail"
              render={(routeProps) => (
                <MaintenanceInvoiceDetail
                  {...this.props}
                  {...routeProps}
                  {...this.state}
                />
              )}
            />
        <Route
          exact
          path='/invoice'
          render={routeProps => (
            <Invoice {...this.props} {...routeProps} {...this.state} />
          )}
        />
        <Route
          exact
          path='/costing'
          render={routeProps => (
            <Costing {...this.props} {...routeProps} {...this.state} />
          )}
        />
           {
          backtoAdmin&& backtoAdmin!==""?<Redirect to="/monitoringnew"/>:<Redirect to="/"/>
        }
        <Route path='*' component={NotFound} />
      </Switch>
    )
  }
}
