import React from "react";
import { Trans as Translate } from "react-i18next";

import { connect } from "react-redux";
import { ReactComponent as MessageIcon } from "../../assets/messageicon.svg";
import SupportModal from "./SupportModal";

import { errorHandler } from "../../Helpers";
import instance from "../../axios";
import { notifySuccess } from "../../Utils/CustomNotifcations";
import Tooltip from "../../Components/common/Tooltip";

const supportDataFormat = (data, user) => ({
  name: user && user.name ? user.name : "",
  email: user && user.email ? user.email : "",
  subject: data?.subject,
  contact: user && user.phone ? user.phone : "",
  country: user && user.country ? user.country : "",
  message: data?.message,
});
const formDefault = {
  subject: "",
  message: "",
};
class Support extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        subject: this.props?.openModal
          ? "Let's Go Green with NTSC Expert Help"
          : formDefault.subject,
        message: "",
      },
      openSupportModal: false,
      disabledUserBtn: true,
      supportStatus: "",
      isDragging: false,
      top: 500, // Initial position from the bottom
      left: window.innerWidth - 65, // Initial position from the right
      hasMoved: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onCloseSubModal = this.onCloseSubModal.bind(this);
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
  }
  onOpenModal = () => {
    this.setState({
      openSupportModal: true,
    });
  };
  onCloseSubModal = () => {
    this.setState({
      openSupportModal: false,
      form: {},
      disabledUserBtn: true,
    });
    if (this.props.handleCloseMessage) {
      this.props.handleCloseMessage();
    }
  };
  handleChange(name, event) {
    const { target } = event;
    let value = target.value.toString();
    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: value,
        },
      },
      this.checkRequiredFields()
    );
  }
  checkRequiredFields() {
    let { subject, message } = this.state.form;
    if (subject && message) {
      this.setState({
        disabledUserBtn: false,
      });
    } else {
      this.setState({
        disabledUserBtn: true,
      });
    }
  }
  csm_submit = () => {
    let { form } = this.state;
    this.setState(
      {
        supportStatus: (
          <span className="loading-status-text">
            <LoadingIndicator /> Sending
          </span>
        ),
      },
      () => this.onCloseSubModal()
    );
    const obj = supportDataFormat(form, this.props?.logInUser);
    this.setState({
      disabledUserBtn: true,
    });
    instance({
      url: `api/feedbacks`,
      method: `POST`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        if (response) {
          this.props.dispatch(notifySuccess("emailSentSuccessfully"));
          this.setState({ openSupportModal: false, supportStatus: "" });
        }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
        this.setState({ supportStatus: "" });
      });
  };

  handleMouseDown = (e) => {
    this.setState({
      isDragging: true,
      offsetX: e.clientX,
      offsetY: e.clientY,
      hasMoved: false,
    });
    document.addEventListener("mousemove", this.handleMouseMove);
    document.addEventListener("mouseup", this.handleMouseUp);
  };

  handleMouseMove = (e) => {
    const { isDragging, offsetX, offsetY, top, left } = this.state;
    if (isDragging) {
      const deltaX = e.clientX - offsetX;
      const deltaY = e.clientY - offsetY;
      this.setState({
        top: top + deltaY,
        left: left + deltaX,
        offsetX: e.clientX,
        offsetY: e.clientY,
        hasMoved: true, // Indicates a move action took place
      });
    }
  };

  handleMouseUp = () => {
    const { hasMoved } = this.state;
    this.setState({ isDragging: false });
    document.removeEventListener("mousemove", this.handleMouseMove);
    document.removeEventListener("mouseup", this.handleMouseUp);

    // Call onOpenModal only if it was a click without movement
    if (!hasMoved && this.onOpenModal) {
      this.onOpenModal();
    }
  };
  render() {
    return (
      <>
        <div
          style={{
            position: "fixed",
            top: this.state.top,
            left: this.state.left,
            zIndex: 9990,
            cursor: "pointer",
            width: "45px",
          }}
          onMouseDown={this.handleMouseDown}
        >
          <Tooltip title={this.props.translate("Open Support Form")}>
            <MessageIcon />
          </Tooltip>
        </div>
        {this.props.openModal === true || this.state.openSupportModal ? (
          <>
            <SupportModal
              {...this.state}
              {...this.props}
              dispatch={this.props.dispatch}
              onCloseSubModal={this.onCloseSubModal}
              formSubmit={this.csm_submit}
              form={this.state.form}
              handleChange={this.handleChange}
              supportStatus={this.state.supportStatus}
            />
          </>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.roles,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting,
});

export default connect(mapStateToProps)(Support);

const LoadingIndicator = () => {
  return (
    <span className="status-icon">
      <span className="loading-dot" style={{ animationDelay: "0ms" }} />
      <span className="loading-dot" style={{ animationDelay: "160ms" }} />
      <span className="loading-dot" style={{ animationDelay: "320ms" }} />
    </span>
  );
};
