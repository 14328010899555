import React, { Component } from "react";

import {Button, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Paper, Grid, TableContainer, Tooltip } from '@mui/material';
import instance from "../../axios";
import Loader from "../../Layout/Loader";
import moment from "moment";
// import Button from "../../Components/common/Button";
class VehicleServicesDetailQrCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: false,
            activeButton:20,
        };
    }
    componentDidMount() {
        const { id } = this.props.match.params;
        this.getData(id,20);
    }
    
    getData = async (id,limit) => {
        this.setState({ isLoading: true ,activeButton:limit});
        const apiLimit=limit==="packages"?`/api/trigger/getvehicleservicespackages/${id}?limit=5`:limit==="battery"?`/api/trigger/getvehicleservicesbattery/${id}?limit=5`: `/api/trigger/getvehicleservices/${id}?limit=${limit}`
        try {
            const response = await instance({
                url:apiLimit,
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
    
            this.setState({ data: response, isLoading: false });
        } catch (error) {
            this.setState({ isLoading: false,activeButton:null });
            console.error("Error fetching vehicle services:", error);
        }
    };
    render() {
        const { id } = this.props.match.params;
        const { activeButton } = this.state;
        return (
            <>
                {
                    this.state.isLoading ?<Loader /> :
                        <Box sx={{ padding: 3, backgroundColor: "#f4f4f4", minHeight: "100vh" }}>
                            {/* Header */}
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: 3,
                                    padding: 2,
                                    backgroundColor: "#fff",
                                    borderRadius: 2,
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <img
                                    src="https://t23.ntsc.app/p-logo.png"
                                    alt="Petromin Logo"
                                    style={{ height: 50 }}
                                />
                                <Typography variant="h5" sx={{ textAlign: "center", color: "#4f81bd", fontWeight: "bolder" }}>
                                    Vehicle Service History <br />
                                    تاريخ خدمة المركبات
                                </Typography>
                                <img
                                    src="https://t23.ntsc.app/logo.png"
                                    alt="NTSC Logo"
                                    style={{ height: 50 }}
                                />
                            </Box>
                            <Paper
                                              
                                              elevation={3}
                                              sx={{
                                                  padding: 2,
                                                  marginBottom: 3,
                                                  backgroundColor: "#f9f9f9",
                                                  borderRadius: 2,
                                                  overflow:"visible"
                                              }}
                                          >
                                           <div style={{ display: "flex", gap: "1rem" }}>
  {/* Packages Button with Tooltip */}
  <Tooltip title={<span style={{fontSize:"0.8rem"}}>Last 5 visits for packages</span>} placement="top">
    <span>
      <Button
        variant="contained"
        sx={{
          backgroundColor: activeButton === "packages" ? "#6FBD43" : "#1565C0",
          color: "white",
          "&:hover": {
            backgroundColor: activeButton === "packages" ? "#5AA832" : "#0D47A1",
          },
          padding: "8px 16px",
          borderRadius: "4px",
          textTransform: "none",
        }}
        onClick={() => this.getData(id, "packages")}
      >
        Packages
      </Button>
    </span>
  </Tooltip>

  {/* Batteries Button with Tooltip */}
  <Tooltip title={<span style={{fontSize:"0.8rem"}}>Last 5 visits for batteries</span>} placement="top">
    <span>
      <Button
        variant="contained"
        sx={{
          backgroundColor: activeButton === "battery" ? "#6FBD43" : "#1565C0",
          color: "white",
          "&:hover": {
            backgroundColor: activeButton === "battery" ? "#5AA832" : "#0D47A1",
          },
          padding: "8px 16px",
          borderRadius: "4px",
          textTransform: "none",
        }}
        onClick={() => this.getData(id, "battery")}
      >
        Batteries
      </Button>
    </span>
  </Tooltip>

  {/* Submit Self Check-in Button */}
  <Button
    variant="=contained"
    sx={{
        backgroundColor: activeButton === "self" ? "#6FBD43" : "#1565C0",
        color: "white",
        "&:hover": {
          backgroundColor: activeButton === "self" ? "#5AA832" : "#0D47A1",
        },
        padding: "8px 16px",
        borderRadius: "4px",
        textTransform: "none",
      }}
    onClick={() => alert("Feature under development")}
  >
    Submit Self Check-in
  </Button>

  {/* History Button */}
  <Tooltip title={<span style={{fontSize:"0.8rem"}}>Last 20 visits</span>} placement="top">
  <Button
    variant="contained"
    sx={{
      backgroundColor: activeButton === 20 ? "#6FBD43" : "#1565C0",
      color: "white",
      "&:hover": {
        backgroundColor: activeButton === 20 ? "#5AA832" : "#0D47A1",
      },
      padding: "8px 16px",
      borderRadius: "4px",
      textTransform: "none",
    }}
    onClick={() => this.getData(id, 20)}
  >
    History of Last 20
  </Button>
  </Tooltip>

</div>

                                          </Paper>
                            {
                                this.state.data?.length === 0 ?
                                    <Paper
                                        elevation={3}
                                        sx={{ padding: "2.5rem 0rem", marginBottom: 3, borderRadius: 2 }}
                                    >
                                        <h2 style={{ textAlign: "center" }}>No History Exists</h2>
                                    </Paper> :
                                    <>
                                        {/* Vehicle Details */}
                                        <Paper
                                            elevation={3}
                                            sx={{ padding: 2, marginBottom: 3, borderRadius: 2 }}
                                        >
                                            <Typography
                                                variant="h6"
                                                sx={{ borderBottom: "2px solid #4f81bd", display: "inline-block" }}
                                            >
                                                Vehicle & Account Details
                                            </Typography>
                                            <Grid container spacing={2} sx={{ marginTop: 1 }}>
                                                <Grid item xs={6}>
                                                <Typography>
                                                        <strong>Account Name</strong> {this.state.data[0]?.attributes?.c_accountName}
                                                    </Typography>
                                                   
                                                    
                                                    <Typography>
                                                        <strong>Plate #:</strong> {this.state.data[0]?.plateNumber}
                                                    </Typography>
                                                    <Typography>
                                                        <strong>VIN:</strong> {this.state.data[0]?.attributes?.vin}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                <Typography>
                                                        <strong>Contract #:</strong> {this.state.data[0]?.items[0]?.contractNumber}
                                                    </Typography>
                                                    <Typography>
                                                        <strong>Make:</strong> {this.state.data[0]?.attributes?.make}
                                                    </Typography>
                                                    <Typography>
                                                        <strong>Model:</strong>  {this.state.data[0]?.attributes?.model}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    

                                        {/* Service Records */}
                                        {this.state.data?.map((service, index) => (
                                            <Paper
                                                key={service.id}
                                                elevation={3}
                                                sx={{
                                                    padding: 2,
                                                    marginBottom: 3,
                                                    backgroundColor: "#f9f9f9",
                                                    borderRadius: 2,
                                                }}
                                            >
                                                <Typography variant="h6" sx={{ color: "#4f81bd" }}>
                                                    Service #{index + 1}
                                                </Typography>
                                                <Box sx={{ marginTop: 1 }}>
                                                    <Typography>
                                                        <strong>Date:</strong> {service?.serviceDate && moment(service.serviceDate).format('DD-MM-YYYY')}
                                                    </Typography>
                                                    <Typography>
                                                        <strong>Odometer:</strong> {service?.odometerReading}
                                                    </Typography>
                                                    <Typography>
                                                        <strong>Station:</strong> {`${service?.stationName}-${service?.stationCode}-${service?.stationCity}`}
                                                    </Typography>
                                                </Box>

                                                {/* Line Items */}
                                                <TableContainer sx={{ marginTop: 2 }}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell><strong>Sr. No.</strong></TableCell>
                                                                <TableCell><strong>Category</strong></TableCell>
                                                                <TableCell><strong>Item Name</strong></TableCell>
                                                                <TableCell><strong>Item Code</strong></TableCell>
                                                                <TableCell><strong>Quantity</strong></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {service?.items?.map((item, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{index + 1}</TableCell>
                                                                    <TableCell>{item?.categoryName?.charAt(0)?.toUpperCase() + item?.categoryName?.slice(1)}</TableCell>
                                                                    <TableCell>{item?.itemName}</TableCell>
                                                                    <TableCell>{item?.itemCode}</TableCell>
                                                                    <TableCell>{item?.itemQty}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        ))}

                                        {/* Footer */}
                                        <Box sx={{ textAlign: "center", marginTop: 3, color: "#777" }}>
                                            <Typography>
                                                This is a system-generated document. No signature is required.
                                            </Typography>
                                        </Box>
                                    </>
                            }



                        </Box>
                }

            </>

        );
    }
}


export default VehicleServicesDetailQrCode;
