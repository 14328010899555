import React, { useEffect, useState } from 'react'
import Button from '../../Components/common/Button'
import Table from '../../Components/common/tableWithBackEndPagination'
import { Card, CardContent, Chip, Grid, Stack, Typography } from '@mui/material'
import axios from 'axios';
import instance from '../../axios';
import Loader from '../../Layout/Loader';
import { CreateReminderMaintenance } from './CreateReminderMaintenance';
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations';
import ConfirmDialoag from '../../Components/common/ConfirmDialoag';
import { errorHandler } from '../../Helpers';
import { CheckCircle } from '@mui/icons-material';
import { useSelector } from 'react-redux';

const checkFloteNumber = (n) => {
    return Number(n) === n && n % 1 !== 0;
}
let vehiclesList = [];
const servicesAttributes = [];
let expenseType = []
let formDefault = {
    attributes: {
        mileage: 0,
        hours: 0,
        date: 0,
        status: true,
        mailTo: "",
        notificators: "mail",
    },
}
const MaintenanceRemindersList = (props) => {
    const CancelToken = axios.CancelToken;
    const [reminders, setReminders] = useState({
        data: [],
        page: 1,
        pageSize: 20,
        total: 0,
        hasNext: false
    })
    const [loading, setLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [isCreateForm, setIsCreateForm] = useState(false)
    const [selectedUnit, setSelectedUnit] = useState(null)
    const [subTypes, setSubTypes] = useState([]);
    const [reminderForm, setReminderForm] = useState(formDefault)
    const [statusMode, setStatusMode] = useState(false)
    const [isVisible, setIsVisable] = useState(false)
    const [isDeleteReminder, setIsDeleteReminder] = useState(false)
    const [isChangeReminder, setIsChangeReminder] = useState(false)
    const [isViewListVehicleIds, setIsViewListVehicleIds] = useState(false)
    const [isEditForm, setIsEditForm] = useState(false)
    const [triggerApi, setTriggerApi] = useState(false);
    const [selectedReminder, setSelectedReminder] = useState(null)
    const [vehicleIds,setVehicleIds]=useState([])
    const vehicles=useSelector((state)=>state.vehicles)
    const getVehicleName = (vehicleId) => {
        const vehicle = vehicles.find((v) => v.id === vehicleId);
        return vehicle ? vehicle?.vehicleLicensePlate : "Unknown 🚗";
      }

    useEffect(() => {
        let source = CancelToken.source();

        const fetchReminders = async () => {
            setLoading(true);
            try {
                const response = await instance({
                    url: "/api/maintenance/get",
                    method: "GET",
                    cancelToken: source.token,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    params: { page: reminders.page, limit: reminders.pageSize, search: searchQuery || undefined },
                })
                setReminders((prev) => ({
                    ...prev,
                    data: response.data,
                    total: response.total,
                    hasNext: response.data.hasNext,
                }));
         
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error("Error fetching maintenance reminders:", error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchReminders();

        return () => source.cancel("API call cancelled")
    }, [reminders.page, reminders.pageSize, isCreateForm,triggerApi]);

    const setPagination = (data1) => {
        const { data } = reminders;
        if (data && data?.length) {
            let nUser = { ...data1 };
            let lastPage = nUser.total / reminders.pageSize;
            let IsFloate = checkFloteNumber(lastPage);
            delete nUser.data;
            nUser.pageSize = reminders.pageSize;
            nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
            return nUser;
        }
        return {};
    };

    const handleChangePage = (page) => {
        setReminders((prevState) => ({
            ...prevState,
            page
        }))
    }
    const handleChangeRowsPerPage = (pageSize) => {
        setReminders((prevState) => ({
            ...prevState,
            pageSize
        }))
    }
    const getVehiclesList = () => {
        vehiclesList = props.vehicles.map((item) => {
            return {
                id: item.id,
                label: item.vehicleLicensePlate,
                value: item.id,
                vehicleLP: item.vehicleLicensePlate,
                valueType: "string",
            };
        });
    };
    const createForm = () => {
        if (!vehiclesList.length && props.vehicles.length) {
            getVehiclesList();
        }
        setReminderForm(formDefault)
        setVehicleIds([])
        setStatusMode(false)
        setIsCreateForm((prev) => !prev)

    }
    
    const SearchItem = (value) => {
        setSearchQuery(value);
    };
    const handleSearchApi=()=>{
        setTriggerApi((prev)=>!prev)
        setReminders((prevState)=>({
            ...prevState,
            page:1
        }))
    }
    const handleStoreVehicleIds = (selectedVehicles) => {
        setVehicleIds(selectedVehicles);
        let updatedIds = selectedVehicles ? selectedVehicles.join(",") : "";
        setReminderForm((prevState)=>({
            ...prevState,
            attributes:{
                ...prevState.attributes,
                vehicleIds:updatedIds
            }
        }))
    
    };
    
    const handleChange = (name, value) => {
        let updatedValue = value;
        if (name === 'cost' && value < 0) {
            updatedValue = 0;
        }
    
        let units = {};
        units.id = updatedValue?.id;
        units.label = updatedValue.deviceName;
        units.value = updatedValue.deviceId;
    
        setSelectedUnit((prevState) => ({
            ...prevState,
            units,
        }));

        setReminderForm((prevState) => ({
            ...prevState,
            [name]: updatedValue,
        }));
    };
    
    const handleChangeAttributes = (name, value, checked) => {
        if (value) {
            if (name === "byMileage" || name === "byHours" || name === "byDays") {
                setReminderForm((prevState) => ({
                    ...prevState,
                    attributes: {
                        ...prevState.attributes,
                        [name]: checked,
                    },
                }));
            } else if (name === "serviceTypeId") {

                getSubTypes(value && value.id);
                setReminderForm(
                    (prevState) => ({
                        ...prevState,
                        attributes: {
                            ...prevState.attributes,
                            serviceTypeId: value,
                            typeName: value.name,
                        },
                    })
                );
            } else if (name === "serviceExpenseTypeId") {
                setReminderForm(
                    (prevState) => ({
                        ...prevState,
                        cost: value?.unitPrice || "",
                        attributes: {
                            ...prevState.attributes,
                            serviceExpenseTypeId: value,
                            subtypeName: value.name
                        },
                    }),
                );
            } else {
                setReminderForm(
                    (prevState) => ({
                        ...prevState,
                        attributes: {
                            ...prevState.attributes,
                            [name]: value,
                        },
                    }),
                );
            }
        } else {
            setReminderForm((prevState) => {
                const updatedAttributes = { ...prevState.attributes };
                delete updatedAttributes[name];
                return {
                    ...prevState,
                    attributes: updatedAttributes,
                };
            });
        }
    };


    const getSubTypes = async (id) => {
        try {
            const response = await instance({
                method: "GET",
                url: `/api/expensetypes/subtype?expenseTypeId=${id}&search=`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            if (response) {
                const res = response.map((item) => ({
                    key: item.id,
                    id: item.id,
                    expenseTypeId: item.expenseTypeId,
                    name: item.label,
                    label: item.label,
                    parentId: item.parentId,
                }));
                setSubTypes(res); // Update state with the new subtypes
                return res; // Return the mapped result
            }
        } catch (error) {
            setSubTypes([]);
            return [];
        }
    };

    useEffect(() => {
        getExpenseTypes()
    }, [])

    const getExpenseTypes = (contractNumber) => {
        let userId = contractNumber
            ? contractNumber
            : props.ServerSettings && props.ServerSettings.contractNumber;
        instance({
            method: "GET",
            url: userId
                ? `/api/expensetypes?contractNumber=${userId}`
                : `/api/expensetypes`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                response &&
                    response.map((type) => {
                        servicesAttributes.push({
                            key: type.id,
                            id: type.id,
                            type: type.type,
                            name: type.name,
                            subType: type.subType,
                        });
                    });

                let expenseTypes = response?.map((item) => {
                    return {
                        key: item.id,
                        id: item.id,
                        name: item.name,
                        description: item.description,
                        attributes: item.attributes,
                        parentId: item.parentId,
                        valueType: "string",
                        expenseType: item.expenseType,
                    };
                });
                expenseType = expenseTypes;
                // }
            })
            .catch((error) => {
                // errorHandler(error,props.dispatch)
            });
    };

    const saveToParent = (obj) => {
        setReminderForm((prevState) => ({
            ...prevState,
            attributes: { ...prevState.attributes, ...obj },
        }))
    };
    useEffect(() => {
        checkRequiredFields()
    }, [reminderForm])

    const checkRequiredFields = () => {
        let { name} = reminderForm;
        let {
            date,
            mileage,
            hours,
            byDays,
            byHours,
            byMileage,
            serviceExpenseTypeId,
            serviceTypeId,
            emails,
            mailTo,
            vehicleIds,
            notificators,
            avergeMileage,
            avergeDailyMileage,
        } = reminderForm && reminderForm.attributes;
        if (
            name &&
            vehicleIds?.length>0&&
            serviceExpenseTypeId &&
            serviceTypeId &&
            ((byMileage && mileage) ||
                (byHours && hours) ||
                (byDays && date) ||
                (avergeDailyMileage && avergeMileage))
        ) {
            if (
                notificators.includes("mail,whatsapp") &&
                mailTo === "custom" &&
                !emails
            ) {
                setIsVisable(true)
            } else {
                setIsVisable(false)
            }
        } else {
            setIsVisable(true)
        }
    };
    const submitForm = async () => {
        let obj = JSON.parse(JSON.stringify(reminderForm));

        if (obj.vehicleIds) {
            // obj.attributes.vehicleLicensePlate=obj.vehicleIds?.label
            // obj.vehicleIds = statusMode ? obj.vehicleIds : obj.vehicleIds.id;
            obj.deviceId = statusMode ? obj.deviceId : 0;
        }

        if (obj.attributes.byMileage && !statusMode) {
            obj.attributes.mileage = parseFloat(obj.attributes.mileage);
            obj.attributes.mileageInterval = parseFloat(obj.attributes.mileageInterval);
            obj.attributes.reminderBeforeMileage = parseFloat(obj.attributes.reminderBeforeMileage);
        }

        if (obj.attributes.byHours && !statusMode) {
            obj.attributes.hours = parseFloat(obj.attributes.hours * (1000 * 3600));
            obj.attributes.hoursInterval = parseFloat(obj.attributes.hoursInterval * (1000 * 3600));
            obj.attributes.reminderBeforeHours = parseFloat(obj.attributes.reminderBeforeHours * (1000 * 3600));
        }

        if (obj.attributes.byDays && !statusMode) {
            obj.attributes.date = obj.attributes.date;
            obj.attributes.daysInterval = parseFloat(obj.attributes.daysInterval) || parseFloat(1);
            obj.attributes.reminderBeforeDays = parseFloat(obj.attributes.reminderBeforeDays) || parseFloat(1);
        }

        if (obj.attributes.serviceExpenseTypeId) {
            obj.attributes.serviceExpenseTypeId = statusMode
                ? obj?.attributes?.serviceExpenseTypeId
                : obj?.attributes?.serviceExpenseTypeId?.id || "";
            obj.attributes.serviceTypeId = statusMode
                ? obj?.attributes?.serviceTypeId
                : obj?.attributes?.serviceTypeId?.id || "";
        }

        obj = {
            ...obj,
            type: "",
            // contractNumber:"pm2c101"
            // // contractNumber: props.logInUser.contractNumber
            // //     ? props.logInUser.contractNumber
            // //     : props.ServerSettings.contractNumber
            // //         ? props.ServerSettings.contractNumber
            // //         : "pm2c101",
        };

        obj.cost = parseFloat(obj.cost) || 0;
        delete obj.check;
        delete obj.parent;

        if (!obj.attributes.repeat) {
            delete obj.attributes.mileageInterval;
            delete obj.attributes.hoursInterval;
            delete obj.attributes.daysInterval;
        }
        if (!obj.attributes.byMileage) {
            delete obj.attributes.mileage;
            delete obj.attributes.mileageInterval;
            delete obj.attributes.reminderBeforeMileage;
            obj.attributes.reminderByMileage = false;
        }
        if (!obj.attributes.byHours) {
            delete obj.attributes.hours;
            delete obj.attributes.hoursInterval;
            delete obj.attributes.reminderBeforeHours;
            obj.attributes.reminderByHours = false;
        }
        if (!obj.attributes.byDays) {
            delete obj.attributes.date;
            delete obj.attributes.daysInterval;
            delete obj.attributes.reminderBeforeDays;
            obj.attributes.reminderByDays = false;
        }
        if(obj.attributes.email?.length===0){
            delete obj.attributes.email;
        }

        const url = isEditForm||statusMode ? `/api/maintenance/${selectedReminder?.id}` : `/api/maintenance`
        const method = isEditForm||statusMode ? `PUT` : `POST`
        try {
            const response = await instance({
                url: url,
                method: method,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                data: obj,
            });
            if (response) {
                if(!statusMode){
                    createForm()
                }
                props.dispatch(notifySuccess(`Reminder ${isEditForm||statusMode ? "Updated" : "Created"} Successfully`));
                if (isEditForm||statusMode) {
                    setIsEditForm(false)
                    setStatusMode(false)
                    setIsChangeReminder(false)
                    setVehicleIds([])
                    setSelectedReminder(null)
                }
            }

        } catch (error) {
            props.dispatch(notifyError(error));
        }

    };



    const handleEditReminder = async (maintenanceForm) => {
        if (!vehiclesList.length && props.vehicles.length) {
            getVehiclesList();
        }
        getExpenseTypes();
        let selectedVehicle = props.vehicles.find(item => item.id === maintenanceForm[0]?.vehicleIds);
        let subTypeId = maintenanceForm[0]?.attributes?.serviceTypeId;
        try {
            const response = await instance({
                method: "GET",
                url: `api/expensetypes/subtype?expenseTypeId=${subTypeId}&search=`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });

            const subTypesData = response?.map(item => ({
                key: item.id,
                id: item.id,
                expenseTypeId: item.expenseTypeId,
                name: item.label,
                label: item.label,
                parentId: item.parentId,
            })) || [];

            setSubTypes(subTypesData);

            // Create a lookup map for subTypes
            const subTypeMap = subTypesData.reduce((acc, cur) => {
                acc[cur.id] = cur;
                return acc;
            }, {});

            let selectedServiceExpenseId = expenseType?.find(
                (item) => item.id === maintenanceForm[0]?.attributes?.serviceTypeId
            );

            let selectedUnit = { id: 0, label: "", value: 0 };

            const updatedForms = maintenanceForm.map((maintenanceForm1) => {
                let obj = { ...maintenanceForm1 };

                // Convert mileage-based attributes
                if (obj.attributes?.byMileage) {
                    obj.attributes.mileage = +(obj.attributes.mileage).toFixed(3);
                    obj.attributes.mileageInterval = +(obj.attributes.mileageInterval).toFixed(3);
                    obj.attributes.reminderBeforeMileage = +(obj.attributes.reminderBeforeMileage).toFixed(3);
                }

                // Convert hour-based attributes
                if (obj.attributes?.byHours) {
                    obj.attributes.hours = +(obj.attributes.hours / (1000 * 3600)).toFixed(3);
                    obj.attributes.hoursInterval = +(obj.attributes.hoursInterval / (1000 * 3600)).toFixed(3);
                    obj.attributes.reminderBeforeHours = +(obj.attributes.reminderBeforeHours / (1000 * 3600)).toFixed(3);
                }

                // Convert day-based attributes
                if (obj.attributes?.byDays) {
                    obj.attributes.daysInterval = +(obj.attributes?.daysInterval) || 1;
                    obj.attributes.reminderBeforeDays = +(obj.attributes?.reminderBeforeDays) || 1;
                }

                // Assign Service Sub-Type
                if (obj.attributes?.serviceExpenseTypeId) {
                    obj.attributes.serviceTypeId = selectedServiceExpenseId;
                    obj.attributes.serviceExpenseTypeId = subTypeMap[maintenanceForm1?.attributes?.serviceExpenseTypeId] || null;
                }

                // Prepare selected unit (only update once outside loop)
                if (maintenanceForm1?.deviceId) {
                    selectedUnit = {
                        id: maintenanceForm1.deviceId,
                        label: maintenanceForm1.deviceName,
                        value: maintenanceForm1.deviceId,
                    };
                }

                // Create a properly structured vehicle object
                let updatedVehicle = selectedVehicle
                    ? {
                        ...selectedVehicle,
                        label: selectedVehicle.vehicleLicensePlate,
                        value: selectedVehicle.id,
                        vehicleLP: selectedVehicle.vehicleLicensePlate,
                        valueType: "string",
                    }
                    : null;

                delete obj.progress;

                return {
                    ...obj,
                    vehicleIds: updatedVehicle,
                };
            });
            setSelectedUnit(selectedUnit);
            setSelectedReminder(updatedForms[0])
            setReminderForm((prevState) => ({
                ...prevState,
                ...updatedForms[0],
            }));

        } catch (error) {
            console.error("Error fetching subtypes:", error);
        }

        setIsEditForm(true);
        setIsCreateForm(true);
    };

    const handleDeleteReminder = () => {
        if (selectedReminder) {
            instance({
                url: `/api/maintenance/${selectedReminder?.id}`,
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (selectedReminder?.id) {
                        setReminders((prev) => ({
                            ...prev,
                            data: prev?.data?.filter(item => item.id !== selectedReminder.id) || [],
                            total: Math.max(0, (prev?.total || 0) - 1),
                        }));
                    }
                    props.dispatch(notifySuccess(`Reminder Deleted Successfully`));
                    setSelectedReminder(null)
                    setIsDeleteReminder(false)
                }).catch(error => {
                    errorHandler(error, props.dispatch)
                    setSelectedReminder(null)
                    setIsDeleteReminder(false)
                })
        }
    }
    const isOpenDeleteReminder = (data) => {
        if (data) {
            setSelectedReminder(data[0])
        }
        setIsDeleteReminder(true)
    }
    const onCancelReminder = () => {
        setIsDeleteReminder(false)
        setSelectedReminder(null)
    }

    const handleChangeStatusReminder = () => {
        let data = selectedReminder

        if (data) {
            data.attributes.status = !data.attributes.status
            setReminderForm((prevState) => ({
                ...prevState,
                ...data
            }))
        }
        setStatusMode(true)
    }

    const isOpenChangeStatus = (data) => {
        if (data) {
            setSelectedReminder(data)
        }
        setIsChangeReminder(true)
    }
    const isOpenViewListVehicleIds = (data) => {
        if (data[0]) {
            setSelectedReminder(data[0])
        }
        setIsViewListVehicleIds(true)
    }
    
    const isCancelViewListVehicleIds = () => {
        setIsViewListVehicleIds(false)
        setSelectedReminder(null)
    }

    const isCancelChangeStatus = () => {
        setIsChangeReminder(false)
        setSelectedReminder(null)
    }

    useEffect(() => {
        if (statusMode) {
            submitForm()
        }

    }, [statusMode])
    return (
        <div>
            {!isCreateForm ?
                <div
                    style={{
                        background: props.themecolors.backgroundColor,
                        color: props.themecolors.textColor,
                        borderRadius: 6,
                    }}
                >
                    {
                        <Grid container style={{ height: "100%" }}>
                            <Grid item sm={12} xs={12}>
                                {!loading ? (
                                    <Table
                                        rows={
                                            (reminders.data &&
                                                reminders.data) ||
                                            []
                                        }
                                        pagination={setPagination(
                                            reminders && reminders
                                        )}
                                        handleChangeRowsPerPage={(n) =>
                                            handleChangeRowsPerPage(n, "maintenanceReminder")
                                        }
                                        handleChangePage={(n) =>
                                            handleChangePage(n, "maintenanceReminder")
                                        }
                                        // hasAccessOfUpdate
                                        isEditable
                                        hasAccessOfDelete
                                        logInUser={props.logInUser}
                                        themecolors={props.themecolors}
                                        translate={props.translate}
                                        vehicles={props?.vehicles || []}
                                        ServerSetting={props.ServerSetting}
                                        searchableMaintenanceReminder
                                        searchReminder={searchQuery}
                                        SearchItem={SearchItem}
                                        onEdit={handleEditReminder}
                                        onDelete={isOpenDeleteReminder}
                                        onStatusChange={isOpenChangeStatus}
                                        isSearchApi={handleSearchApi}
                                        isSearchApiBtn
                                        createForm={createForm}
                                        isCreateForm={isCreateForm}
                                        isShowReminderHeader
                                        showReminders={props.showReminders}
                                        // completeMark
                                        hasAccessOfCreate
                                        listViewVehicleIds={isOpenViewListVehicleIds}
                                        status
                                        createButton
                                        // createButton={
                                        //     // checkPrivileges('invoiceCreate') && (
                                        //     <div style={{ marginLeft: 10 }}>
                                        //         <Button onClick={createForm}>
                                        //             {props.translate('Create')}
                                        //         </Button>
                                        //     </div>
                                        //     // )
                                        // }
                                        rowDefinition={
                                            [
                                                // {
                                                //     id: "attributes.vehicleLicensePlate",
                                                //     numeric: false,
                                                //     disablePadding: false,
                                                //     label: props.translate("vehicles"),
                                                // },
                                                {
                                                    id: "name",
                                                    numeric: false,
                                                    disablePadding: false,
                                                    label: props.translate("Name"),
                                                },
                                                {
                                                    id: "attributes.typeName",
                                                    numeric: false,
                                                    disablePadding: false,
                                                    label: props.translate("Expense Type"),
                                                },
                                                {
                                                    id: "attributes.subtypeName",
                                                    numeric: false,
                                                    disablePadding: false,
                                                    label: props.translate("Sub-Type"),
                                                    //   hide: checkPrivileges("invoiceInvalidView") ? false : true,
                                                },
                                                {
                                                    id: "cost",
                                                    numeric: false,
                                                    disablePadding: false,
                                                    label: props.translate("cost"),
                                                    hide: false,
                                                },
                                                {
                                                    id: "listViewVehicleIds",
                                                    numeric: false,
                                                    disablePadding: false,
                                                    label: props.translate("vehicleIdsList"),
                                                    hide: false,
                                                },
                                            ]
                                        }
                                    />
                                ) : (
                                    <Loader />
                                )}
                            </Grid>
                        </Grid>
                    }
                </div> : <div>
                    <CreateReminderMaintenance
                        {...props}
                        translate={props.translate}
                        form={reminderForm}
                        back={() => {
                            setIsCreateForm(false);
                            setIsEditForm(false);
                        }}
                        handleStoreVehicleIds={handleStoreVehicleIds}
                        vehicleIds={vehicleIds}
                        vehicles={vehiclesList}
                        allVehicles={vehiclesList}
                        handleChange={handleChange}
                        selectedUnit={selectedUnit}
                        subTypes={subTypes}
                        servicesAttributes={servicesAttributes}
                        handleChangeAttributes={handleChangeAttributes}
                        saveToParent={saveToParent}
                        submitForm={submitForm}
                        isVisible={isVisible}
                        isEditForm={isEditForm}
                    />
                </div>
            }

            {isDeleteReminder && (
                <ConfirmDialoag
                    onCancel={onCancelReminder}
                    onOk={handleDeleteReminder}
                    title={props.translate('areYouWantToDelete')}
                    children={selectedReminder?.name}
                />
            )}
            {isChangeReminder && (
                <ConfirmDialoag
                    onCancel={isCancelChangeStatus}
                    onOk={handleChangeStatusReminder}
                    OkText={'Yes'}
                CancelText={'No'}
                title={props.translate('areYouWantChangeStatus')}
                    children={selectedReminder?.name}
                />
            )}
              {isViewListVehicleIds && (
                <ConfirmDialoag
                    onCancel={isCancelViewListVehicleIds}
                    onOk={isCancelViewListVehicleIds}
                    hideCancel
                    OkText={'Ok'}
                title={props.translate('LIST VEHICLE IDS ')}
                    children={
                        <Card sx={{width:400, maxWidth: 400, mx: "auto", p: 2,boxShadow:3, borderRadius: 3 }}>
                        <CardContent>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="body1" color="text.secondary">
                              PMS Name:
                            </Typography>
                            <Typography variant="h6" fontWeight="bold">
                              {selectedReminder?.name}
                            </Typography>
                          </Stack>
                          {selectedReminder?.attributes?.byMileage && (
                            <Stack direction="row" alignItems="center" spacing={1} mt={2} color="green">
                              <CheckCircle color="success" />
                              <Typography variant="body1">PMS By Mileage</Typography>
                            </Stack>
                          )}
                
                          <Typography variant="body1" color="text.secondary" mt={2}>
                            PMS Vehicles :
                          </Typography>
                          <Stack
  direction="row"
  flexWrap="wrap"
  mt={1}
  sx={{ maxWidth: "100%", gap: 1 }}
>
{selectedReminder.vehicleIds?.map((vehicleId) => (
      <Chip key={vehicleId} label={`${getVehicleName(vehicleId)}`} variant="outlined" />
    ))}
</Stack>
                        </CardContent>
                      </Card>
                    }
                />
            )}
        </div>
    )
}

export default MaintenanceRemindersList

